import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import "./AddPricingTable.css";
import { AuthContext } from "../../context/AuthContext";
import { baseUrl } from "../../../api/Api";
import PricingModal from "./PricingModal";

const AddPricingTable = ({ data }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [allShows, setAllShows] = useState(data || []);
  const [pricingData, setPricingData] = useState(data || []);
  const [editableValue, setEditableValue] = useState("");
  const [pricingModalActive, setPricingModalActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [isCreatePricingModal, setIsCreatePricingModal] = useState(false);
  const [selectedPricingId, setSelectedPricingId] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedShow, setSelectedShow] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const { token, role } = useContext(AuthContext);
  const cellRefs = useRef({});

  useEffect(() => {
    setAllShows(data || []);
  }, [data]);

  console.log(allShows);

  const allColumns = [
    { field: "Controllers", headerName: "Controllers" },
    { field: "Show", headerName: "Show" },
    { field: "Next Dates", headerName: "Start Date" },
    { field: "End Date", headerName: "End Date" },
    { field: "Convention Center / Host Hotel", headerName: "Convention" },
    { field: "Venue", headerName: "Venue" },
    { field: "City, Country", headerName: "City, Country" },
    { field: "isActive", headerName: "Active" },
    { field: "updatedAt", headerName: "Date Modified" },
  ];

  const columnsOfTable =
    role !== "pricing junior"
      ? allColumns
      : allColumns.filter((column) => column.field !== "SellingPrice");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleTogglePricing = (event, showId, show) => {
    event.stopPropagation();
    setPricingModalActive(true);
    setSelectedShow(show);
    setSelectedPricing(show.pricing);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsCreatePricingModal(false);
    setSelectedPricingId(null);
    setPricingModalActive(false);
    setSelectedShow(null);
  };

  return (
    <div className="table-container">
      <table className="table-scrollable">
        <thead style={{ position: "static" }}>
          <tr>
            {columnsOfTable.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allShows.map((show) => (
            <React.Fragment key={show._id}>
              <tr style={{ cursor: "pointer" }}>
                {columnsOfTable.map((column) => (
                  <td key={column.field}>
                    {column.field === "Controllers" ? (
                      <div>
                        <button
                          onClick={(event) =>
                            handleTogglePricing(event, show._id, show)
                          }
                        >
                          View Pricing
                        </button>
                      </div>
                    ) : editingCell?.showId === show._id &&
                      editingCell.field === column.field ? (
                      <input
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        type="text"
                        value={editableValue}
                      />
                    ) : column.field === "Shows" ? (
                      show[column.field] &&
                      Array.isArray(show[column.field]) ? (
                        show[column.field].map((show) => (
                          <div key={show._id}>{show.Show}</div>
                        ))
                      ) : (
                        <div>No Shows</div>
                      )
                    ) : column.field === "isActive" ? (
                      show[column.field] ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : column.field === "lastModified" ? (
                      formatDate(show[column.field])
                    ) : typeof show[column.field] === "object" &&
                      show[column.field] !== null ? (
                      <div>
                        {Object.keys(show[column.field]).map((key) => (
                          <div key={key}>
                            {key}: {JSON.stringify(show[column.field][key])}
                          </div>
                        ))}
                      </div>
                    ) : (
                      show[column.field]
                    )}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {pricingModalActive && (
        <div
          className="modal-backdrop"
          onClick={handleModalClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
          >
            <PricingModal showId={selectedShow._id} data={selectedPricing} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPricingTable;
