import React from 'react'

const ResDrop = ({ label, options, selectedValue, handleChange, name }) => {
  return (
    <div className="filter-dropDown-container">
    <label htmlFor={`${name}-dropdown`} className="label-dropdown-filter linear">
      <h4>{label}</h4>
    </label>
    <select
      name={name}
      id={`${name}-dropdown`}
      className="dropdown-filter"
      value={selectedValue}
      onChange={handleChange}
    >
      <option value="" className="dropdown-filter-option">
        Select {label}
      </option>
      {options?.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
  )
}

export default ResDrop