import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const SignUpForm = () => {
  const { signup } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    timezone: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const selectOptions = [
    { label: "super user", value: "super user" },
    { label: "agent", value: "agent" },
    { label: "agent closer", value: "agent closer" },
    { label: "team leader", value: "team leader" },
    { label: "sales manager", value: "sales manager" },
    { label: "sales support", value: "sales support" },
    { label: "sales support manager", value: "sales support manager" },
    { label: "email team manager", value: "email team manager" },
    { label: "locator", value: "locator" },
    { label: "locator manager", value: "locator manager" },
    { label: "leads junior", value: "leads junior" },
    { label: "leads senior", value: "leads senior" },
    { label: "email team", value: "email team" },
    { label: "leads manager", value: "leads manager" },
    { label: "pricing manager", value: "pricing manager" },
    { label: "pricing junior", value: "pricing junior" },
    { label: "pricing senior", value: "pricing senior" },
    { label: "lead generator", value: "lead generator" },
    { label: "locator manager", value: "locator manager" },
    { label: "operations manager", value: "operations manager" },
    { label: "house", value: "house" },
    { label: "customer support", value: "customer support" },
  ];

  const handleChange = (e) => {
    setSuccessMessage("");
    setErrorMessage("");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(formData);
      setSuccessMessage("User Created Successfully!");
      setFormData({
        name: "",
        email: "",
        password: "",
        role: "",
        timezone: "",
      }); // Clear the form
    } catch (error) {
      setErrorMessage(error.response.data.message);
      console.error("Signup error:", error);
    }
  };

  return (
    <div className="form-control-container">
      {successMessage && (
        <div
          style={{ color: "green", marginBottom: "1rem", fontWeight: "600" }}
        >
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div style={{ color: "red", marginBottom: "1rem", fontWeight: "600" }}>
          {errorMessage}
        </div>
      )}
      <form className="form-control" onSubmit={handleSubmit}>
        <div className="inputs-form-controls">
          <input
            type="text"
            placeholder="UserName"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            placeholder="User Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="password"
            placeholder="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          <div className="select-dropdown-control">
            <label htmlFor="role-dropdown" className="label-dropdown">
              Role :
            </label>
            <select
              name="role"
              id="role-dropdown"
              className="dropdown-container"
              value={formData.role}
              onChange={handleChange}
            >
              <option value="" hidden>
                Select an option
              </option>
              {selectOptions.map((optionItem) => (
                <option key={optionItem.value} value={optionItem.value}>
                  {optionItem.label}
                </option>
              ))}
            </select>
          </div>
          <div className="select-dropdown-control">
            <label htmlFor="timezone-dropdown" className="label-dropdown">
              TimeZone :
            </label>
            <select
              name="timezone"
              id="timezone-dropdown"
              className="dropdown-container"
              value={formData.timezone}
              onChange={handleChange}
            >
              <option value="" hidden>
                Select TimeZone
              </option>
              <option value="uk">UK</option>
              <option value="us">US</option>
            </select>
          </div>
        </div>
        <div className="buttons-form-controls">
          <button className="form-btn">Sign Up</button>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
