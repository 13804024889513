import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import PricingTable from "../../components/pricingTeam/PricingTable";
import UploadControllersSection from "../../components/uploadControllers/UploadControllersSection";
import "./PricingManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import JuniorPricingTable from "../../components/pricingTeam/pricingJunior/JuniorPricingTable";
import { baseUrl } from "../../api/Api";
import PricingEmailRequest from "../../components/PricingEmailRequest/PricingEmailRequest";
import NewPricingEmailReq from "../../components/PricingEmailRequest/NewPricingEmailReq";
import AddPricingTable from "../../components/pricingTeam/pricingSenior/AddPricingTable";

const PricingManager = () => {
  const { token, role } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [data, setData] = useState(null);
  const [rawPricing, setRawPricing] = useState(null);
  const [allShows, setAllShows] = useState(null);

  // Fetch regular pricing data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-pricings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch junior staging pricing data
  const fetchManuallyGeneratedRawPricing = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/pricing/manually-generated-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawPricing(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  const fetchAllShows = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-shows`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setAllShows(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  useEffect(() => {
    if (activeTable === "pricing") {
      fetchData();
    } else if (activeTable === "raw pricing") {
      fetchManuallyGeneratedRawPricing();
    } else if (activeTable === "create pricing") {
      fetchAllShows();
    }
  }, [activeTable]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleShowTable = (table) => {
    setActiveTable(table);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Pricing Team View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              {role !== "pricing junior" && (
                <>
                  <button
                    onClick={() => handleShowTable("pricing")}
                    className="sidebar-btn"
                  >
                    Pricing Table
                  </button>
                  {/* <button
                    onClick={() => handleShowTable("email")}
                    className="sidebar-btn"
                  >
                    Email Request
                  </button>
                  <button
                    onClick={() => handleShowTable("OldEmail")}
                    className="sidebar-btn"
                  >
                    History Email Request
                  </button> */}
                </>
              )}
              {role === "pricing junior" && (
                <button
                  onClick={() => handleShowTable("raw pricing")}
                  className="sidebar-btn"
                >
                  Create Raw Pricing
                </button>
              )}
              {role === "pricing junior" && (
                <button
                  onClick={() => handleShowTable("create pricing")}
                  className="sidebar-btn"
                >
                  Create Pricing
                </button>
              )}
              {/* <button
                onClick={() => handleShowTable("upload")}
                className="sidebar-btn"
              >
                Upload Bulk Data
              </button> */}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>

        {/* Conditional rendering of components */}
        <div className="table-container">
          {activeTable === "pricing" && role !== "pricing junior" && (
            <>
              <h2 className="table-title">Pricing Table</h2>
              <PricingTable data={data} fetchData={fetchData} />
            </>
          )}

          {/* {activeTable === "email" && (
            <>
              <NewPricingEmailReq token={token} />
            </>
          )}

          {activeTable === "OldEmail" && (
            <>
              <PricingEmailRequest token={token} />
            </>
          )} */}

          {activeTable === "raw pricing" && (
            <>
              <h2 className="table-title">Create Raw Pricing</h2>
              <JuniorPricingTable
                data={rawPricing}
                fetchData={fetchManuallyGeneratedRawPricing}
                role={role}
              />
            </>
          )}

          {activeTable === "create pricing" && (
            <>
              <AddPricingTable
                data={allShows}
                fetchData={fetchAllShows}
                role={role}
              />
            </>
          )}

          {activeTable === "upload" && (
            <>
              <h2 className="table-title">Upload Bulk Data</h2>
              <UploadControllersSection />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingManager;
