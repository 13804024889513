import React, { useState, useContext } from "react";
import { Grid, TextField, Button, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import { AuthContext } from "../../components/context/AuthContext";
import { baseUrl } from "../../api/Api";

const CreateLead = ({ showNames, timezones }) => {
  const [selectedShowName, setSelectedShowName] = useState("");
  const [selectedShowId, setSelectedShowId] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedEdition, setSelectedEdition] = useState("");
  const [formState, setFormState] = useState({
    Lead: "",
    Phone: "",
    Email: "",
    "Dm Name": "",
    "Locate Status": "",
    "Show Url": "",
    Platform: "",
    "Exhibitor List": "",
    "Lead Status": "",
  });
  const { token } = useContext(AuthContext);

  // Lead status options
  const leadStatusOptions = ["Open", "In Progress", "Closed", "Need Update"];
  const editionOptions = ["2024", "2025", "2026"];

  const handleShowChange = (event) => {
    const selectedShowName = event.target.value;
    const show = showNames.find((show) => show.showName === selectedShowName);
    if (show) {
      setSelectedShowName(selectedShowName);
      setSelectedShowId(show.showId);
    }
  };

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleEditionChange = (event) => {
    setSelectedEdition(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLeadStatusChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      "Lead Status": event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const leadData = {
      Show: selectedShowName, // Use showId here
      ShowId: selectedShowId, // Or use as needed
      Lead: formState.Lead,
      "Locate Status": formState["Locate Status"],
      "Show Url": formState["Show Url"],
      Platform: formState.Platform,
      "Exhibitor List": formState["Exhibitor List"],
      "Lead Status": formState["Lead Status"],
      Edition: selectedEdition,
      Timezone: selectedTimezone,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/create-company`,
        leadData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Lead created:", response.data);
      
      setFormState({
        Lead: "",
        Phone: "",
        Email: "",
        "Dm Name": "",
        "Locate Status": "",
        "Show Url": "",
        Platform: "",
        "Exhibitor List": "",
        "Lead Status": "",
      });
      setSelectedShowId("");
      setSelectedTimezone("");
      setSelectedEdition("");
    } catch (error) {
      console.error("Error creating lead:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Show Name</InputLabel>
            <Select
              name="showName"
              value={showNames?.find((show) => show.showId === selectedShowId)?.showName || ""}
              onChange={handleShowChange}
              label="Show Name"
            >
              <MenuItem value="" disabled>Select Show Name</MenuItem>
              {showNames &&
                showNames.map((show) => (
                  <MenuItem key={show.showId} value={show.showName}>
                    {show.showName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Timezone</InputLabel>
            <Select
              name="timezone"
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              label="Timezone"
            >
              <MenuItem value="" disabled>Select Timezone</MenuItem>
              {timezones &&
                timezones.map((timezone) => (
                  <MenuItem key={timezone} value={timezone}>
                    {timezone}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Edition</InputLabel>
            <Select
              name="edition"
              value={selectedEdition}
              onChange={handleEditionChange}
              label="Edition"
            >
              <MenuItem value="" disabled>Select Edition</MenuItem>
              {editionOptions.map((edition) => (
                <MenuItem key={edition} value={edition}>
                  {edition}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Lead"
            name="Lead"
            value={formState.Lead}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Phone"
            name="Phone"
            value={formState.Phone}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Email"
            name="Email"
            value={formState.Email}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="DM Name"
            name="Dm Name"
            value={formState["Dm Name"]}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Locate Status"
            name="Locate Status"
            value={formState["Locate Status"]}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Show Url"
            name="Show Url"
            value={formState["Show Url"]}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Platform"
            name="Platform"
            value={formState.Platform}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Exhibitor List"
            name="Exhibitor List"
            value={formState["Exhibitor List"]}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Lead Status</InputLabel>
            <Select
              name="Lead Status"
              value={formState["Lead Status"]}
              onChange={handleLeadStatusChange}
              label="Lead Status"
            >
              {leadStatusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Create Lead
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateLead;
