import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AuthContext } from "../context/AuthContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const LeadDataTable = ({ data, show, company }) => {
  const { role } = useContext(AuthContext);
  console.log(role);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: "200px" }}>Show</StyledTableCell>
            <StyledTableCell>Hotel</StyledTableCell>
            {role === "email team" && (
              <>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Contact Name</StyledTableCell>
              </>
            )}
            <StyledTableCell>Budget</StyledTableCell>
            <StyledTableCell>Max. Distance</StyledTableCell>
            <StyledTableCell>Rooms</StyledTableCell>
            <StyledTableCell>Check In</StyledTableCell>
            <StyledTableCell>Check Out</StyledTableCell>
            <StyledTableCell>Preferences</StyledTableCell>
            <StyledTableCell>Amenities</StyledTableCell>
            <StyledTableCell>Activities</StyledTableCell>
            <StyledTableCell>Request</StyledTableCell>
            <StyledTableCell>/Room Stay</StyledTableCell>
            {role === "email team" && (
              <>
                <StyledTableCell>Email Type</StyledTableCell>
                <StyledTableCell>Email Notes</StyledTableCell>
                <StyledTableCell>Email Status</StyledTableCell>
              </>
            )}
            <StyledTableCell>Agent</StyledTableCell>
            <StyledTableCell>Date Updated</StyledTableCell>
            <StyledTableCell>Made By</StyledTableCell>
            {/* <StyledTableCell>Closer</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{show}</StyledTableCell>
            {role === "email team" && (
              <>
                <StyledTableCell>{company}</StyledTableCell>
                <StyledTableCell>{data.Email}</StyledTableCell>
                <StyledTableCell>{data["Contact Name"]}</StyledTableCell>
              </>
            )}
            <StyledTableCell>{data.Budget}</StyledTableCell>
            <StyledTableCell>{data.Rooms}</StyledTableCell>
            <StyledTableCell>{data["Check-in"]}</StyledTableCell>
            <StyledTableCell>{data["Check-out"]}</StyledTableCell>
            <StyledTableCell>{data.Preferences}</StyledTableCell>
            <StyledTableCell>{data.Amenities}</StyledTableCell>
            <StyledTableCell>{data.Activities}</StyledTableCell>
            <StyledTableCell>{data.Request}</StyledTableCell>
            <StyledTableCell>{data["/ Room Stay"]}</StyledTableCell>
            {role === "email team" && (
              <>
                <StyledTableCell>{data["Email Type"]}</StyledTableCell>
                <StyledTableCell>{data["Email Notes"]}</StyledTableCell>

                <StyledTableCell>{data["Email Status"]}</StyledTableCell>
              </>
            )}
            <StyledTableCell>{data.Agent}</StyledTableCell>
            <StyledTableCell>{data["Date Updated"]}</StyledTableCell>
            <StyledTableCell>{data.MadeBy}</StyledTableCell>
            {/* <StyledTableCell>{data["Closer Assigned"]}</StyledTableCell> */}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeadDataTable;
