import React, { createContext, useContext, useEffect, useRef, useState } from "react";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState("disconnected"); // Track connection status
  const socketRef = useRef(null);

  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsUrl =
      process.env.REACT_APP_WEBSOCKET_URL ||
      `${protocol}://${window.location.hostname}`;

    const connectWebSocket = () => {
      setStatus("connecting");
      socketRef.current = new WebSocket(wsUrl);

      socketRef.current.onopen = () => {
        setStatus("connected");
        console.log("WebSocket connection opened");
      };

      socketRef.current.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        try {
          const parsedMessage = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, parsedMessage]);
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      };

      socketRef.current.onclose = () => {
        setStatus("disconnected");
        console.log("WebSocket connection closed, reconnecting...");
        setTimeout(connectWebSocket, 3000); // Retry connection after 3 seconds
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        socketRef.current.close();
      };
    };

    connectWebSocket();

    return () => {
      socketRef.current.close();
    };
  }, []);

  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("Cannot send message, WebSocket not connected");
    }
  };

  return (
    <WebSocketContext.Provider value={{ messages, sendMessage, status }}>
      {children}
    </WebSocketContext.Provider>
  );
};
