import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { baseUrl } from "../../../api/Api";

const CreatePricingModal = ({ token, onClose, showId, onPricingCreated }) => {
  const [conventionCenter, setConventionCenter] = useState("");
  const [pricingStatus, setPricingStatus] = useState("");

  const handleConventionCenterChange = (event) => {
    setConventionCenter(event.target.value);
  };

  const handlePricingStatusChange = (event) => {
    setPricingStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/create-pricing/${showId}`,
        {
          "Convention Center / Host Hotel": conventionCenter,
          "Pricing Status": pricingStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data.data.pricing);

      // Call the callback to pass the created pricing data to the parent component
      onPricingCreated(response.data.data.pricing);
      onClose();
    } catch (error) {
      console.error("Error creating pricing:", error);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Convention Center / Host Hotel"
                name="conventionCenter"
                value={conventionCenter}
                onChange={handleConventionCenterChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Pricing Status"
                name="pricingStatus"
                value={pricingStatus}
                onChange={handlePricingStatusChange}
                fullWidth
                margin="normal"
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Create Pricing
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreatePricingModal;
