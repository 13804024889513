import React, { useContext, useState } from "react";
import axios from "axios";
import styles from "./UploadCompaniesSection.module.css";
import UploadCompanies from "./UploadCompanies";
import { AuthContext } from "../context/AuthContext";
import UploadSseniorLeads from "./senior/UploadSeniorLeads";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { baseUrl } from "../../api/Api";
import { FaBullseye } from "react-icons/fa";

const UploadCompaniesSection = ({ showNames }) => {
  const [selectedCompaniesFile, setSelectedCompaniesFile] = useState(null);
  const [selectedSeniorLeadsFile, setSelectedSeniorLeadsFile] = useState(null);
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isEditionVisible, setIsEditionVisible] = useState(false);
  const [isShowVisible, setIsShowvisible] = useState(false);
  const [isUploadToSpecificShowVisible, setIsUploadToSpecificShowVisible] =
    useState(false);
  const [isUploadToDifferentShowsVisible, setIsUploadToDifferentShowsVisible] =
    useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  console.log(showNames);

  const editionYears = ["2024", "2025"];

  const handleCompaniesFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedCompaniesFile(file);
  };

  const handleSeniorLeadsFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedSeniorLeadsFile(file);
  };

  const handleShowNameChange = (event) => {
    setSelectedShow(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleCompaniesUpload = async () => {
    if (selectedCompaniesFile && selectedShow) {
      const formData = new FormData();
      formData.append("excelFile", selectedCompaniesFile);
      formData.append("Show", selectedShow);
      formData.append("editionYear", selectedYear);
      setLoading(true);
      setProgress(0);

      try {
        const response = await axios.post(
          `${baseUrl}/api/leads-senior/upload-leads-to-show`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted); // Update progress
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("File or show not selected");
    }
  };

  const handleSeniorLeadsUpload = async () => {
    if (selectedSeniorLeadsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedSeniorLeadsFile);
      formData.append("editionYear", selectedYear);
      setLoading(true);
      setProgress(0);
      try {
        const response = await axios.post(
          `${baseUrl}/api/leads-senior/upload-leads`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted); // Update progress
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("File or show not selected");
    }
  };

  const handleDownloadSampleLeadsWithShow = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/leads-senior/download-sample-with-shows`,
        {
          responseType: "blob", // This ensures the file is treated as binary data
        }
      );

      // Create a blob URL from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Set the download filename
      link.setAttribute("download", "Sample_Leads_With_Show.xlsx");

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the link element after download
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the sample leads file:", error);
    }
  };

  const handleDownloadSampleLeadsWithoutShow = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/leads-senior/download-sample-without-shows`,
        {
          responseType: "blob", // This ensures the file is treated as binary data
        }
      );

      // Create a blob URL from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Set the download filename
      link.setAttribute("download", "Sample_Leads_Without_Show.xlsx");

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the link element after download
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the sample leads file:", error);
    }
  };

  const handleShowUploadInSpecificShow = () => {
    setIsEditionVisible(true);
    setIsShowvisible(true);
    setIsUploadToSpecificShowVisible(true);
    setIsUploadToDifferentShowsVisible(false);
  };

  const handleShowUploadInDifferentShow = () => {
    setIsEditionVisible(true);
    setIsShowvisible(false);
    setIsUploadToSpecificShowVisible(false);
    setIsUploadToDifferentShowsVisible(true);
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded</p>
      <div style={{ display: "flex", gap: "20px", marginTop: "1rem" }}>
        <Button
          variant="outlined"
          sx={{ color: "white" }}
          onClick={handleShowUploadInSpecificShow}
        >
          Upload Leads In Specific Show
        </Button>
        <Button
          variant="outlined"
          sx={{ color: "white" }}
          onClick={handleShowUploadInDifferentShow}
        >
          Upload Leads In Different Shows
        </Button>
      </div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          marginBottom: "1rem",
          marginTop: "1rem",
          gap: "20px",
          width: "50%",
        }}
      >
        {isEditionVisible && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="year-select-label">Edition</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              label="Edition"
            >
              <MenuItem value="">
                <em>Select Edition</em>
              </MenuItem>
              {editionYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isShowVisible && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="show-select-label">Show Name</InputLabel>
            <Select
              labelId="show-select-label"
              id="show-select"
              value={selectedShow}
              onChange={handleShowNameChange}
              label="Show Name"
            >
              <MenuItem value="">
                <em>Select Show Name</em>
              </MenuItem>
              {showNames.map((show) => (
                <MenuItem key={show.showId} value={show.showName}>
                  {show.showName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>

      <div className={styles.controllersContainer}>
        {isUploadToSpecificShowVisible && (
          <div className={styles.uploadElement}>
            <Button
              onClick={handleDownloadSampleLeadsWithShow}
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              Download Sample
            </Button>
            <UploadCompanies
              onFileChange={handleCompaniesFileChange}
              onUpload={handleCompaniesUpload}
              isLoading={loading}
              progress={progress}
              setProgress={setProgress}
            />
          </div>
        )}
        {isUploadToDifferentShowsVisible && (
          <div className={styles.uploadElement}>
            <Button
              onClick={handleDownloadSampleLeadsWithoutShow}
              sx={{ color: "white", marginBottom: "1rem" }}
            >
              Download Sample
            </Button>
            <UploadSseniorLeads
              onFileChange={handleSeniorLeadsFileChange}
              onUpload={handleSeniorLeadsUpload}
              isLoading={loading}
              progress={progress}
              setProgress={setProgress}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadCompaniesSection;
