import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { baseUrl } from "../../../api/Api";

const CreateLeadModel = ({ showId, token, onClose, onLeadCreated }) => {
  const [leadData, setLeadData] = useState({
    companyName: "",
    phone: "",
    email: "",
    link: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeadData({ ...leadData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/create-raw-lead/${showId}`,
        {
          companyName: leadData.companyName,
          phone: leadData.phone,
          email: leadData.email,
          link: leadData.link,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Call onLeadCreated with the updated show data if needed
      onLeadCreated(response.data.show);
      console.log(response.data.show);

      onClose();
    } catch (error) {
      console.error("Error creating lead:", error);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                name="companyName"
                value={leadData.companyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phone"
                value={leadData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                value={leadData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                label="Link"
                name="link"
                value={leadData.link}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Create Lead
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateLeadModel;
