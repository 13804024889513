import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LiveRequestCard from "./LiveRequestCard";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./SalesSupportPage.module.css";
import { AuthContext } from "../context/AuthContext";
import SalesSupportPricingTable from "./SalesSupportPricingTable";
import LeadDataTable from "./LeadDataTable";
import { baseUrl } from "../../api/Api";

const AllRequestsPage = () => {
  const [allRequests, setAllRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedModel, setSelectedModel] = useState(""); // State for dropdown selection
  const [loading, setLoading] = useState(false);
  const { token, role } = useContext(AuthContext);
  console.log(role);

  // Handle the dropdown change
  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  useEffect(() => {
    const fetchLiveRequests = async () => {
      try {
        setLoading(true);
        // Determine the endpoint based on the user's role
        const endpoint =
          role === "sales support manager"
            ? `${baseUrl}/api/support/all-live-requests`
            : role === "email team manager"
            ? `${baseUrl}/api/email-team/all-email-requests`
            : null;

        if (endpoint && selectedModel) {
          const response = await axios.post(
            endpoint,
            {
              model: selectedModel,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAllRequests(response.data);
          console.log(response.data);
        } else {
          console.error("Invalid role or no matching endpoint.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching live requests:", error);
        setLoading(false);
      }
    };

    // Trigger the API call when selectedModel changes
    if (selectedModel) {
      fetchLiveRequests();
    }
  }, [role, token, selectedModel]); // Trigger request when model changes

  const handleCardClick = (request) => {
    setSelectedRequest(request);
  };

  return (
    <div className={styles.container}>
      <FormControl variant="outlined" className={styles.dropdown}>
        <InputLabel id="model-select-label">Select Model</InputLabel>
        <Select
          labelId="model-select-label"
          id="model-select"
          value={selectedModel}
          onChange={handleModelChange}
          label="Select Model"
        >
          <MenuItem value="us">US</MenuItem>
          <MenuItem value="uk">UK</MenuItem>
        </Select>
        <FormHelperText>Select region model (US or UK)</FormHelperText>
      </FormControl>
      <div className={styles.content}>
        {loading ? (
          <div className="spinner-container">
            Loading...
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <ul className={styles.requestList}>
              <h2>All Requests</h2>
              {allRequests.slice(0, 4).map((request, index) => (
                <li key={index} onClick={() => handleCardClick(request)}>
                  <LiveRequestCard message={{ data: request }} />
                </li>
              ))}
            </ul>

            <div className={styles.requestDetails}>
              <h2>Request Details</h2>
              {selectedRequest && (
                <div>
                  <p>
                    <strong>Show Name:</strong> {selectedRequest?.showName}
                  </p>
                  <p>
                    <strong>Company ID:</strong> {selectedRequest?.companyName}
                  </p>
                  <p>
                    <strong>Request:</strong> {selectedRequest?.Request}
                  </p>
                  <p>
                    <strong>Requested Data:</strong>{" "}
                    {selectedRequest?.RequestedData}
                  </p>
                  <p>
                    <strong>Requested Data:</strong>{" "}
                    {selectedRequest?.notes}
                  </p>
                  <p>
                    <strong>State:</strong> {selectedRequest?.State}
                  </p>
                  <p>
                    <strong>Submitted Time:</strong>{" "}
                    {selectedRequest?.submittedTime}
                  </p>
                  <p>
                    <strong>Answered Time:</strong>{" "}
                    {selectedRequest?.answeredTime}
                  </p>
                  <p>
                    <strong>Feedback Data:</strong>
                  </p>
                  <SalesSupportPricingTable data={selectedRequest?.feedback} />
                  <p>
                    <strong>Lead Data:</strong>
                  </p>
                  <LeadDataTable
                    data={selectedRequest?.leadData}
                    show={selectedRequest?.showName}
                    company={selectedRequest?.companyName}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllRequestsPage;
