import React from "react";

const Templates = ({dataOfTemplate }) => {

  const dmName = dataOfTemplate.ContactName;
  const userName = localStorage.getItem("name");

  return (
    <div className="template-info-section">
      {dataOfTemplate.templatedSelected === "Template 1" && (
        <div className="templateMaded-container">
          <h4>
            Dear <span>{dmName !== "" ? dmName : "dmName[ContactName]"}</span>,
          </h4>
          <p>
            We're thrilled to introduce{" "}
            <span>{dataOfTemplate.companyName}</span>, your premier partner for
            unparalleled show accommodations...
          </p>
          <p>Corporate blocks negotiated at 15-30% less from OTA's:</p>
          <ul>
            <li>
              <b>Hassle-Free Booking:</b> Effortlessly reserve accommodations
              through our intuitive platform.
            </li>
            <li>
              <b>Real-Time Updates:</b> Stay informed with instant notifications
              on your reservations.
            </li>
            <li>
              <b>Exceptional Savings:</b> Access competitive rates and exclusive
              discounts.
            </li>
            <li>
              <b>Dedicated Support:</b> Our round-the-clock team is ready to
              assist you at every step.
            </li>
          </ul>
          <p>
            Below is a glimpse of the pricing table for the upcoming{" "}
            <span>{dataOfTemplate.showSelected}</span>:
          </p>
          <table border="1">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Skyline</th>
                <th>Distance</th>
                <th>Rates</th>
                <th>B&B</th>
              </tr>
            </thead>
            <tbody>
              {dataOfTemplate.pricingSelected.length === 0 ? (
                <tr>
                  <td></td>
                </tr>
              ) : (
                dataOfTemplate.pricingSelected.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td></td>
                    <td>{item.Distance}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <p>
            Please note that group rates are available upon request, tailored to
            meet your specific needs.
          </p>
          <p>
            Our commitment to excellence extends beyond mere accommodation...
          </p>
          <p>
            To proceed with your reservation or inquire about group rates,
            simply reply with the following details:
          </p>
          <ul>
            <li>
              <b>Number of Rooms:</b> _______________
            </li>
            <li>
              <b>Dates of Stay:</b> _______________
            </li>
            <li>
              <b>Preferred Hotel:</b> _______________
            </li>
            <li>
              <b>Room Preference (Double Bed/Twin Bed):</b> _______________
            </li>
          </ul>
          <p>
            For non-refundable requests and potential price reductions, kindly
            indicate your preference in your response.
          </p>
          <p>
            We're dedicated to enhancing your {dataOfTemplate.showSelected}{" "}
            experience...
          </p>
          <p>Best Regards,</p>
          <p>
            <span>{userName}</span>
          </p>
          <p>Reservations Specialist</p>
          <p>Exclusive Skyline</p>
          <p>400 Renaissance Center Suite #2600</p>
          <p>Detroit, MI 48243</p>
          <p>exclusiveskyline.com</p>
          <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 2" && (
        <div className="templateMaded-container">
          <h4>
            Dear <span>{dmName}</span>,
          </h4>
          <p>
            We are excited to offer you special accommodations through{" "}
            <span>{dataOfTemplate.companyName}</span> for the upcoming{" "}
            <span>{dataOfTemplate.showSelected}</span>...
          </p>
          <p>Why Choose Exclusive Skyline?</p>
          <ul>
            <li>
              <b>Easy Booking:</b> Seamlessly book your stay with our
              user-friendly platform.
            </li>
            <li>
              <b>Instant Alerts:</b> Get timely updates about your reservations.
            </li>
            <li>
              <b>Cost Savings:</b> Benefit from our competitive pricing and
              exclusive deals.
            </li>
            <li>
              <b>Support Team:</b> Our dedicated team is available 24/7 to
              assist you.
            </li>
          </ul>
          <p>
            Here's a preview of the hotel options for{" "}
            <span>{dataOfTemplate.showSelected}</span>:
          </p>
          <table border="1">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Skyline</th>
                <th>Distance</th>
                <th>Rates</th>
                <th>B&B</th>
              </tr>
            </thead>
            <tbody>
              {dataOfTemplate.pricingSelected.length === 0 ? (
                <tr>
                  <td></td>
                </tr>
              ) : (
                dataOfTemplate.pricingSelected.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td></td>
                    <td>{item.Distance}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <p>
            We also offer group rates upon request, tailored to your specific
            requirements.
          </p>
          <p>
            To make a reservation or inquire about group rates, please provide
            the following details:
          </p>
          <ul>
            <li>
              <b>Number of Rooms:</b> _______________
            </li>
            <li>
              <b>Dates of Stay:</b> _______________
            </li>
            <li>
              <b>Preferred Hotel:</b> _______________
            </li>
            <li>
              <b>Room Preference (Double Bed/Twin Bed):</b> _______________
            </li>
          </ul>
          <p>
            For non-refundable requests and potential price reductions, please
            mention your preference in your response.
          </p>
          <p>
            We look forward to making your{" "}
            <span>{dataOfTemplate.showSelected}</span> experience exceptional...
          </p>
          <p>Sincerely,</p>
          <p>
            <span>{userName}</span>
          </p>
          <p>Reservations Specialist</p>
          <p>Exclusive Skyline</p>
          <p>400 Renaissance Center Suite #2600</p>
          <p>Detroit, MI 48243</p>
          <p>exclusiveskyline.com</p>
          <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 3" && (
        <div className="templateMaded-container">
          <h4>
            Dear <span>{dmName}</span>,
          </h4>
          <p>
            Don't miss out on the best accommodations for{" "}
            <span>{dataOfTemplate.showSelected}</span>! Exclusive Skyline is
            your ultimate partner for an unmatched stay experience.
          </p>
          <p>Experience the Exclusive Skyline Advantage:</p>
          <ul>
            <li>
              <b>Hassle-Free Booking:</b> Effortlessly reserve accommodations
              through our intuitive platform.
            </li>
            <li>
              <b>Real-Time Updates:</b> Stay informed with instant notifications
              on your reservations.
            </li>
            <li>
              <b>Exceptional Savings:</b> Access competitive rates and exclusive
              discounts.
            </li>
            <li>
              <b>Dedicated Support:</b> Our round-the-clock team is ready to
              assist you at every step.
            </li>
          </ul>
          <p>
            Secure your spot now! Here's a glimpse of the pricing table for the
            upcoming <span>{dataOfTemplate.showSelected}</span>:
          </p>
          <table border="1">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Skyline</th>
                <th>Distance</th>
                <th>Rates</th>
                <th>B&B</th>
              </tr>
            </thead>
            <tbody>
              {dataOfTemplate.pricingSelected.length === 0 ? (
                <tr>
                  <td></td>
                </tr>
              ) : (
                dataOfTemplate.pricingSelected.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td></td>
                    <td>{item.Distance}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <p>
            Please note that group rates are available upon request, tailored to
            meet your specific needs.
          </p>
          <p>
            Our commitment to excellence extends beyond mere accommodation.
            Don't wait, act now to ensure your perfect stay!
          </p>
          <p>
            To proceed with your reservation or inquire about group rates,
            simply reply with the following details:
          </p>
          <ul>
            <li>
              <b>Number of Rooms:</b> _______________
            </li>
            <li>
              <b>Dates of Stay:</b> _______________
            </li>
            <li>
              <b>Preferred Hotel:</b> _______________
            </li>
            <li>
              <b>Room Preference (Double Bed/Twin Bed):</b> _______________
            </li>
          </ul>
          <p>
            For non-refundable requests and potential price reductions, kindly
            indicate your preference in your response.
          </p>
          <p>
            We're dedicated to enhancing your{" "}
            <span>{dataOfTemplate.showSelected}</span> experience.
          </p>
          <p>Best Regards,</p>
          <p>
            <span>{userName}</span>
          </p>
          <p>Reservations Specialist</p>
          <p>Exclusive Skyline</p>
          <p>400 Renaissance Center Suite #2600</p>
          <p>Detroit, MI 48243</p>
          <p>exclusiveskyline.com</p>
          <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 4" && (
        <div className="templateMaded-container">
          <h4>
            Dear <span>{dmName}</span>,
          </h4>
          <p>
            Don't miss out on the best accommodations for{" "}
            <span>{dataOfTemplate.showSelected}</span>! Exclusive Skyline is
            your ultimate partner for an unmatched stay experience.
          </p>
          <p>
            Time is running out! Ensure the best accommodations for{" "}
            <span>{dataOfTemplate.showSelected}</span> with Exclusive Skyline.
          </p>
          <p>Why Choose Exclusive Skyline?</p>
          <ul>
            <li>
              <b>Easy Booking:</b> Seamlessly book your stay with our
              user-friendly platform.
            </li>
            <li>
              <b>Instant Alerts:</b> Get timely updates about your reservations.
            </li>
            <li>
              <b>Cost Savings:</b> Benefit from our competitive pricing and
              exclusive deals.
            </li>
            <li>
              <b>Support Team:</b> Our dedicated team is available 24/7 to
              assist you.
            </li>
          </ul>
          <p>
            Act quickly to secure your reservation! Here's a preview of the
            hotel options for <span>{dataOfTemplate.showSelected}</span>:
          </p>
          <table border="1">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Skyline</th>
                <th>Distance</th>
                <th>Rates</th>
                <th>B&B</th>
              </tr>
            </thead>
            <tbody>
              {dataOfTemplate.pricingSelected.length === 0 ? (
                <tr>
                  <td></td>
                </tr>
              ) : (
                dataOfTemplate.pricingSelected.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td></td>
                    <td>{item.Distance}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <p>
            Group rates are available upon request. Don't delay – secure your
            spot today!
          </p>
          <p>
            To proceed with your reservation or inquire about group rates,
            simply reply with the following details:
          </p>
          <ul>
            <li>
              <b>Number of Rooms:</b> _______________
            </li>
            <li>
              <b>Dates of Stay:</b> _______________
            </li>
            <li>
              <b>Preferred Hotel:</b> _______________
            </li>
            <li>
              <b>Room Preference (Double Bed/Twin Bed):</b> _______________
            </li>
          </ul>
          <p>
            For non-refundable requests and potential price reductions, kindly
            indicate your preference in your response.
          </p>
          <p>
            We're dedicated to enhancing your{" "}
            <span>{dataOfTemplate.showSelected}</span> experience.
          </p>
          <p>Best Regards,</p>
          <p>
            <span>{userName}</span>
          </p>
          <p>Reservations Specialist</p>
          <p>Exclusive Skyline</p>
          <p>400 Renaissance Center Suite #2600</p>
          <p>Detroit, MI 48243</p>
          <p>exclusiveskyline.com</p>
          <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 5" && (
        <div className="templateMaded-container">
            <h4>
                Dear <span>{dmName}</span>,
            </h4>
            <p>The deadline to book your stay for <span>{dataOfTemplate.showSelected}</span> is fast approaching! Don't miss out on the best rates and accommodations with Exclusive Skyline.</p>
            <p>Exclusive Skyline offers:</p>
            <ul>
                <li><b>Hassle-Free Booking:</b> Effortlessly reserve accommodations through our intuitive platform.</li>
                <li><b>Real-Time Updates:</b> Stay informed with instant notifications on your reservations.</li>
                <li><b>Exceptional Savings:</b> Access competitive rates and exclusive discounts.</li>
                <li><b>Dedicated Support:</b> Our round-the-clock team is ready to assist you at every step.</li>
            </ul>
            <p>Secure your reservation before the deadline! Here's a glimpse of the pricing table for the upcoming <span>{dataOfTemplate.showSelected}</span>:</p>
            <table border="1">
                <thead>
                <tr>
                    <th>Hotel</th>
                    <th>Skyline</th>
                    <th>Distance</th>
                    <th>Rates</th>
                    <th>B&B</th>
                </tr>
                </thead>
                <tbody>
                {dataOfTemplate.pricingSelected.length === 0 ? (
                    <tr>
                    <td></td>
                    </tr>
                ) : (
                    dataOfTemplate.pricingSelected.map((item) => (
                    <tr key={item._id}>
                        <td>{item.Hotel}</td>
                        <td></td>
                        <td>{item.Distance}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    ))
                )}
                </tbody>
            </table>
            <p>Group rates are available upon request. Make sure to book before it's too late!</p>
            <p>To proceed with your reservation or inquire about group rates, simply reply with the following details:</p>
            <ul>
                <li><b>Number of Rooms:</b> _______________</li>
                <li><b>Dates of Stay:</b> _______________</li>
                <li><b>Preferred Hotel:</b> _______________</li>
                <li><b>Room Preference (Double Bed/Twin Bed):</b> _______________</li>
            </ul>
            <p>For non-refundable requests and potential price reductions, kindly indicate your preference in your response.</p>
            <p>We're dedicated to enhancing your <span>{dataOfTemplate.showSelected}</span> experience.</p>
            <p>Best Regards,</p>
            <p><span>{userName}</span></p>
            <p>Reservations Specialist</p>
            <p>Exclusive Skyline</p>
            <p>400 Renaissance Center Suite #2600</p>
            <p>Detroit, MI 48243</p>
            <p>exclusiveskyline.com</p>
            <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 6" && (
        <div className="templateMaded-container">
            <h4>
                Dear <span>{dmName}</span>,
            </h4>
            <p>This is a friendly reminder to book your accommodations for <span>{dataOfTemplate.showSelected}</span> with Exclusive Skyline. We want to ensure you get the best rates and options available.</p>
            <p>Our exclusive advantages include:</p>
            <ul>
                <li><b>Hassle-Free Booking:</b> Effortlessly reserve accommodations through our intuitive platform.</li>
                <li><b>Real-Time Updates:</b> Stay informed with instant notifications on your reservations.</li>
                <li><b>Exceptional Savings:</b> Access competitive rates and exclusive discounts.</li>
                <li><b>Dedicated Support:</b> Our round-the-clock team is ready to assist you at every step.</li>
            </ul>
            <p>Here's a glimpse of the pricing table for the upcoming <span>{dataOfTemplate.showSelected}</span>:</p>
            <table border="1">
                <thead>
                <tr>
                    <th>Hotel</th>
                    <th>Skyline</th>
                    <th>Distance</th>
                    <th>Rates</th>
                    <th>B&B</th>
                </tr>
                </thead>
                <tbody>
                {dataOfTemplate.pricingSelected.length === 0 ? (
                    <tr>
                    <td></td>
                    </tr>
                ) : (
                    dataOfTemplate.pricingSelected.map((item) => (
                    <tr key={item._id}>
                        <td>{item.Hotel}</td>
                        <td></td>
                        <td>{item.Distance}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    ))
                )}
                </tbody>
            </table>
            <p>Group rates are available upon request. Don't delay, book your stay today!</p>
            <p>To proceed with your reservation or inquire about group rates, simply reply with the following details:</p>
            <ul>
                <li><b>Number of Rooms:</b> _______________</li>
                <li><b>Dates of Stay:</b> _______________</li>
                <li><b>Preferred Hotel:</b> _______________</li>
                <li><b>Room Preference (Double Bed/Twin Bed):</b> _______________</li>
            </ul>
            <p>For non-refundable requests and potential price reductions, kindly indicate your preference in your response.</p>
            <p>We're dedicated to enhancing your <span>{dataOfTemplate.showSelected}</span> experience.</p>
            <p>Best Regards,</p>
            <p><span>{userName}</span></p>
            <p>Reservations Specialist</p>
            <p>Exclusive Skyline</p>
            <p>400 Renaissance Center Suite #2600</p>
            <p>Detroit, MI 48243</p>
            <p>exclusiveskyline.com</p>
            <p>skylinehousing.net</p>
        </div>
      )}
      {dataOfTemplate.templatedSelected === "Template 7" && (
        <div className="templateMaded-container">
            <h4>
                Dear <span>{dmName}</span>,
            </h4>
            <p>This is your last chance to secure your accommodations for <span>{dataOfTemplate.showSelected}</span> with Exclusive Skyline. Availability is limited, and we don't want you to miss out on the best options.</p>
            <p>Exclusive Skyline offers:</p>
            <ul>
                <li><b>Hassle-Free Booking:</b> Effortlessly reserve accommodations through our intuitive platform.</li>
                <li><b>Real-Time Updates:</b> Stay informed with instant notifications on your reservations.</li>
                <li><b>Exceptional Savings:</b> Access competitive rates and exclusive discounts.</li>
                <li><b>Dedicated Support:</b> Our round-the-clock team is ready to assist you at every step.</li>
            </ul>
            <p>Secure your reservation now! Here's a glimpse of the pricing table for the upcoming <span>{dataOfTemplate.showSelected}</span>:</p>
            <table border="1">
                <thead>
                <tr>
                    <th>Hotel</th>
                    <th>Skyline</th>
                    <th>Distance</th>
                    <th>Rates</th>
                    <th>B&B</th>
                </tr>
                </thead>
                <tbody>
                {dataOfTemplate.pricingSelected.length === 0 ? (
                    <tr>
                    <td></td>
                    </tr>
                ) : (
                    dataOfTemplate.pricingSelected.map((item) => (
                    <tr key={item._id}>
                        <td>{item.Hotel}</td>
                        <td></td>
                        <td>{item.Distance}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    ))
                )}
                </tbody>
            </table>
            <p>Group rates are available upon request. Don't wait – book your stay today!</p>
            <p>To proceed with your reservation or inquire about group rates, simply reply with the following details:</p>
            <ul>
                <li><b>Number of Rooms:</b> _______________</li>
                <li><b>Dates of Stay:</b> _______________</li>
                <li><b>Preferred Hotel:</b> _______________</li>
                <li><b>Room Preference (Double Bed/Twin Bed):</b> _______________</li>
            </ul>
            <p>For non-refundable requests and potential price reductions, kindly indicate your preference in your response.</p>
            <p>We're dedicated to enhancing your <span>{dataOfTemplate.showSelected}</span> experience.</p>
            <p>Best Regards,</p>
            <p><span>{userName}</span></p>
            <p>Reservations Specialist</p>
            <p>Exclusive Skyline</p>
            <p>400 Renaissance Center Suite #2600</p>
            <p>Detroit, MI 48243</p>
            <p>exclusiveskyline.com</p>
            <p>skylinehousing.net</p>
        </div>
      )}
    </div>
  );
};

export default Templates;
