import React, { useState, useCallback,useContext } from "react";
import "./Tabs.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ShowPricingTable from "../pricingTable/ShowPricingTable";
import ShowPricingOld from "../pricingTable/ShowPricingOld";
import RequestDropdown from "../RequestDropdown/RequestDropdown";
import { AuthContext } from "../context/AuthContext"; // Adjust this import path based on your project structure

const optionsReason = [
  { label: "Competition Rate", value: "Competition Rate" },
  { label: "Official Housing Only", value: "Official Housing Only" },
  { label: "Recently Booked", value: "Recently Booked" },
  { label: "Early Booked", value: "Early Booked" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "All Set", value: "All Set" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Local", value: "Local" },
  { label: "Homeowner", value: "Homeowner" },
  { label: "Free Rooms", value: "Free Rooms" },
  { label: "Next Show", value: "Next Show" },
  { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
  { label: "Escalate", value: "Escalate" },
  { label: "Try Again", value: "Try Again" },
];
const optionsEmailType = [
  { label: "Not yet", value: "Not yet" },
  {
    label: "Pricing (1 Day Before, Last Day)",
    value: "Pricing (1 Day Before, Last Day)",
  },
  {
    label: "Pricing (2 Day Before, Last Day)",
    value: "Pricing (2 Day Before, Last Day)",
  },
  {
    label: "Pricing (3 Day Before, Last Day)",
    value: "Pricing (3 Day Before, Last Day)",
  },
  {
    label: "Pricing (Same Day, Last Day)",
    value: "Pricing (Same Day, Last Day)",
  },
  {
    label: "Pricing (1 Day Before, Day After)",
    value: "Pricing (1 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, Day After)",
    value: "Pricing (2 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, 2 Day After)",
    value: "Pricing (2 Day Before, 2 Day After)",
  },
  {
    label: "Contact Card for Future Reference",
    value: "Contact Card for Future Reference",
  },
  { label: "Mixed", value: "Mixed" },
];
const optionsPreferences = [
  { label: "King Bed", value: "King Bed" },
  { label: "2 Queen Beds", value: "2 Queen Beds" },
  { label: "King & Queens", value: "King & Queens" },
  { label: "Triple", value: "Triple" },
  { label: "1 Bed Suite", value: "1 Bed Suite" },
  { label: "2 Bed Suite", value: "2 Bed Suite" },
  { label: "1-Bedroom Apt/Suite", value: "1-Bedroom Apt/Suite" },
  { label: "2-Bedroom Apt/Suite", value: "2-Bedroom Apt/Suite" },
  { label: "3-Bedroom Apt/Suite", value: "3-Bedroom Apt/Suite" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Villa", value: "Villa" },
];
const optionsAmenities = [
  { label: "Room Only", value: "Room Only" },
  { label: "Breakfast", value: "Breakfast" },
  { label: "HB", value: "HB" },
  { label: "FB", value: "FB" },
  { label: "AI", value: "AI" },
  { label: "Kitchenette", value: "Kitchenette" },
  { label: "Balcony", value: "Balcony" },
  { label: "Others", value: "Others" },
];
const optionsActivities = [
  { label: "Transfers", value: "Transfers" },
  { label: "Car Rental", value: "Car Rental" },
  { label: "Air Ticket", value: "Air Ticket" },
  { label: "Platform Access", value: "Platform Access" },
];

const Tabs = ({
  dataOfRowId,
  setDataOfRowId,
  getShows,
  selectedRows,
  isNewActive,
  data,
  selectedRowByCompany,
  requestSelected,
  setRequestSelected,
  handleRequestPopupOpen,
  sendEmailRequest,
}) => {
  const { role } = useContext(AuthContext);
  const [value, setValue] = React.useState("1");
  const [emailError, setEmailError] = useState("");
  const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false);
  const [isActivitesOpen, setIsActivitesOpen] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [alertEmailTypeMessageActive , setAlertEmailTypeMessageActive ] = useState(false);

  console.log("dataOfRowIddataOfRowId",dataOfRowId);
  

  const toggleAmenitiesDropdown = () => setIsAmenitiesOpen((prev) => !prev);
  const toggleActivitesDropdown = () => setIsActivitesOpen((prev) => !prev);

  const handleChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleInputChange = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      setDataOfRowId((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (name === "Email") {
        if (value === "" || value.includes("@")) {
          setEmailError("");
        } else {
          setEmailError(
            "Please enter a valid email address & E-mail must include @."
          );
          return;
        }
      }

      // Handle Email Type validation: Check if the value is neither of the two valid options
      if (name === "Email Type") {
        if (
          value !== "Pricing (Same Day, Last Day)" &&
          value !== "Contact Card for Future Reference" &&
          value !== "Pricing (1 Day Before, Last Day)"
        ) {
          setAlertEmailTypeMessageActive(true);
          return
        }
        setAlertEmailTypeMessageActive(false);
      }

    },
    [setDataOfRowId]
  );

  const updatePreferences = (value) => {
    setSelectedPreferences(value);
  };

  const updateAmenities = (value, isChecked) => {
    setSelectedAmenities((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((amenity) => amenity !== value);
      }
    });
  };

  const addRoomDetail = () => {
    // Save selected data into the roomDetails

    setDataOfRowId((prevData) => ({
      ...prevData,
      roomDetails: [
        ...prevData.roomDetails,
        { Preferences: selectedPreferences, Amenities: selectedAmenities },
      ],
    }));

    console.log(dataOfRowId.roomDetails);

    // Clear selections
    setSelectedPreferences("");
    setSelectedAmenities([]);
    setIsAmenitiesOpen(false);
  };

  const updateActivities = (value, isChecked) => {
    setDataOfRowId((prevData) => {
      const updatedActivities = isChecked
        ? [...prevData.Activities, value] // Add if checked
        : prevData.Activities.filter((item) => item !== value); // Remove if unchecked

      return {
        ...prevData,
        Activities: updatedActivities,
      };
    });
  };

  const handleRequestChange = useCallback(
    (lead, leadData, show) => {
      console.log(lead);

      handleRequestPopupOpen(
        lead.showId,
        lead.companyId,
        lead?.Show || "N/A",
        lead?.Company || "N/A"
      );
    },
    [handleRequestPopupOpen]
  );

  const sendReqHandel = () =>{
    sendEmailRequest()
  }

  return (
    <div className="popup-tabs-contain">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value} className="tab-context">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="tab-box"
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="tab-list"
            >
              <Tab
                label="Client Communication"
                value="1"
                className="tab-info"
              />
              <Tab
                label="Follow-Up & Timeline"
                value="2"
                className="tab-info"
              />
              <Tab
                label="Booking & Preferences"
                value="3"
                className="tab-info"
              />
              <Tab label="Lead Information" value="4" className="tab-info" />
              <Tab label="Pricing Table" value="5" className="tab-info" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="rejection-reason">Rejection Reason:</label>
                <select
                  name="Rejection Reason"
                  id="rejection-reason"
                  className="tab-input input-dropdown"
                  value={dataOfRowId["Rejection Reason"]}
                  onChange={handleInputChange}
                >
                  <option value="" hidden>
                    Select a reason...
                  </option>
                  {optionsReason.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="notes">Notes : </label>
                <input
                  type="text"
                  name="Notes"
                  id="notes"
                  className="tab-input"
                  value={dataOfRowId.Notes}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="contact-name">Contact Name : </label>
                <input
                  type="text"
                  name="Contact Name"
                  id="contact-name"
                  className="tab-input"
                  value={dataOfRowId["Contact Name"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="dm-rating">DM Rating : </label>
                <input
                  type="number"
                  name="DM Rating"
                  id="dm-rating"
                  className="tab-input"
                  value={dataOfRowId["DM Rating"]}
                  onChange={handleInputChange}
                  min="0"
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  name="Email"
                  id="email"
                  className="tab-input"
                  value={dataOfRowId.Email}
                  onChange={handleInputChange}
                />
                {emailError && <p className="error-text">{emailError}</p>}
              </div>
              {role !== "agent" && (
                <div className="tab-input-control">
                  <label htmlFor="closer-note">Closer Note : </label>
                  <input
                    type="text"
                    name="Closer Note"
                    id="closer-note"
                    className="tab-input"
                    value={dataOfRowId["Closer Note"]}
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {/* <div className="tab-input-control">
                <button onClick={sendEmailRequest} style={{ display: "inline-block", width: "fit-content" }}>Send Email Request</button>
              </div> */}

              <div className="tab-inputs-container">
              <div className="tab-input-control">
                <label htmlFor="Email Type">Email Type :</label>
                <select
                  name="Email Type"
                  id="Email Type"
                  className="tab-input input-dropdown"
                  value={dataOfRowId["Email Type"]}
                  onChange={handleInputChange}
                >
                  <option value="" hidden>
                    Select a type...
                  </option>
                  {optionsEmailType.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="Email Notes">Email Notes : </label>
                <input
                  type="text"
                  name="Email Notes"
                  id="Email Notes"
                  className="tab-input"
                  value={dataOfRowId["Email Notes"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                {alertEmailTypeMessageActive && <p style={{color:"red"}}>You Must Choose Check-in , Check-out & number of rooms</p>}
                <button style={{marginTop:"10px", width:"350px"}} onClick={sendReqHandel}>Send Email Request</button>
              </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="daysToCall">Days to Call : </label>
                <input
                  type="number"
                  name="Days to Call"
                  id="daysToCall"
                  className="tab-input"
                  min="0"
                  value={dataOfRowId["Days to Call"]}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="lastContact">Last Contact : </label>
                <input
                  type="text"
                  name="Last Contact"
                  id="lastContact"
                  className="tab-input"
                  value={dataOfRowId["Last Contact"]}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="tab-input-control">
                <label htmlFor="nextContact">Next Contact : </label>
                <input
                  type="text"
                  name="Next Contact"
                  id="nextContact"
                  className="tab-input"
                  value={dataOfRowId["Next Contact"]}
                  onChange={handleInputChange}
                />
              </div> */}
              <div className="tab-input-control">
                <label htmlFor="closingDate">Closing Date : </label>
                <input
                  type="date"
                  name="Closing Date"
                  id="closingDate"
                  className="tab-input"
                  value={dataOfRowId["Closing Date"]}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="hotel">Hotel : </label>
                <input
                  type="text"
                  name="Hotel"
                  id="hotel"
                  className="tab-input"
                  value={dataOfRowId.Hotel}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="budget">Budget : </label>
                <input
                  type="text"
                  name="Budget"
                  id="budget"
                  className="tab-input"
                  value={dataOfRowId.Budget}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="maxDistance">Max. Distance : </label>
                <input
                  type="text"
                  name=" Distance"
                  id="maxDistance"
                  className="tab-input"
                  value={dataOfRowId[" Distance"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="rooms">Rooms : </label>
                <input
                  type="number"
                  name="Rooms"
                  id="rooms"
                  className="tab-input"
                  min="0"
                  value={dataOfRowId.Rooms}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="nextDates">Check-in : </label>
                <input
                  type="date"
                  name="Check-in"
                  id="nextDates"
                  className="tab-input"
                  value={dataOfRowId["Check-in"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="endDate">Check-out : </label>
                <input
                  type="date"
                  name="Check-out"
                  id="endDate"
                  className="tab-input"
                  value={dataOfRowId["Check-out"]}
                  onChange={handleInputChange}
                />
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className="tab-input-control">
                    <label htmlFor="preferences">Preferences:</label>
                    <select
                      name="Preferences"
                      id="preferences"
                      className="tab-input input-dropdown"
                      value={selectedPreferences}
                      onChange={(e) => updatePreferences(e.target.value)}
                    >
                      <option value="" hidden>
                        Select a Preference ...
                      </option>
                      {optionsPreferences.map((optionItem) => (
                        <option key={optionItem.value} value={optionItem.value}>
                          {optionItem.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="tab-input-control">
                    <label htmlFor="amenities">Amenities:</label>
                    <div className="dropdown">
                      <div
                        className="dropdown-header"
                        onClick={toggleAmenitiesDropdown}
                      >
                        {selectedAmenities.length > 0
                          ? selectedAmenities.join(", ")
                          : "Select Amenities..."}
                      </div>
                      {isAmenitiesOpen && ( // Show dropdown only when open
                        <div className="dropdown-list">
                          {optionsAmenities.map((optionItem) => (
                            <div
                              key={optionItem.value}
                              className="dropdown-item"
                            >
                              <input
                                type="checkbox"
                                id={optionItem.value}
                                value={optionItem.value}
                                checked={
                                  selectedAmenities.includes(
                                    optionItem.value
                                  ) || false
                                }
                                onChange={(e) =>
                                  updateAmenities(
                                    optionItem.value,
                                    e.target.checked
                                  )
                                }
                              />
                              <label htmlFor={optionItem.value}>
                                {optionItem.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    style={{ height: "40px", marginTop: "35px" }}
                    onClick={addRoomDetail}
                  >
                    Add
                  </button>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <h3>Room Details:</h3>
                  <ul>
                    {dataOfRowId.roomDetails.map((detail, index) => (
                      <div key={index}>
                        <li>
                          <strong>Room {index + 1}:</strong>
                        </li>
                        <li>
                          <strong>Preferences:</strong>{" "}
                          {detail.Preferences || "None"}
                        </li>
                        <li>
                          <strong>Amenities:</strong>{" "}
                          {detail.Amenities.length > 0
                            ? detail.Amenities.join(", ")
                            : "None"}
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="tab-input-control">
                <label htmlFor="activities">Activities:</label>
                <div className="dropdown">
                  <div
                    className="dropdown-header"
                    onClick={toggleActivitesDropdown}
                  >
                    {dataOfRowId.Activities.length > 0
                      ? dataOfRowId.Activities.join(", ")
                      : "Select Activities..."}
                  </div>
                  {isActivitesOpen && (
                    <div className="dropdown-list">
                      {optionsActivities.map((optionItem) => (
                        <div key={optionItem.value} className="dropdown-item">
                          <input
                            type="checkbox"
                            id={optionItem.value}
                            value={optionItem.value}
                            checked={
                              dataOfRowId.Activities.includes(
                                optionItem.value
                              ) || false
                            }
                            onChange={(e) =>
                              updateActivities(
                                optionItem.value,
                                e.target.checked
                              )
                            }
                          />
                          <label htmlFor={optionItem.value}>
                            {optionItem.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="tab-input-control">
                <label htmlFor="roomStay">/ Room Stay : </label>
                <input
                  type="number"
                  name="/ Room Stay"
                  id="roomStay"
                  className="tab-input"
                  value={dataOfRowId["/ Room Stay"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="totalStay">Total Stay: </label>
                <input
                  type="number"
                  name="Total Stay"
                  id="totalStay"
                  className="tab-input"
                  value={dataOfRowId["Total Stay"]}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <RequestDropdown
                  requestSelected={requestSelected}
                  setRequestSelected={setRequestSelected}
                  id={dataOfRowId._id}
                  onRequestChange={() => handleRequestChange(dataOfRowId)}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className="tabs-input-form">
              {/* <div className="tab-input-control">
                <label htmlFor="leadDate">Lead Date : </label>
                <input
                  type="date"
                  name="Lead Date"
                  id="leadDate"
                  className="tab-input"
                  value={dataOfRowId["Lead Date"]}
                  onChange={handleInputChange}
                />
              </div> */}

              <div className="tab-input-control">
                <label htmlFor="leadDateTime">Lead Date: </label>
                <input
                  type="datetime-local"
                  name="Lead Date Time"
                  id="leadDateTime"
                  className="tab-input"
                  value={
                    getShows.length > 0
                      ? new Date(getShows[0].createdAt)
                          .toISOString()
                          .slice(0, 16)
                      : ""
                  }
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              {/* <div className="tab-input-control">
                <label htmlFor="emailType">Email Type :</label>
                <select
                  name="Email Type"
                  id="emailType"
                  className="tab-input input-dropdown"
                  value={dataOfRowId["Email Type"]}
                  onChange={handleInputChange}
                >
                  <option value="" hidden>
                    Select a type...
                  </option>
                  {optionsEmailType.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </TabPanel>
          <TabPanel value="5">
            {isNewActive ? (
              <ShowPricingTable
                getShows={getShows}
                selectedRows={selectedRows}
              />
            ) : (
              <ShowPricingOld
                selectedRows={selectedRows}
                data={data}
                selectedRowByCompany={selectedRowByCompany}
              />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Tabs;







































// import React, { useState, useCallback,useContext } from "react";
// import "./Tabs.css";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import ShowPricingTable from "../pricingTable/ShowPricingTable";
// import ShowPricingOld from "../pricingTable/ShowPricingOld";
// import RequestDropdown from "../RequestDropdown/RequestDropdown";
// import { AuthContext } from "../context/AuthContext"; // Adjust this import path based on your project structure

// const optionsReason = [
//   { label: "Competition Rate", value: "Competition Rate" },
//   { label: "Official Housing Only", value: "Official Housing Only" },
//   { label: "Recently Booked", value: "Recently Booked" },
//   { label: "Early Booked", value: "Early Booked" },
//   { label: "Not Interested", value: "Not Interested" },
//   { label: "All Set", value: "All Set" },
//   { label: "Airbnb", value: "Airbnb" },
//   { label: "Local", value: "Local" },
//   { label: "Homeowner", value: "Homeowner" },
//   { label: "Free Rooms", value: "Free Rooms" },
//   { label: "Next Show", value: "Next Show" },
//   { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
//   { label: "Escalate", value: "Escalate" },
//   { label: "Try Again", value: "Try Again" },
// ];
// const optionsEmailType = [
//   { label: "Not yet", value: "Not yet" },
//   {
//     label: "Pricing (1 Day Before, Last Day)",
//     value: "Pricing (1 Day Before, Last Day)",
//   },
//   {
//     label: "Pricing (2 Day Before, Last Day)",
//     value: "Pricing (2 Day Before, Last Day)",
//   },
//   {
//     label: "Pricing (3 Day Before, Last Day)",
//     value: "Pricing (3 Day Before, Last Day)",
//   },
//   {
//     label: "Pricing (Same Day, Last Day)",
//     value: "Pricing (Same Day, Last Day)",
//   },
//   {
//     label: "Pricing (1 Day Before, Day After)",
//     value: "Pricing (1 Day Before, Day After)",
//   },
//   {
//     label: "Pricing (2 Day Before, Day After)",
//     value: "Pricing (2 Day Before, Day After)",
//   },
//   {
//     label: "Pricing (2 Day Before, 2 Day After)",
//     value: "Pricing (2 Day Before, 2 Day After)",
//   },
//   {
//     label: "Contact Card for Future Reference",
//     value: "Contact Card for Future Reference",
//   },
//   { label: "Mixed", value: "Mixed" },
// ];
// const optionsPreferences = [
//   { label: "King Bed", value: "King Bed" },
//   { label: "2 Queen Beds", value: "2 Queen Beds" },
//   { label: "King & Queens", value: "King & Queens" },
//   { label: "Triple", value: "Triple" },
//   { label: "1 Bed Suite", value: "1 Bed Suite" },
//   { label: "2 Bed Suite", value: "2 Bed Suite" },
//   { label: "1-Bedroom Apt/Suite", value: "1-Bedroom Apt/Suite" },
//   { label: "2-Bedroom Apt/Suite", value: "2-Bedroom Apt/Suite" },
//   { label: "3-Bedroom Apt/Suite", value: "3-Bedroom Apt/Suite" },
//   { label: "Airbnb", value: "Airbnb" },
//   { label: "Villa", value: "Villa" },
// ];
// const optionsAmenities = [
//   { label: "Room Only", value: "Room Only" },
//   { label: "Breakfast", value: "Breakfast" },
//   { label: "HB", value: "HB" },
//   { label: "FB", value: "FB" },
//   { label: "AI", value: "AI" },
//   { label: "Kitchenette", value: "Kitchenette" },
//   { label: "Balcony", value: "Balcony" },
//   { label: "Others", value: "Others" },
// ];
// const optionsActivities = [
//   { label: "Transfers", value: "Transfers" },
//   { label: "Car Rental", value: "Car Rental" },
//   { label: "Air Ticket", value: "Air Ticket" },
//   { label: "Platform Access", value: "Platform Access" },
// ];

// const Tabs = ({
//   dataOfRowId,
//   setDataOfRowId,
//   getShows,
//   selectedRows,
//   isNewActive,
//   data,
//   selectedRowByCompany,
//   requestSelected,
//   setRequestSelected,
//   handleRequestPopupOpen,
//   sendEmailRequest,
// }) => {
//   const { role } = useContext(AuthContext);
//   const [value, setValue] = React.useState("1");
//   const [emailError, setEmailError] = useState("");
//   const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false);
//   const [isActivitesOpen, setIsActivitesOpen] = useState(false);
//   const [selectedPreferences, setSelectedPreferences] = useState("");
//   const [selectedAmenities, setSelectedAmenities] = useState([]);

//   console.log("dataOfRowIddataOfRowId",dataOfRowId);
  

//   const toggleAmenitiesDropdown = () => setIsAmenitiesOpen((prev) => !prev);
//   const toggleActivitesDropdown = () => setIsActivitesOpen((prev) => !prev);

//   const handleChange = React.useCallback((event, newValue) => {
//     setValue(newValue);
//   }, []);

//   const handleInputChange = React.useCallback(
//     (e) => {
//       const { name, value } = e.target;
//       setDataOfRowId((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));

//       if (name === "Email") {
//         if (value === "" || value.includes("@")) {
//           setEmailError("");
//         } else {
//           setEmailError(
//             "Please enter a valid email address & E-mail must include @."
//           );
//           return;
//         }
//       }
//     },
//     [setDataOfRowId]
//   );

//   const updatePreferences = (value) => {
//     setSelectedPreferences(value);
//   };

//   const updateAmenities = (value, isChecked) => {
//     setSelectedAmenities((prev) => {
//       if (isChecked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((amenity) => amenity !== value);
//       }
//     });
//   };

//   const addRoomDetail = () => {
//     // Save selected data into the roomDetails

//     setDataOfRowId((prevData) => ({
//       ...prevData,
//       roomDetails: [
//         ...prevData.roomDetails,
//         { Preferences: selectedPreferences, Amenities: selectedAmenities },
//       ],
//     }));

//     console.log(dataOfRowId.roomDetails);

//     // Clear selections
//     setSelectedPreferences("");
//     setSelectedAmenities([]);
//     setIsAmenitiesOpen(false);
//   };

//   const updateActivities = (value, isChecked) => {
//     setDataOfRowId((prevData) => {
//       const updatedActivities = isChecked
//         ? [...prevData.Activities, value] // Add if checked
//         : prevData.Activities.filter((item) => item !== value); // Remove if unchecked

//       return {
//         ...prevData,
//         Activities: updatedActivities,
//       };
//     });
//   };

//   const handleRequestChange = useCallback(
//     (lead, leadData, show) => {
//       console.log(lead);

//       handleRequestPopupOpen(
//         lead.showId,
//         lead.companyId,
//         lead?.Show || "N/A",
//         lead?.Company || "N/A"
//       );
//     },
//     [handleRequestPopupOpen]
//   );

//   const calculateNextDate = (dateStr) => {
//     if (!dateStr) return "";
//     const date = new Date(dateStr);
//     date.setDate(date.getDate() - 1);
//     return date.toISOString().split("T")[0];
//   };

//   const calculateEndDate = (dateStr) => {
//     if (!dateStr) return "";
//     const date = new Date(dateStr);
//     date.setDate(date.getDate() + 1);
//     return date.toISOString().split("T")[0];
//   };

//   const sendReqHandel = () =>{
//     sendEmailRequest()
//   }

//   return (
//     <div className="popup-tabs-contain">
//       <Box sx={{ width: "100%", typography: "body1" }}>
//         <TabContext value={value} className="tab-context">
//           <Box
//             sx={{ borderBottom: 1, borderColor: "divider" }}
//             className="tab-box"
//           >
//             <TabList
//               onChange={handleChange}
//               aria-label="lab API tabs example"
//               className="tab-list"
//             >
//               <Tab
//                 label="Client Communication"
//                 value="1"
//                 className="tab-info"
//               />
//               <Tab
//                 label="Follow-Up & Timeline"
//                 value="2"
//                 className="tab-info"
//               />
//               <Tab
//                 label="Booking & Preferences"
//                 value="3"
//                 className="tab-info"
//               />
//               <Tab label="Lead Information" value="4" className="tab-info" />
//               <Tab label="Pricing Table" value="5" className="tab-info" />
//             </TabList>
//           </Box>
//           <TabPanel value="1">
//             <div className="tabs-input-form">
//               <div className="tab-input-control">
//                 <label htmlFor="rejection-reason">Rejection Reason:</label>
//                 <select
//                   name="Rejection Reason"
//                   id="rejection-reason"
//                   className="tab-input input-dropdown"
//                   value={dataOfRowId["Rejection Reason"]}
//                   onChange={handleInputChange}
//                 >
//                   <option value="" hidden>
//                     Select a reason...
//                   </option>
//                   {optionsReason.map((optionItem) => (
//                     <option key={optionItem.value} value={optionItem.value}>
//                       {optionItem.label}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="notes">Notes : </label>
//                 <input
//                   type="text"
//                   name="Notes"
//                   id="notes"
//                   className="tab-input"
//                   value={dataOfRowId.Notes}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="contact-name">Contact Name : </label>
//                 <input
//                   type="text"
//                   name="Contact Name"
//                   id="contact-name"
//                   className="tab-input"
//                   value={dataOfRowId["Contact Name"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="dm-rating">DM Rating : </label>
//                 <input
//                   type="number"
//                   name="DM Rating"
//                   id="dm-rating"
//                   className="tab-input"
//                   value={dataOfRowId["DM Rating"]}
//                   onChange={handleInputChange}
//                   min="0"
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="email">Email : </label>
//                 <input
//                   type="email"
//                   name="Email"
//                   id="email"
//                   className="tab-input"
//                   value={dataOfRowId.Email}
//                   onChange={handleInputChange}
//                 />
//                 {emailError && <p className="error-text">{emailError}</p>}
//               </div>
//               {role !== "agent" && (
//                 <div className="tab-input-control">
//                   <label htmlFor="closer-note">Closer Note : </label>
//                   <input
//                     type="text"
//                     name="Closer Note"
//                     id="closer-note"
//                     className="tab-input"
//                     value={dataOfRowId["Closer Note"]}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               )}

//               {/* <div className="tab-input-control">
//                 <button onClick={sendEmailRequest} style={{ display: "inline-block", width: "fit-content" }}>Send Email Request</button>
//               </div> */}

//               <div className="tab-inputs-container">
//               <div className="tab-input-control">
//                 <label htmlFor="Email Type">Email Type :</label>
//                 <select
//                   name="Email Type"
//                   id="Email Type"
//                   className="tab-input input-dropdown"
//                   value={dataOfRowId["Email Type"]}
//                   onChange={handleInputChange}
//                 >
//                   <option value="" hidden>
//                     Select a type...
//                   </option>
//                   {optionsEmailType.map((optionItem) => (
//                     <option key={optionItem.value} value={optionItem.value}>
//                       {optionItem.label}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="Email Notes">Email Notes : </label>
//                 <input
//                   type="text"
//                   name="Email Notes"
//                   id="Email Notes"
//                   className="tab-input"
//                   value={dataOfRowId["Email Notes"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <button style={{marginTop:"10px", width:"350px"}} onClick={sendReqHandel}>Send Email Request</button>
//               </div>
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value="2">
//             <div className="tabs-input-form">
//               <div className="tab-input-control">
//                 <label htmlFor="daysToCall">Days to Call : </label>
//                 <input
//                   type="number"
//                   name="Days to Call"
//                   id="daysToCall"
//                   className="tab-input"
//                   min="0"
//                   value={dataOfRowId["Days to Call"]}
//                   onChange={handleInputChange}
//                   disabled
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="lastContact">Last Contact : </label>
//                 <input
//                   type="text"
//                   name="Last Contact"
//                   id="lastContact"
//                   className="tab-input"
//                   value={dataOfRowId["Last Contact"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               {/* <div className="tab-input-control">
//                 <label htmlFor="nextContact">Next Contact : </label>
//                 <input
//                   type="text"
//                   name="Next Contact"
//                   id="nextContact"
//                   className="tab-input"
//                   value={dataOfRowId["Next Contact"]}
//                   onChange={handleInputChange}
//                 />
//               </div> */}
//               <div className="tab-input-control">
//                 <label htmlFor="closingDate">Closing Date : </label>
//                 <input
//                   type="date"
//                   name="Closing Date"
//                   id="closingDate"
//                   className="tab-input"
//                   value={dataOfRowId["Closing Date"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value="3">
//             <div className="tabs-input-form">
//               <div className="tab-input-control">
//                 <label htmlFor="hotel">Hotel : </label>
//                 <input
//                   type="text"
//                   name="Hotel"
//                   id="hotel"
//                   className="tab-input"
//                   value={dataOfRowId.Hotel}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="budget">Budget : </label>
//                 <input
//                   type="text"
//                   name="Budget"
//                   id="budget"
//                   className="tab-input"
//                   value={dataOfRowId.Budget}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="maxDistance">Max. Distance : </label>
//                 <input
//                   type="text"
//                   name=" Distance"
//                   id="maxDistance"
//                   className="tab-input"
//                   value={dataOfRowId[" Distance"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="rooms">Rooms : </label>
//                 <input
//                   type="number"
//                   name="Rooms"
//                   id="rooms"
//                   className="tab-input"
//                   min="0"
//                   value={dataOfRowId.Rooms}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="nextDates">Check-in : </label>
//                 <input
//                   type="date"
//                   name="Next Dates"
//                   id="nextDates"
//                   className="tab-input"
//                   value={calculateNextDate(dataOfRowId["Next Dates"])}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="endDate">Check-out : </label>
//                 <input
//                   type="date"
//                   name="End Date"
//                   id="endDate"
//                   className="tab-input"
//                   value={calculateEndDate(dataOfRowId["End Date"])}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div style={{ width: "100%" }}>
//                 <div
//                   style={{
//                     display: "flex",
//                     width: "100%",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div className="tab-input-control">
//                     <label htmlFor="preferences">Preferences:</label>
//                     <select
//                       name="Preferences"
//                       id="preferences"
//                       className="tab-input input-dropdown"
//                       value={selectedPreferences}
//                       onChange={(e) => updatePreferences(e.target.value)}
//                     >
//                       <option value="" hidden>
//                         Select a Preference ...
//                       </option>
//                       {optionsPreferences.map((optionItem) => (
//                         <option key={optionItem.value} value={optionItem.value}>
//                           {optionItem.label}
//                         </option>
//                       ))}
//                     </select>
//                   </div>

//                   <div className="tab-input-control">
//                     <label htmlFor="amenities">Amenities:</label>
//                     <div className="dropdown">
//                       <div
//                         className="dropdown-header"
//                         onClick={toggleAmenitiesDropdown}
//                       >
//                         {selectedAmenities.length > 0
//                           ? selectedAmenities.join(", ")
//                           : "Select Amenities..."}
//                       </div>
//                       {isAmenitiesOpen && ( // Show dropdown only when open
//                         <div className="dropdown-list">
//                           {optionsAmenities.map((optionItem) => (
//                             <div
//                               key={optionItem.value}
//                               className="dropdown-item"
//                             >
//                               <input
//                                 type="checkbox"
//                                 id={optionItem.value}
//                                 value={optionItem.value}
//                                 checked={
//                                   selectedAmenities.includes(
//                                     optionItem.value
//                                   ) || false
//                                 }
//                                 onChange={(e) =>
//                                   updateAmenities(
//                                     optionItem.value,
//                                     e.target.checked
//                                   )
//                                 }
//                               />
//                               <label htmlFor={optionItem.value}>
//                                 {optionItem.label}
//                               </label>
//                             </div>
//                           ))}
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                   <button
//                     style={{ height: "40px", marginTop: "35px" }}
//                     onClick={addRoomDetail}
//                   >
//                     Add
//                   </button>
//                 </div>
//                 <div style={{ marginTop: "1rem" }}>
//                   <h3>Room Details:</h3>
//                   <ul>
//                     {dataOfRowId.roomDetails.map((detail, index) => (
//                       <div key={index}>
//                         <li>
//                           <strong>Room {index + 1}:</strong>
//                         </li>
//                         <li>
//                           <strong>Preferences:</strong>{" "}
//                           {detail.Preferences || "None"}
//                         </li>
//                         <li>
//                           <strong>Amenities:</strong>{" "}
//                           {detail.Amenities.length > 0
//                             ? detail.Amenities.join(", ")
//                             : "None"}
//                         </li>
//                       </div>
//                     ))}
//                   </ul>
//                 </div>
//               </div>

//               <div className="tab-input-control">
//                 <label htmlFor="activities">Activities:</label>
//                 <div className="dropdown">
//                   <div
//                     className="dropdown-header"
//                     onClick={toggleActivitesDropdown}
//                   >
//                     {dataOfRowId.Activities.length > 0
//                       ? dataOfRowId.Activities.join(", ")
//                       : "Select Activities..."}
//                   </div>
//                   {isActivitesOpen && (
//                     <div className="dropdown-list">
//                       {optionsActivities.map((optionItem) => (
//                         <div key={optionItem.value} className="dropdown-item">
//                           <input
//                             type="checkbox"
//                             id={optionItem.value}
//                             value={optionItem.value}
//                             checked={
//                               dataOfRowId.Activities.includes(
//                                 optionItem.value
//                               ) || false
//                             }
//                             onChange={(e) =>
//                               updateActivities(
//                                 optionItem.value,
//                                 e.target.checked
//                               )
//                             }
//                           />
//                           <label htmlFor={optionItem.value}>
//                             {optionItem.label}
//                           </label>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>

//               <div className="tab-input-control">
//                 <label htmlFor="roomStay">/ Room Stay : </label>
//                 <input
//                   type="number"
//                   name="/ Room Stay"
//                   id="roomStay"
//                   className="tab-input"
//                   value={dataOfRowId["/ Room Stay"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="tab-input-control">
//                 <label htmlFor="totalStay">Total Stay: </label>
//                 <input
//                   type="number"
//                   name="Total Stay"
//                   id="totalStay"
//                   className="tab-input"
//                   value={dataOfRowId["Total Stay"]}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div>
//                 <RequestDropdown
//                   requestSelected={requestSelected}
//                   setRequestSelected={setRequestSelected}
//                   id={dataOfRowId._id}
//                   onRequestChange={() => handleRequestChange(dataOfRowId)}
//                 />
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value="4">
//             <div className="tabs-input-form">
//               {/* <div className="tab-input-control">
//                 <label htmlFor="leadDate">Lead Date : </label>
//                 <input
//                   type="date"
//                   name="Lead Date"
//                   id="leadDate"
//                   className="tab-input"
//                   value={dataOfRowId["Lead Date"]}
//                   onChange={handleInputChange}
//                 />
//               </div> */}

//               <div className="tab-input-control">
//                 <label htmlFor="leadDateTime">Lead Date: </label>
//                 <input
//                   type="datetime-local"
//                   name="Lead Date Time"
//                   id="leadDateTime"
//                   className="tab-input"
//                   value={
//                     getShows.length > 0
//                       ? new Date(getShows[0].createdAt)
//                           .toISOString()
//                           .slice(0, 16)
//                       : ""
//                   }
//                   onChange={handleInputChange}
//                   disabled
//                 />
//               </div>

//               {/* <div className="tab-input-control">
//                 <label htmlFor="emailType">Email Type :</label>
//                 <select
//                   name="Email Type"
//                   id="emailType"
//                   className="tab-input input-dropdown"
//                   value={dataOfRowId["Email Type"]}
//                   onChange={handleInputChange}
//                 >
//                   <option value="" hidden>
//                     Select a type...
//                   </option>
//                   {optionsEmailType.map((optionItem) => (
//                     <option key={optionItem.value} value={optionItem.value}>
//                       {optionItem.label}
//                     </option>
//                   ))}
//                 </select>
//               </div> */}
//             </div>
//           </TabPanel>
//           <TabPanel value="5">
//             {isNewActive ? (
//               <ShowPricingTable
//                 getShows={getShows}
//                 selectedRows={selectedRows}
//               />
//             ) : (
//               <ShowPricingOld
//                 selectedRows={selectedRows}
//                 data={data}
//                 selectedRowByCompany={selectedRowByCompany}
//               />
//             )}
//           </TabPanel>
//         </TabContext>
//       </Box>
//     </div>
//   );
// };

// export default Tabs;
