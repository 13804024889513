import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { baseUrl } from "../../../api/Api";

const amenitiesList = [
  "parking",
  "airConditioned",
  "spa",
  "restaurant",
  "gym",
  "reception24Hour",
  "internet",
  "swimmingPool",
];

const CreateHotelInMatchedPricing = ({
  pricingId,
  token,
  onClose,
  onHotelCreated,
  setIsModalOpen,
}) => {
  const [hotelData, setHotelData] = useState({
    "Hotel Name": "",
    Stars: "",
    "Image URL": "",
    "Average Nightly Rate with Markup": "",
    "Free Cancellation Until": "",
    "Boarding Type": "",
    Distance: "",
    Address: "",
    Amenities: amenitiesList.reduce((acc, amenity) => {
      acc[amenity] = false;
      return acc;
    }, {}),
    "Room Type": "",
  });

  console.log(pricingId);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setHotelData((prevState) => ({
        ...prevState,
        Amenities: {
          ...prevState.Amenities,
          [name]: checked ? "Yes" : "No",
        },
      }));
    } else {
      setHotelData({ ...hotelData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/create-hotel/${pricingId}`,
        {
          hotel: {
            ...hotelData,
            Amenities: Object.fromEntries(
              Object.entries(hotelData.Amenities).map(([key, value]) => [
                key,
                value === "Yes" ? true : false,
              ])
            ),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onHotelCreated(response.data);
      onClose();
    } catch (error) {
      console.error("Error creating hotel:", error);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {Object.keys(hotelData)
              .filter((key) => key !== "Amenities")
              .map((key) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <TextField
                    label={key.replace(/([A-Z])/g, " $1").trim()}
                    name={key}
                    value={hotelData[key]}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Box>
                <Box sx={{ mb: 2 }}>
                  <strong>Amenities</strong>
                </Box>
                {amenitiesList.map((amenity) => (
                  <FormControlLabel
                    key={amenity}
                    control={
                      <Checkbox
                        checked={hotelData.Amenities[amenity] === "Yes"}
                        onChange={handleChange}
                        name={amenity}
                      />
                    }
                    label={amenity}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Create Hotel
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateHotelInMatchedPricing;
