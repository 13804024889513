import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const UpdateExistingLeadModal = ({ open, handleClose, lead, onUpdate }) => {
  const [formData, setFormData] = useState({
    Shift: lead.Shift || "",
    Phone: lead.Phone || "",
    Email: lead.Email || "",
    DMName: lead["DM Name"] || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onUpdate(lead._id, formData);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Update Lead
        </Typography>
        <TextField
          fullWidth
          label="Shift"
          name="Shift"
          value={formData.Shift}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Phone"
          name="Phone"
          value={formData.Phone}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="Email"
          value={formData.Email}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="DM Name"
          name="DMName"
          value={formData.DMName}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateExistingLeadModal;
