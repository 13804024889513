import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { baseUrl } from "../../../api/Api";

const CreateRawPricingModal = ({ token, onClose, onPricingCreated }) => {
  const [showName, setShowName] = useState("");

  const handleChange = (event) => {
    setShowName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/create-raw-pricing`,
        { showName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Call the callback to pass the created pricing data to the parent component
      console.log(response.data);

      onPricingCreated(response.data.data);
      onClose();
    } catch (error) {
      console.error("Error creating pricing:", error);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Show Name"
                name="showName"
                value={showName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Create Pricing
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateRawPricingModal;
