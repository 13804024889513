import React from "react";
import ResDrop from "./ResDrop";

const FiltersSection = ({
  handleChangeFilter,
  companyOptions,
  selectedCompany,
  statusOptions,
  selectedStatus,
  selectedShowName,
  selectedTime,
  showNameOptions,
  timeZoneOptions,
  updatedAtOptions,
  selectedTimeZoneFilter,
}) => {
  console.log(updatedAtOptions);

  return (
    <div className="main-header-info filters-section-container">
      <ResDrop
        label="TimeZone"
        options={timeZoneOptions}
        selectedValue={selectedTimeZoneFilter}
        handleChange={handleChangeFilter}
        name="timeZone"
      />
      <ResDrop
        label="ShowName"
        options={showNameOptions}
        selectedValue={selectedShowName}
        handleChange={handleChangeFilter}
        name="showName"
      />
      <ResDrop
        label="Status"
        options={statusOptions}
        selectedValue={selectedStatus}
        handleChange={handleChangeFilter}
        name="status"
      />
      <ResDrop
        label="Company"
        options={companyOptions}
        selectedValue={selectedCompany}
        handleChange={handleChangeFilter}
        name="companies"
      />

<div className="filter-dropDown-container">
        <label htmlFor="updateTime-dropdown" className="label-dropdown-filter linear">
          <h4>Update Time</h4>
        </label>
        <select
          name="Update Time"
          id="updateTime-dropdown"
          className="dropdown-filter"
          value={selectedTime}
          onChange={handleChangeFilter}
        >
          <option value="" className="dropdown-filter-option">
            Select Update Time
          </option>
          {updatedAtOptions?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FiltersSection;
