import React from 'react'
import './AuthForm.css'
import { MdWavingHand } from "react-icons/md";
import LoginForm from '../../components/userForm/LoginForm';

const Login = () => {
  return (
    <div className="auth-form-container login-container">
        <div className="auth-form-contain">
          <div className="form-blur-background login-blur-background">
            <h1 className='auth-form-header login-header'>Welcome Back!</h1>
            <p className="auth-form-text">Glad to see you again <MdWavingHand className='hand-icon'/></p>
            <p className="auth-form-text">login to your account below</p>
            <LoginForm/>
          </div>
        </div>
    </div>
  )
}

export default Login