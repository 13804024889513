import React, { useContext, useState } from "react";
import "./PopUp.css";
import axios from "axios";
import Tabs from "../Tabs/Tabs";
import OverlayStatus from "./OverlayStatus";
import { baseUrl } from "../../api/Api";
import { AuthContext } from "../context/AuthContext";
import { Snackbar, Alert } from "@mui/material";

const AnswerPopUp = ({
  dataOfRowId,
  handleUpdateSubmit,
  overLayStatusActive,
  handleCloseStatusPopup,
  handleCloseUpdateSubmit,
  setDataOfRowId,
  getShows,
  selectedRows,
  isNewActive,
  data,
  selectedRowByCompany,
  handleRequestPopupOpen,
  requestSelected,
  setRequestSelected,
  requestedData,
  leadDataForRequest,
}) => {
  const { token } = useContext(AuthContext);
  const [open, setOpen] = useState(false); // State to control Snackbar visibility
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // Set initial severity type (success, error, etc.)

  const sendRequest = async () => {
    if (!requestSelected && !requestedData && !dataOfRowId?.["Email Type"]) {
      console.error(
        "Request or required data is missing, not sending request."
      );
      setAlertMessage("Error sending request!");
      setSeverity("error");
      setOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/agents/send-request`,
        {
          showId: leadDataForRequest.showId,
          companyId: leadDataForRequest.companyId,
          request: "Email Request",
          requestedData: dataOfRowId?.["Email Type"],
          notes: dataOfRowId?.["Email Notes"],
          Email: dataOfRowId.Email,
          ContactName: dataOfRowId?.["Contact Name"],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response);
      setAlertMessage("Request sent successfully!");
      setSeverity("success");
      setOpen(true);
    } catch (error) {
      console.error("Error sending request:", error);
      setAlertMessage("Error sending request!");
      setSeverity("error");
      setOpen(true);
    }
  };

  return (
    <div className="answerPopUp-container">
      <div className="popup-content">
        <div className="popup-content-header">
          <div className="popup-info">
            <h3 className="popup-header ">
              <span className="linear">Show Name :</span> {dataOfRowId.Show}
            </h3>
            <h3 className="popup-header">
              <span className="linear">Company Name :</span>
              {dataOfRowId.Company}
            </h3>
            <h3 className="popup-header">
              <span className="linear">TimeZone :</span> {dataOfRowId.Timezone}
            </h3>
          </div>
          <div className="popup-button">
            <button
              className="save-btn"
              type="submit"
              onClick={handleUpdateSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="popup-tabs-section">
          <Tabs
            dataOfRowId={dataOfRowId}
            setDataOfRowId={setDataOfRowId}
            getShows={getShows}
            selectedRows={selectedRows}
            isNewActive={isNewActive}
            data={data}
            selectedRowByCompany={selectedRowByCompany}
            requestSelected={requestSelected}
            setRequestSelected={setRequestSelected}
            handleRequestPopupOpen={handleRequestPopupOpen}
            sendEmailRequest={sendRequest}
          />
        </div>
      </div>
      {overLayStatusActive && (
        <OverlayStatus
          handleCloseUpdateSubmit={handleCloseUpdateSubmit}
          handleCloseStatusPopup={handleCloseStatusPopup}
          dataOfRowId={dataOfRowId}
          setDataOfRowId={setDataOfRowId}
          sendEmailRequest={sendRequest}
        />
      )}

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={6000} // Hide after 6 seconds
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Position the Snackbar at the bottom-left
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AnswerPopUp;

// import React, { useEffect, useContext } from "react";
// import "./PopUp.css";
// import axios from "axios";
// import Tabs from "../Tabs/Tabs";
// import OverlayStatus from "./OverlayStatus";
// import { baseUrl } from "../../api/Api";
// import { AuthContext } from "../context/AuthContext";

// const AnswerPopUp = ({
//   dataOfRowId,
//   handleUpdateSubmit,
//   overLayStatusActive,
//   handleCloseUpdateSubmit,
//   setDataOfRowId,
//   getShows,
//   selectedRows,
//   isNewActive,
//   data,
//   selectedRowByCompany,
//   handleRequestPopupOpen,
//   requestSelected,
//   setRequestSelected,
//   requestedData,
//   leadDataForRequest,
// }) => {
//   const { token } = useContext(AuthContext);

//   const sendRequest = async () => {

//     if (!requestSelected && !requestedData && !dataOfRowId?.["Email Type"]) {
//       console.error(
//         "Request or required data is missing, not sending request."
//       );
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/agents/send-request`,
//         {
//           showId: leadDataForRequest.showId,
//           companyId: leadDataForRequest.companyId,
//           request: "Email Request",
//           requestedData: dataOfRowId?.["Email Type"],
//           notes: dataOfRowId?.["Email Notes"],
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("response", response);
//     } catch (error) {
//       console.error("Error sending request:", error);
//     }
//   };

//   return (
//     <div className="answerPopUp-container">
//       <div className="popup-content">
//         <div className="popup-content-header">
//           <div className="popup-info">
//             <h3 className="popup-header ">
//               <span className="linear">Show Name :</span> {dataOfRowId.Show}
//             </h3>
//             <h3 className="popup-header">
//               <span className="linear">Company Name :</span>
//               {dataOfRowId.Company}
//             </h3>
//             <h3 className="popup-header">
//               <span className="linear">TimeZone :</span> {dataOfRowId.Timezone}
//             </h3>
//           </div>
//           <div className="popup-button">
//             <button
//               className="save-btn"
//               type="submit"
//               onClick={handleUpdateSubmit}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//         <div className="popup-tabs-section">
//           <Tabs
//             dataOfRowId={dataOfRowId}
//             setDataOfRowId={setDataOfRowId}
//             getShows={getShows}
//             selectedRows={selectedRows}
//             isNewActive={isNewActive}
//             data={data}
//             selectedRowByCompany={selectedRowByCompany}
//             requestSelected={requestSelected}
//             setRequestSelected={setRequestSelected}
//             handleRequestPopupOpen={handleRequestPopupOpen}
//             sendEmailRequest={sendRequest}
//           />
//         </div>
//       </div>
//       {overLayStatusActive && (
//         <OverlayStatus
//           handleCloseUpdateSubmit={handleCloseUpdateSubmit}
//           dataOfRowId={dataOfRowId}
//           setDataOfRowId={setDataOfRowId}
//           sendEmailRequest={sendRequest}
//         />
//       )}
//     </div>
//   );
// };

// export default AnswerPopUp;

// import React, { useEffect, useContext } from "react";
// import "./PopUp.css";
// import axios from "axios";
// import Tabs from "../Tabs/Tabs";
// import OverlayStatus from "./OverlayStatus";
// import { baseUrl } from "../../api/Api";
// import { AuthContext } from "../context/AuthContext";

// const AnswerPopUp = ({
//   dataOfRowId,
//   handleUpdateSubmit,
//   overLayStatusActive,
//   handleCloseUpdateSubmit,
//   setDataOfRowId,
//   getShows,
//   selectedRows,
//   isNewActive,
//   data,
//   selectedRowByCompany,
//   handleRequestPopupOpen,
//   requestSelected,
//   setRequestSelected,
//   requestedData,
//   setRequestedData,
//   notes,
//   setRequestedNotes,
//   leadDataForRequest,
//   isHistoryActive,
// }) => {
//   const { token } = useContext(AuthContext);

//   // const handleEmailOptionSelect = () => {
//   //   if (dataOfRowId?.["Email Type"] && dataOfRowId?.["Email Notes"]) {
//   //     setRequestedData("Email Request");
//   //   } else {
//   //     setRequestedData("");
//   //   }
//   // };

//   const sendRequest = async () => {
//     // if (!requestSelected ) {
//     //   console.error("Request is required but not set.");
//     //   // return;
//     // }

//     if (!requestSelected && !requestedData && !dataOfRowId?.["Email Type"]) {
//       console.error(
//         "Request or required data is missing, not sending request."
//       );
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/agents/send-request`,
//         {
//           showId: leadDataForRequest.showId,
//           companyId: leadDataForRequest.companyId,
//           request: "Email Request",
//           requestedData: dataOfRowId?.["Email Type"],
//           notes: dataOfRowId?.["Email Notes"],
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("response", response);
//     } catch (error) {
//       console.error("Error sending request:", error);
//     }
//   };

//   // useEffect(() => {
//   //   handleEmailOptionSelect();
//   //   // if (isHistoryActive && dataOfRowId?.["Email Type"]) {
//   //   //   sendRequest();
//   //   // }
//   //   if (
//   //     (isHistoryActive || isNewActive) &&
//   //     dataOfRowId?.["Email Type"] &&
//   //     dataOfRowId?.["Email Notes"]
//   //   ) {
//   //     sendRequest();
//   //   }
//   // }, [dataOfRowId?.["Email Type"] , dataOfRowId?.["Email Notes"]]);

//   // const sendRequest = async () => {
//   //   // Ensure requestSelected is set correctly
//   //   if (!requestSelected) {
//   //     console.error("Request is required but not set.");
//   //     return; // Prevent sending the request if it's not set
//   //   }

//   //   try {
//   //     const response = await axios.post(
//   //       `${baseUrl}/api/agents/send-request`,
//   //       {
//   //         showId: leadDataForRequest.showId,
//   //         companyId: leadDataForRequest.companyId,
//   //         request: requestSelected, // Use requestSelected instead of selectEmailReq
//   //         requestedData: dataOfRowId?.["Email Type"], // Adjust this if necessary
//   //       },
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`,
//   //         },
//   //       }
//   //     );
//   //     handleCloseUpdateSubmit(); // Close overlay or popup as needed
//   //     console.log("response", response);
//   //   } catch (error) {
//   //     console.error("Error sending request:", error);
//   //   }
//   // };

//   // // Example of setting requestSelected
//   // const handleEmailOptionSelect = () => {
//   //   if (dataOfRowId?.["Email Type"]) {
//   //     console.log("Email Type::::::", dataOfRowId?.["Email Type"]);
//   //     setRequestSelected("Email Request");
//   //   } else {
//   //     // You might want to set a custom request based on user input
//   //     const CustomRequest = prompt("Enter your custom request:"); // or however you gather this
//   //     setRequestSelected(CustomRequest || ""); // Default to empty if canceled
//   //   }
//   // };

//   return (
//     <div className="answerPopUp-container">
//       {/* <button
//         className="close-btn"
//         type="button"
//         onClick={() => {
//           setPopUpActive(false);
//         }}
//       >
//         x
//       </button> */}
//       <div className="popup-content">
//         <div className="popup-content-header">
//           <div className="popup-info">
//             <h3 className="popup-header ">
//               <span className="linear">Show Name :</span> {dataOfRowId.Show}
//             </h3>
//             <h3 className="popup-header">
//               <span className="linear">Company Name :</span>
//               {dataOfRowId.Company}
//             </h3>
//             <h3 className="popup-header">
//               <span className="linear">TimeZone :</span> {dataOfRowId.Timezone}
//             </h3>
//           </div>
//           <div className="popup-button">
//             <button
//               className="save-btn"
//               type="submit"
//               onClick={handleUpdateSubmit}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//         <div className="popup-tabs-section">
//           <Tabs
//             dataOfRowId={dataOfRowId}
//             setDataOfRowId={setDataOfRowId}
//             getShows={getShows}
//             selectedRows={selectedRows}
//             isNewActive={isNewActive}
//             data={data}
//             selectedRowByCompany={selectedRowByCompany}
//             requestSelected={requestSelected}
//             setRequestSelected={setRequestSelected}
//             handleRequestPopupOpen={handleRequestPopupOpen}
//             sendEmailRequest={sendRequest}
//           />
//         </div>
//       </div>
//       {overLayStatusActive && (
//         <OverlayStatus
//           handleCloseUpdateSubmit={handleCloseUpdateSubmit}
//           dataOfRowId={dataOfRowId}
//           setDataOfRowId={setDataOfRowId}
//           sendEmailRequest={sendRequest}
//         />
//       )}
//     </div>
//   );
// };

// export default AnswerPopUp;
