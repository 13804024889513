import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../components/context/AuthContext";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Select,
  Grid,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import { format } from "date-fns";
import { baseUrl } from "../../api/Api";

const AllAgents = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/get-team`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response.data.agents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching team members:", error);
        setError("Failed to fetch team members. Please try again.");
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, [token]);

  const handleRowClick = (id) => {
    navigate(`/closer-history/${id}`, { state: { teamMembers } });
  };

  const handleAgnetRowClick = (id) => {
    console.log(id);

    navigate(`/agent-member-history/${id}`);
  };

  const handleViewReports = (id) => {
    console.log(id);

    navigate(`/agent-reports/${id}`);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return format(date, "MMM dd, yyyy h:mm a");
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  console.log(teamMembers);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Role</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Logged In</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Last Seen</strong>
              </TableCell>
              <TableCell
                sx={{
                  color: "wheat",
                  width: "300px",
                }}
              >
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMembers.map((member) => (
              <TableRow>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.role}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell
                  sx={{
                    width: "120px",
                    maxWidth: "120px",
                    textAlign: "center",
                  }}
                >
                  <Circle
                    sx={{
                      color: member.loggedIn ? "green" : "red",
                      fontSize: "1rem",
                    }}
                  />
                </TableCell>
                <TableCell>
                  {member.loggedIn ? "Online" : formatDate(member.lastSeen)}
                </TableCell>
                <TableCell
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedUser(member);
                      setOpenModal(true);
                    }}
                  >
                    View Team
                  </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    key={member.userId}
                    onClick={() => handleAgnetRowClick(member._id)}
                  >
                    View Leads
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    key={member.userId}
                    onClick={() => handleViewReports(member._id)}
                  >
                    Reports
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Team Members</DialogTitle>
        <DialogContent>
          {selectedUser ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "wheat" }}>Name</TableCell>
                    <TableCell sx={{ color: "wheat" }}>Email</TableCell>
                    <TableCell sx={{ color: "wheat" }}>Role</TableCell>
                    <TableCell sx={{ color: "wheat" }}>Leads count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUser.team && selectedUser.team.length > 0 ? (
                    selectedUser.team.map((member) => (
                      <TableRow
                        key={member.userId}
                        onClick={() => handleAgnetRowClick(member.userId)}
                      >
                        <TableCell>{member.name}</TableCell>
                        <TableCell>{member.email}</TableCell>
                        <TableCell>{member.role}</TableCell>
                        <TableCell>{member.assignedLeadCount}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography>No team members</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No user selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllAgents;
