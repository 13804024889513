import React from 'react'
import './AuthForm.css'
import SignUpForm from '../../components/userForm/SignUpForm'

const SignUp = () => {
  return (
    <div className="auth-form-container">
        <div className="auth-form-contain">
          <div className="form-blur-background">
            <h1 className='auth-form-header'>Create Your Account!</h1>
            <p className="auth-form-text">Once You Register, You Will Have <br/>Access To All CRM Features</p>
            <SignUpForm/>
            {/* <div className='line'></div>
            <div className="forms-link">
                <p>Already Have An Account?<a href="/">Login</a></p>
            </div> */}
          </div>
        </div>
    </div>
  )
}

export default SignUp