import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./HouseManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import UploadCustomerSupportSection from "../../components/CustomerSupport/UploadCustomerSupportSection";
import HouseLeadsTable from "../../components/House/HouseLeadsTable";
import axios from "axios";
import { baseUrl } from "../../api/Api";

const HouseManager = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLeadsTableActive, setIsLeadsTableActive] = useState(true);
  const [activeShows, setActiveShows] = useState([]);
  //   const [isUploadSectionActive, setIsUploadSectionActive] = useState(false);

  const { role, token } = useContext(AuthContext);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const showExistingLeads = () => {
    setIsLeadsTableActive(true);
    // setIsUploadSectionActive(false);
  };

  //   const showUploadSection = () => {
  //     setIsRawExistingLeadsActive(false);
  //     setIsUploadSectionActive(true);
  //   };

  useEffect(() => {
    const fetchActiveShows = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/house/get-active-shows`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setActiveShows(response.data);
      } catch (error) {
        console.error("Error fetching active shows:", error);
      }
    };

    fetchActiveShows();
  }, [token]);

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">House View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showExistingLeads} className="sidebar-btn">
                House Leads
              </button>
              {/* <button onClick={showUploadSection} className="sidebar-btn">
                Upload Existings
              </button> */}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isLeadsTableActive && (
          <div className="reuse-container">
            <h2 className="table-title">House Leads</h2>
            <HouseLeadsTable activeShows={activeShows} />
          </div>
        )}

        {/* {isUploadSectionActive && (
          <div className="reuse-container">
            <h2 className="table-title">Upload Existing Leads</h2>
            <UploadCustomerSupportSection />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default HouseManager;
