import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SubTable = ({ amenities }) => {
  console.log(amenities);

  // Check if amenities is not empty
  const names = amenities?.length > 0 ? Object.keys(amenities[0]) : [];
  const values = amenities?.length > 0 ? Object.values(amenities[0]) : [];

  console.log(names);
  console.log(values);

  return (
    <Table size="small" sx={{ border: "1px solid #ddd", margin: "0 auto" }}>
      <TableBody>
        <TableRow>
          {names?.length > 0 ? (
            names.map((name, index) => (
              <StyledTableCell key={`amenity-name-${index}`}>
                {name}
              </StyledTableCell>
            ))
          ) : (
            <StyledTableCell colSpan={1}>
              No Amenities Available
            </StyledTableCell>
          )}
        </TableRow>
        <TableRow>
          {values?.length > 0 ? (
            values.map((value, index) => (
              <StyledTableCell key={`amenity-value-${index}`}>
                {value ? "Yes" : "No"} {/* Or any appropriate display */}
              </StyledTableCell>
            ))
          ) : (
            <StyledTableCell colSpan={1}>
              No Amenities Available
            </StyledTableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const SalesSupportPricingTable = ({ data }) => {
  
  console.log(data);

  return (
    <TableContainer component={Paper} sx={{ height: "500px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Hotel Name</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
            <StyledTableCell>Amenities</StyledTableCell>
            <StyledTableCell>Boarding Type</StyledTableCell>
            <StyledTableCell>Distance</StyledTableCell>
            <StyledTableCell>Free Cancellation Until</StyledTableCell>
            <StyledTableCell>Nightly Rate</StyledTableCell>
            <StyledTableCell>Room Type</StyledTableCell>
            <StyledTableCell>Selling Price</StyledTableCell>
            <StyledTableCell>Stars</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((hotel, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {hotel?.["Hotel Name"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>{hotel?.Address || "N/A"}</StyledTableCell>
                <StyledTableCell>
                  <SubTable amenities={hotel?.Amenities} />
                </StyledTableCell>
                <StyledTableCell>
                  {hotel?.["Boarding Type"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>{hotel?.Distance || "N/A"}</StyledTableCell>
                <StyledTableCell>
                  {hotel?.["Free Cancellation Until"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>
                  {hotel?.["Nightly Rate"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>
                  {hotel?.["Room Type"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>
                  {hotel?.["Selling Price"] || "N/A"}
                </StyledTableCell>
                <StyledTableCell>{hotel?.Stars || "N/A"}</StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={10} align="center">
                No Data Available
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SalesSupportPricingTable;
