import React, { useCallback } from "react";

const RequestTimeZone = ({
  selectOptionsTimezone,
  selectedTimeZone,
  setSelectedTimeZone,
}) => {
  const handleTimeZoneChange = useCallback(
    (event) => {
      const timeZoneValue = event.target.value;
      setSelectedTimeZone(timeZoneValue);
    },
    [setSelectedTimeZone]
  );

  return (
    <div className="main-header-info">
      <div className="request-dropDown-container">
        <label
          htmlFor="request-dropdown"
          className="label-dropdown-request linear"
        >
          <h4>TimeZone:</h4>
        </label>
        <select
          name="timeZone"
          id="request-dropdown"
          className="dropdown-request"
          value={selectedTimeZone}
          onChange={handleTimeZoneChange}
        >
          <option value="" hidden className="dropdown-request-option">
            Select TimeZone
          </option>
          {selectOptionsTimezone &&
            selectOptionsTimezone.map((optionTime) => (
              <option
                key={optionTime}
                value={optionTime}
                className="dropdown-request-option"
              >
                {optionTime}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default RequestTimeZone;
