import React, { useEffect, useState, useMemo } from "react";
import "./PricingEmailRequest.css";
import { baseUrl } from "../../api/Api";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
} from "@mui/material";
import { IoClose } from "react-icons/io5";

const PricingEmailRequest = ({ token }) => {
  const [historyEmailRequests, setHistoryEmailRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  console.log("historyEmailRequests", historyEmailRequests);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${baseUrl}/api/support/pricing-emails-history`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setHistoryEmailRequests(data);
      } catch (err) {
        setError("No history email requests found for this user");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Filtered email requests based on search input
  const filteredRequests = historyEmailRequests.filter((emailRequest) =>
    emailRequest?.showData?.Show.toLowerCase().includes(
      searchFilter.toLowerCase()
    )
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="history-pricing-team">
      <h3 style={{ color: "#0b5491", letterSpacing: "1.1px" }}>
        History Of Email Request :
      </h3>

      <div className="filter-in-pricing-email">
        <label
          htmlFor="nameFilter"
          style={{ marginRight: "8px", fontWeight: 700 }}
        >
          Show Name:
        </label>
        <input
          type="text"
          id="nameFilter"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search by Show Name"
          style={{ flex: 1, padding: "5px" }}
        />
      </div>

      <div className="email-requests-list">
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "75vh",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Lead Name",
                  "Show Name",
                  "Request Data",
                  "State",
                  "Details",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "17px",
                      fontFamily: '"Playfair Display", serif',
                      letterSpacing: 1,
                      ...(header === "Details" && { textAlign: "center" }),
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests.map((emailRequest) => (
                <TableRow key={emailRequest._id}>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "4px 10px",
                    }}
                  >
                    {emailRequest?.companyData?.Lead}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "4px 10px",
                    }}
                  >
                    {emailRequest?.showData?.Show}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "4px 10px",
                    }}
                  >
                    {emailRequest?.RequestedData}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "4px 10px",
                    }}
                  >
                    {emailRequest?.State}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "4px 10px",
                      textAlign: "center",
                    }}
                  >
                    <button
                      className="pricing-email-req-details"
                      onClick={() => handleViewDetails(emailRequest)}
                    >
                      View Details
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Material-UI Dialog for Request Details */}
        <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
          <div className="Dialog-pricing-email-contanier">
            <div className="Dialog-pricing-email-header">
              <h3>Email Request Details</h3>
              <button className="close-icon-dialog" onClick={handleCloseDialog}>
                <IoClose />
              </button>
            </div>
            <div className="Dialog-pricing-email-contain">
              <div className="Dialog-pricing-email-info">
                <div className="Dialog-pricing-email-texts">
                  <p className="pricing-email-req-card-text">
                    <span>Agent Name:</span> {selectedRequest?.agentName}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Show Name:</span>
                    {selectedRequest?.showData?.Show}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Company Name:</span>
                    {selectedRequest?.companyData?.Lead}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Email:</span> {selectedRequest?.Email}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Request Data:</span>
                    {selectedRequest?.RequestedData}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Check-in:</span>
                    {selectedRequest?.companyData?.["Check-in"] &&
                      new Date(
                        selectedRequest?.companyData["Check-in"]
                      )?.toLocaleDateString("en-US")}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Check-out:</span>
                    {selectedRequest?.companyData?.["Check-out"] &&
                      new Date(
                        selectedRequest?.companyData["Check-out"]
                      )?.toLocaleDateString("en-US")}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>No.Rooms:</span>
                    {selectedRequest?.companyData?.["/ Room Stay"]}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>Email Note:</span> {selectedRequest?.notes}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>ContactName:</span>
                    {selectedRequest?.ContactName}
                  </p>
                  <p className="pricing-email-req-card-text">
                    <span>State:</span> {selectedRequest?.State}
                  </p>
                </div>
                {/* <button
                className="pricing-email-req-details"
                // onClick={openFormDialog}
              >
                <p>Add Hotels</p>
              </button> */}
              </div>
              <div className="table-pricing-req-contanier">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Hotel Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Stars
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Selling Price
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Free Cancellation Until
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Boarding Type
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Distance
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Address
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "'Roboto', sans-serif",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Room Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRequest?.emailPricingFeedback.length > 0 ? (
                        selectedRequest?.emailPricingFeedback.map(
                          (hotel, index) => {
                            console.log("Rendering hotel:", hotel); // Log each hotel
                            return (
                              <TableRow key={index}>
                                <TableCell>{hotel?.["Hotel Name"]}</TableCell>
                                <TableCell>{hotel?.Stars}</TableCell>
                                <TableCell>{hotel?.SellingPrice}</TableCell>
                                <TableCell>
                                  {hotel?.["Free Cancellation Until"]}
                                </TableCell>
                                <TableCell>
                                  {hotel?.["Boarding Type"]}
                                </TableCell>
                                <TableCell>{hotel?.["Distance"]}</TableCell>
                                <TableCell>{hotel?.["Address"]}</TableCell>
                                <TableCell>{hotel?.["Room Type"]}</TableCell>
                              </TableRow>
                            );
                          }
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={8}
                            style={{ textAlign: "center" }}
                          >
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* <button
                className="pricing-email-req-details"
                // onClick={handleSendEmailFeedback}
                style={{marginTop:"10px"}}
              >
                <p>Send To Email Team</p>
              </button> */}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default PricingEmailRequest;
