import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import "./Agent.css";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import NewLeads from "../../components/NewLeads/NewLeads";
import HistoryLeads from "../../components/HistoryLeads/HistoryLeads";
import {
  AllTimeZoneUrl,
  GetFilteredCompanies,
  GetOldCompanies,
  baseUrl,
} from "../../api/Api";
import Table from "../../components/table/Table";
import TableOldData from "../../components/table/TableOldData";
import AnswerPopUp from "../../components/popUp/AnswerPopUp";
import OverLayPage from "../../components/OverLayPage/OverLayPage";
import LiveRequest from "../../components/RequestDropdown/LiveRequest";
import LiveRequestFeedback from "../../components/salesSupportTeam/LiveRequestFeedback";
import CallbackTable from "../../components/table/CallbackTable";
import { AuthContext } from "../../components/context/AuthContext";

const Agent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewActive, setIsNewActive] = useState(true);
  const [isHistoryActive, setIsHistoryActive] = useState(false);
  const [isCallbackActive, setIsCallbackActive] = useState(false);
  ////----------- get data  by timezone---------------------------------->
  const [selectOptionsTimezone, setSelectOptionsTimezone] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedEdition, setSelectedEdition] = useState("");
  ///get shows------------------------------------------>
  const [getShows, setGetShows] = useState([]);
  const [getOldShows, setGetOldShows] = useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////////
  const [selectedRows, setSelectedRows] = useState("");
  const [selectedRowByCompany, setSelectedRowByCompany] = useState("");
  /// popUP Display---------------------------------------------->
  const [popUpActive, setPopUpActive] = useState(false);
  const [overLayStatusActive, setOverLayStatusActive] = useState(false);
  /// overlay Display-------------------------------------------------->
  const [overlayActive, setOverlayActive] = useState(false);
  ////////////////////Closer Assigned/////////////////////////////
  const [selectCloserAssigned, setSelectCloserAssigned] = useState("");
  const [closerSelections, setCloserSelections] = useState({});
  // dataOfRow ------------------------------------->
  const [dataOfRowId, setDataOfRowId] = useState({
    showId: null,
    companyId: null,
    Show: null,
    Company: null,
    Timezone: null,
    Status: null,
    "Rejection Reason": null,
    Notes: null,
    "Contact Name": null,
    "DM Rating": null,
    Email: null,
    "Closer Assigned": null,
    "Closer Note": null,
    "Days to Call": null,
    "Last Contact": null,
    "Next Contact": null,
    "Closing Date": null,
    Hotel: null,
    Budget: null,
    " Distance": null,
    Rooms: null,
    "Next Dates": null,
    "End Date": null,
    roomDetails: [],
    Activities: null,
    "/ Room Stay": null,
    "Email Type": null,
    "Email Notes": null,
    "Check-in": "",
    "Check-out": "",
  });

  console.log("dataOfRowId", dataOfRowId);

  /////////////////// email template //////////////////
  const [emailTemplateData, setEmailTemplateData] = useState({
    showId: null,
    companyId: null,
    Show: null,
    Company: null,
  });
  const [leadDataForRequest, setLeadDataForRequest] = useState({
    showId: null,
    companyId: null,
    Show: null,
    Company: null,
  });

  /////////////////// requestBtn ////////////////////////////////////
  // const [requestSelected, setRequestSelected] = useState();
  const [requestSelected, setRequestSelected] = useState({});
  const [keyReq, setKeyReq] = useState("");
  const [valueReq, setvalueReq] = useState("");

  const [isReqPopupVisible, setIsReqPopupVisible] = useState(false);
  const [requestedData, setRequestedData] = useState("");
  const [requestesNotes, setRequestedNotes] = useState("");
  // ----------------------- History Leads Logic------------------------->

  const [companyOptions, setCompanyOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [showNameOptions, setShowNameOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [updatedAtOptions, setUpdatedAtOptions] = useState([]);
  // const [editionsOptions, setEditionsOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedUpdateTime, setSelectedUpdateTime] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedShowName, setSelectedShowName] = useState("");
  const [selectedTimeZoneFilter, setSelectedTimeZoneFilter] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // filter shows ------------------------------------->
  const [filteredShows, setFilteredShows] = useState([]);
  /////////////////////select type email/////////////////////////////////////////////
  const [typeOfEmail, setTypeOfEmail] = useState("");
  const [notesOfEmail, setNotesOfEmail] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////
  const [leadsCount, setLeadsCount] = useState("");

  // Pagination Section ------------------------------------->
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  // console.log(getShows);
  // console.log(getOldShows);

  const handleFeedbackOpenModal = (feedbackData) => {
    handleFeedbackData(feedbackData);
    setIsFeedbackModalOpen(true);
  };
  const handleFeedbackCloseModal = () => {
    setIsFeedbackModalOpen(false);
  };

  useEffect(() => {
    const firstKey = Object.keys(requestSelected)[0] || "";
    setKeyReq(firstKey);

    const currentValue = requestSelected[firstKey] || "";
    setvalueReq(currentValue);

    if (
      requestSelected[firstKey] === "Live Request" ||
      requestSelected[firstKey] === "Custom Request"
    ) {
      setIsReqPopupVisible(true);
    } else {
      setIsReqPopupVisible(false);
    }
  }, [requestSelected]);

  const fetchOldData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      const oldCompaniesResponse = await axios.get(
        `${GetOldCompanies}?page=${page}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setGetOldShows(oldCompaniesResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        // Fetch timezones
        const timezoneResponse = await axios.get(AllTimeZoneUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSelectOptionsTimezone(timezoneResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchOldData();
  }, []);

  // useEffect(() => {
  //   if (selectedTimeZone) {
  //     const fetchShows = async () => {
  //       try {
  //         const token = localStorage.getItem("token");
  //         const response = await axios.post(
  //           GetFilteredCompanies,
  //           { Timezone: selectedTimeZone, editionYear: selectedEdition },
  //           { headers: { Authorization: `Bearer ${token}` } }
  //         );
  //         setGetShows(response.data);
  //         console.log("response",response.data);

  //       } catch (error) {
  //         console.error("Error posting timezone:", error);
  //         setErrorMessage(error.response.data.message);
  //       }
  //     };
  //     fetchShows();
  //   }
  // }, [dataOfRowId,getOldShows ,selectedTimeZone,selectedEdition]);

  const fetchShows = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        GetFilteredCompanies,
        { Timezone: selectedTimeZone, editionYear: selectedEdition },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Clear previous error message if the request is successful
      setErrorMessage("");

      // Check if there is data
      if (response.data && response.data.length > 0) {
        setGetShows(response.data);
        const totalLeadsCount = response.data.reduce((acc, show) => {
          return acc + (show.Leads ? show.Leads.length : 0);
        }, 0);

        setLeadsCount(totalLeadsCount);
      } else {
        // Handle case where data is empty but no error
        setErrorMessage("No data available for the selected timezone.");
        setGetShows([]); // Clear shows if no data
      }
    } catch (error) {
      console.error("Error posting timezone:", error);
      // Set error message if there is an error response
      if (error.response && error.response.data) {
        setErrorMessage(
          error.response.data.message || "An unexpected error occurred",
        );
      } else {
        setErrorMessage("An unexpected error occurred");
      }
      setGetShows([]);
    }
  };

  // console.log(leadsCount);

  useEffect(() => {
    if (selectedTimeZone) {
      fetchShows();
    }
  }, [selectedTimeZone, selectedEdition]);

  // }, [dataOfRowId,selectedTimeZone, feedbackData, selectedEdition]);

  /////////////////////////////////////////////////////////////////////////
  const toggleSidebar = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const showNewContent = useCallback(() => {
    setIsNewActive(true);
    setIsHistoryActive(false);
    setIsCallbackActive(false);
  }, []);

  const showHistoryContent = useCallback(() => {
    setIsNewActive(false);
    setIsHistoryActive(true);
    setIsCallbackActive(false);
  }, []);

  const showCallbackContent = useCallback(() => {
    setIsNewActive(false);
    setIsHistoryActive(false);
    setIsCallbackActive(true);
  }, []);

  // -------------Open and close popup  ----------------

  const handleRequestPopupOpen = useCallback(
    (showId, companyId, showName, companyName) => {
      setSelectedRows(showId);
      setLeadDataForRequest({
        showId,
        companyId,
        Show: showName,
        Company: companyName,
      });
    },
    [],
  );

  const handleFeedbackData = (feedback) => {
    setFeedbackData(feedback);
  };

  const handlePopUpOpen = (
    showId,
    companyId,
    showName,
    companyName,
    timeZone,
    Status,
    rejectionReason,
    Notes,
    ContactName,
    dmRating,
    Email,
    closerAssigned,
    closerNote,
    daysToCall,
    lastContact,
    nextContact,
    closingDate,
    Hotel,
    Budget,
    Distance,
    Rooms,
    NextDates,
    EndDate,
    roomDetails,
    Activities,
    roomStay,
    totalStay,
    emailType,
    emailNotes,
  ) => {
    setPopUpActive(true);
    setSelectedRows(showId);
    const roomDetail = roomDetails.length > 0 ? roomDetails : [];

    const nextDate = new Date(NextDates); // Parse the NextDates into a Date object
    nextDate.setDate(nextDate.getDate() - 1); // Subtract one day for Check-in

    const endDate = new Date(EndDate); // Parse the EndDate into a Date object
    endDate.setDate(endDate.getDate() + 1); // Add one day for Check-out

    // Function to format date as 'YYYY-MM-DD'
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setDataOfRowId({
      showId,
      companyId,
      Show: showName,
      Company: companyName,
      Timezone: timeZone,
      Status,
      "Rejection Reason": rejectionReason,
      Notes,
      "Contact Name": ContactName,
      "DM Rating": dmRating,
      Email,
      "Closer Assigned": closerAssigned,
      "Closer Note": closerNote,
      "Days to Call": daysToCall,
      "Last Contact": lastContact,
      "Next Contact": nextContact,
      "Closing Date": closingDate,
      Hotel,
      Budget,
      " Distance": Distance,
      Rooms,
      "Next Dates": NextDates,
      "End Date": EndDate,
      roomDetails: roomDetail,
      Activities,
      "/ Room Stay": roomStay,
      "Total Stay": totalStay,
      "Email Type": emailType,
      "Email Notes": emailNotes,
      "Check-in": formatDate(nextDate),
      "Check-out": formatDate(endDate),
    });
    setTypeOfEmail(emailType);
    setNotesOfEmail(emailNotes);
    setLeadDataForRequest({
      showId,
      companyId,
      Show: showName,
      Company: companyName,
    });
  };

  const handleCloserChange = (leadDataId, selectedCloser) => {
    setCloserSelections((prev) => {
      const updatedSelections = { ...prev, [leadDataId]: selectedCloser };
      setDataOfRowId((prevData) => ({
        ...prevData,
        "Closer Assigned": updatedSelections[leadDataId] || "",
      }));
      return updatedSelections;
    });
  };
  const calculateNextDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
  };

  const calculateEndDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
  };
  ///////////////////// update shows  ///////////////////////////

  // console.log(getShows);

  const UpdateShow = async () => {
    try {
      const token = localStorage.getItem("token");
      const updatedData = { ...dataOfRowId };
      const payload = {
        "Max. Distance": updatedData[" Distance"],
        "/ Room Stay": updatedData["/ Room Stay"],
        Activities: updatedData.Activities,
        Budget: updatedData.Budget,
        "Closer Assigned": updatedData["Closer Assigned"],
        "Closer Note": updatedData["Closer Note"],
        "Closing Date": updatedData["Closing Date"],
        Lead: updatedData.Company,
        "DM Name": updatedData["Contact Name"],
        "DM Rating": updatedData["DM Rating"],
        "Days to Call": updatedData["Days to Call"],
        Email: updatedData.Email,
        "Email Type": updatedData["Email Type"],
        "Email Notes": updatedData["Email Notes"],
        "End Date": updatedData["End Date"],
        "Check-out": updatedData["Check-out"],
        Hotel: updatedData.Hotel,
        "Last Contact": updatedData["Last Contact"],
        "Next Contact": updatedData["Next Contact"],
        "Next Dates": updatedData["Next Dates"],
        "Check-in": updatedData["Check-in"],
        Notes: updatedData.Notes,
        roomDetails: updatedData.roomDetails,
        "Rejection Reason": updatedData["Rejection Reason"],
        Rooms: updatedData.Rooms,
        Status: updatedData.Status,
        Timezone: updatedData.Timezone,
        "Total Stay": updatedData["Total Stay"],
      };

      const res = await axios.put(
        `${baseUrl}/api/agents/shows/${dataOfRowId.showId}/companies/${dataOfRowId.companyId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchOldData();
      setGetShows((prevShows) => {
        return prevShows.map((show) => {
          if (show._id === dataOfRowId.showId) {
            const updatedLeads = show.Leads.filter(
              (lead) => lead.Lead !== updatedData.Company
            );
            setLeadsCount((prevCount) => prevCount - 1);
            return { ...show, Leads: updatedLeads };
          }
          return show;
        });
      });
      setPopUpActive(false);
    } catch (error) {
      console.error("Updating show failed:", error);
    }
  };
  const handleUpdateSubmit = () => {
    setOverLayStatusActive(true);
  };
  const handleCloseUpdateSubmit = async () => {
    setOverLayStatusActive(false);
    await UpdateShow();
  };

  const handleCloseStatusPopup = () => {
    setOverLayStatusActive(false);
  };

  ///////////////////////////////////////////////////////////////
  const handelOverlayPage = (showId, companyId, showName, companyName) => {
    setSelectedRows(showId);
    setSelectedRowByCompany(companyId);
    setOverlayActive(true);
    setEmailTemplateData({
      showId: showId,
      companyId: companyId,
      Show: showName,
      Company: companyName,
    });
  };
  ///////////////////////////////////////////////////////////////
  const handelOverlayclose = () => {
    setOverlayActive(false);
  };
  //////////////////////////////////////////////////////////////
  const handleCloseLiveReq = () => {
    setRequestSelected("");
  };
  // -------------------------------------------------------------------------------->
  // ----------------------- History Leads Logic-------------------------
  // -------------------------------------------------------------------------------->

  // useEffect(() => {
  //   const companies = [...new Set(getOldShows.map((show) => show.Leads))];
  //   setCompanyOptions(companies);

  //   const statuses = [
  //     ...new Set(
  //       getOldShows.flatMap((show) =>
  //         (show.Leads || []).map((lead) => lead.Status)
  //       )
  //     ),
  //   ];

  //   setStatusOptions(statuses);

  //   const showNames = [...new Set(getOldShows.map((show) => show.Show))];
  //   setShowNameOptions(showNames);

  //   const timeZones = [
  //     ...new Set(
  //       getOldShows.flatMap((show) =>
  //         (show.Leads || []).map((lead) => lead.Timezone)
  //       )
  //     ),
  //   ];
  //   setTimeZoneOptions(timeZones);
  // }, [getOldShows]);

  // useEffect(() => {
  //   let filtered = getOldShows;

  //   if (selectedCompany) {
  //     filtered = filtered.filter((show) =>
  //       show.Leads.some((lead) => lead.Hotel === selectedCompany)
  //     );
  //   }

  //   if (selectedStatus) {
  //     filtered = filtered.filter((show) =>
  //       show.Leads.some((lead) => lead.Status === selectedStatus)
  //     );
  //   }

  //   if (selectedShowName) {
  //     filtered = filtered.filter((show) => show.Show === selectedShowName);
  //   }

  //   if (selectedTimeZoneFilter) {
  //     filtered = filtered.filter((show) =>
  //       show.Leads.some((lead) => lead.Timezone === selectedTimeZoneFilter)
  //     );
  //   }

  //   setFilteredShows(filtered);
  // }, [
  //   selectedCompany,
  //   selectedStatus,
  //   selectedShowName,
  //   selectedTimeZoneFilter,
  //   getOldShows,
  // ]);

  // const handleChangeFilter = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "companies") {
  //     setSelectedCompany(value);
  //   } else if (name === "status") {
  //     setSelectedStatus(value);
  //   } else if (name === "showName") {
  //     setSelectedShowName(value);
  //   } else if (name === "timeZone") {
  //     setSelectedTimeZoneFilter(value);
  //   }
  // };

  // const dataToDisplay = filteredShows;

  useEffect(() => {
    const companies = [
      ...new Set(
        getOldShows.flatMap((show) =>
          (show.Leads || []).map((lead) => lead.Lead)
        )
      ),
    ];
    setCompanyOptions(companies);

    const statuses = [
      ...new Set(
        getOldShows.flatMap((show) =>
          (show.Leads || []).map((lead) => lead.Status)
        )
      ),
    ];

    setStatusOptions(statuses);

    const showNames = [...new Set(getOldShows.map((show) => show.Show))];
    setShowNameOptions(showNames);

    const timeZones = [
      ...new Set(
        getOldShows.flatMap((show) =>
          (show.Leads || []).map((lead) => lead.Timezone)
        )
      ),
    ];
    setTimeZoneOptions(timeZones);

    const updatedAt = [
      ...new Set(
        getOldShows.flatMap((show) =>
          (show.Leads || []).map((lead) => lead.updatedAt)
        )
      ),
    ];

    // Group by date and add counts for leads and prospect leads
    const groupedByDate = updatedAt.reduce((acc, dateString) => {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      // Initialize if the date doesn't exist in the accumulator
      if (!acc[formattedDate]) {
        acc[formattedDate] = { totalCount: 0, prospectCount: 0 };
      }

      // Iterate over all shows and leads to count total and prospect leads for each date
      getOldShows.forEach((show) => {
        show.Leads?.forEach((lead) => {
          // Check if the lead's updatedAt matches the current date
          if (lead.updatedAt === dateString) {
            acc[formattedDate].totalCount += 1;

            // Check if the lead's status is "Prospect"
            if (lead.Status === "Prospect") {
              acc[formattedDate].prospectCount += 1;
            }
          }
        });
      });

      return acc;
    }, {});

    // Convert the grouped data into the format required for the dropdown
    const formattedUpdatedAtOptions = Object.keys(groupedByDate).map(
      (date) => ({
        value: date,
        label: `${date} - ${groupedByDate[date].totalCount} leads - ${groupedByDate[date].prospectCount} prospect`,
      })
    );

    setUpdatedAtOptions(formattedUpdatedAtOptions);
  }, [filteredShows]);

  useEffect(() => {
    let filtered = getOldShows;

    // Filter by show name
    if (selectedShowName) {
      filtered = filtered.filter((show) => show.Show === selectedShowName);
    }

    if (selectedCompany) {
      filtered = filtered.map((show) => ({
        ...show,
        Leads: show.Leads.filter((lead) => lead.Lead.includes(selectedCompany)),
      }));
    }

    if (selectedUpdateTime) {
      console.log(selectedUpdateTime);

      // Convert selectedUpdateTime (e.g., "11/12/2024") into a date object
      const [day, month, year] = selectedUpdateTime.split("/");
      const formattedSelectedDate = new Date(
        `${year}-${month}-${day}`
      ).toLocaleDateString("en-GB");

      filtered = filtered.map((show) => ({
        ...show,
        Leads: show.Leads.filter((lead) => {
          // Get only the date part of lead.updatedAt and compare
          const leadDate = new Date(lead.updatedAt).toLocaleDateString("en-GB");

          return leadDate === formattedSelectedDate;
        }),
      }));
    }

    if (selectedTimeZoneFilter) {
      filtered = filtered.map((show) => ({
        ...show,
        Leads: show.Leads.filter(
          (lead) => lead.Timezone === selectedTimeZoneFilter,
        ),
      }));
    }

    if (selectedStatus) {
      filtered = filtered.map((show) => ({
        ...show,
        Leads: show.Leads.filter((lead) => lead.Status === selectedStatus),
      }));
    }

    setFilteredShows(filtered);
  }, [
    selectedTimeZoneFilter,
    selectedShowName,
    selectedStatus,
    selectedCompany,
    selectedUpdateTime,
    getOldShows,
  ]);

  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    if (name === "companies") {
      setSelectedCompany(value);
    } else if (name === "status") {
      setSelectedStatus(value);
    } else if (name === "showName") {
      setSelectedShowName(value);
    } else if (name === "timeZone") {
      setSelectedTimeZoneFilter(value);
    } else if (name === "Update Time") {
      setSelectedUpdateTime(value);
    }
  };
  console.log(selectedUpdateTime);

  const dataToDisplay = filteredShows;

  // Pagination Section
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const paginatedShows = dataToDisplay.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    socket.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "feedback") {
        setFeedbackData(message.data.feedback);
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const dateDiff = (date, cDate) =>
    Math.ceil(Math.abs(date - cDate) / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const nextContact = dataOfRowId["Next Contact"];
    const nextContactDate = nextContact ? new Date(nextContact) : null;
    const currentDate = new Date();

    const daysToCall =
      nextContactDate && !isNaN(nextContactDate.getTime())
        ? dateDiff(nextContactDate, currentDate)
        : 0;

    setDataOfRowId((prevState) => ({
      ...prevState,
      "Days to Call": daysToCall,
    }));

    // if (!isNaN(nextContactDate.getTime())) {
    //   const differenceInDays = dateDiff(nextContactDate, currentDate);

    //   setDataOfRowId((prevState) => ({
    //     ...prevState,
    //     "Days to Call": differenceInDays,
    //   }));
    // }
  }, [dataOfRowId["Next Contact"]]);

  return (
    <div className="agent-container">
      <div className="agent-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showNewContent} className="sidebar-btn">
                New
              </button>
              <button onClick={showHistoryContent} className="sidebar-btn">
                History
              </button>
              <button onClick={showCallbackContent} className="sidebar-btn">
                Callback
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {/* ///////////////////////////////////////////////////// */}
        <div className="main-agent-container">
          <div className="main-agent-contain">
            <div className="main-header-info">
              <h2 className="main-header-h2">Agent View</h2>
            </div>
            <div className="main-header-container">
              {isNewActive && (
                <NewLeads
                  selectOptionsTimezone={selectOptionsTimezone}
                  selectedTimeZone={selectedTimeZone}
                  setSelectedTimeZone={setSelectedTimeZone}
                  selectedEdition={selectedEdition}
                  setSelectedEdition={setSelectedEdition}
                  getShows={getShows}
                  fetchShows={fetchShows}
                  leadsCount={leadsCount}
                />
              )}
              {isHistoryActive && (
                <HistoryLeads
                  getOldShows={getOldShows}
                  handleChangeFilter={handleChangeFilter}
                  companyOptions={companyOptions}
                  selectedCompany={selectedCompany}
                  statusOptions={statusOptions}
                  selectedStatus={selectedStatus}
                  selectedTime={selectedUpdateTime}
                  showNameOptions={showNameOptions}
                  selectedShowName={selectedShowName}
                  timeZoneOptions={timeZoneOptions}
                  updatedAtOptions={updatedAtOptions}
                  selectedTimeZoneFilter={selectedTimeZoneFilter}
                />
              )}
            </div>
            {isNewActive && (
              <Table
                data={getShows}
                handelPopUpOpen={handlePopUpOpen}
                handelOverlayPage={handelOverlayPage}
                requestSelected={requestSelected}
                setRequestSelected={setRequestSelected}
                handleRequestPopupOpen={handleRequestPopupOpen}
                handleFeedbackData={handleFeedbackData}
                feedbackData={feedbackData}
                handleFeedbackOpenModal={handleFeedbackOpenModal}
                selectCloserAssigned={selectCloserAssigned}
                setSelectCloserAssigned={setSelectCloserAssigned}
                closerSelections={closerSelections}
                handleCloserChange={handleCloserChange}
                errorMessage={errorMessage}
              />
            )}
            {isHistoryActive && (
              <TableOldData
                data={dataToDisplay}
                // data={paginatedShows}
                handelPopUpOpen={handlePopUpOpen}
                handelOverlayPage={handelOverlayPage}
                requestSelected={requestSelected}
                setRequestSelected={setRequestSelected}
                handleFeedbackOpenModal={handleFeedbackOpenModal}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                dataToDisplay={dataToDisplay}
              />
            )}
            {isCallbackActive && (
              <CallbackTable
                data={dataToDisplay}
                // data={paginatedShows}
                handelPopUpOpen={handlePopUpOpen}
                handelOverlayPage={handelOverlayPage}
                requestSelected={requestSelected}
                setRequestSelected={setRequestSelected}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}
            {/* <Table
              data={isNewActive ? getShows : getOldShows}
              handelPopUpOpen={handlePopUpOpen}
              handelOverlayPage={handelOverlayPage}
              requestSelected={requestSelected}
              setRequestSelected={setRequestSelected}
            /> */}
          </div>
        </div>
      </div>
      {popUpActive && (
        <AnswerPopUp
          dataOfRowId={dataOfRowId}
          data={dataToDisplay}
          handleUpdateSubmit={handleUpdateSubmit}
          overLayStatusActive={overLayStatusActive}
          handleCloseStatusPopup={handleCloseStatusPopup}
          handleCloseUpdateSubmit={handleCloseUpdateSubmit}
          setDataOfRowId={setDataOfRowId}
          getShows={getShows}
          isNewActive={isNewActive}
          selectedRows={selectedRows}
          handleRequestPopupOpen={handleRequestPopupOpen}
          requestSelected={valueReq}
          setRequestSelected={setRequestSelected}
          requestedData={requestedData}
          setRequestedData={setRequestedData}
          requestedNotes={requestesNotes}
          setRequestedNotes={setRequestedNotes}
          leadDataForRequest={leadDataForRequest}
          isHistoryActive={isHistoryActive}
        />
      )}
      {overlayActive && (
        <OverLayPage
          handelOverlayclose={handelOverlayclose}
          emailTemplateData={emailTemplateData}
          getShows={getShows}
          selectedRowByCompany={selectedRowByCompany}
          selectedRows={selectedRows}
        />
      )}
      {isReqPopupVisible && (
        <LiveRequest
          handleCloseLiveReq={handleCloseLiveReq}
          leadDataForRequest={leadDataForRequest}
          requestSelected={valueReq}
          typeOfEmail={typeOfEmail}
          notesOfEmail={notesOfEmail}
          requestedData={requestedData}
          setRequestedData={setRequestedData}
        />
      )}

      <LiveRequestFeedback
        isFeedbackModalOpen={isFeedbackModalOpen}
        handleFeedbackCloseModal={handleFeedbackCloseModal}
        feedbackData={feedbackData}
      />
    </div>
  );
};

export default Agent;
