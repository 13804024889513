import React, { useState } from "react";
import axios from "axios";
import UploadShows from "./UploadShows";
import styles from "./UploadControllersSection.module.css";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { baseUrl } from "../../api/Api";

const UploadLocatorsSection = ({ showId }) => {
  const [selectedShowsFile, setSelectedShowsFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleShowsFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedShowsFile(file);
  };

  const handleShowsUpload = async () => {
    if (selectedShowsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedShowsFile);
      setLoading(true);
      setProgress(0);
      try {
        const response = await axios.post(
          `${baseUrl}/api/locator/upload-raw-lead/${showId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted);
            },
          }
        );
        console.log(response.data);
        setSelectedShowsFile(null);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No file selected for shows");
    }
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded </p>

      <div className={styles.controllersContainer}>
        <UploadShows
          onFileChange={handleShowsFileChange}
          onUpload={handleShowsUpload}
          isLoading={loading}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
    </div>
  );
};

export default UploadLocatorsSection;
