import React, { useState, useEffect, useContext } from "react";
import "./ExistingLeads.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

const ExistingLeads = () => {
  const [existingLeads, setExistingLeads] = useState([]);
  const [closers, setClosers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);

  // Fetch closers from the API
  useEffect(() => {
    const fetchClosers = async () => {
      try {
        const response = await fetch(
          "http://localhost:4800/api/sales-manager/get-closers",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch closers");
        }

        const data = await response.json();
        setClosers(data); // Set closers from the response
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClosers();
  }, [token]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch(
          "http://localhost:4800/api/sales-manager/get-existing-leads",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch leads");
        }

        const data = await response.json();
        setExistingLeads(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, [token]);

  const handleCloserChange = (leadId, newCloser) => {
    const updatedLeads = existingLeads.map((lead) =>
      lead._id === leadId
        ? { ...lead, "Closer Assigned": { closerName: newCloser } }
        : lead
    );
    setExistingLeads(updatedLeads);
  };

  // Handle the "Done" click - update the closer for the lead
  const handleDoneClick = async (leadId, closerName, closerId, showId) => {
    try {
      const response = await fetch(
        "http://localhost:4800/api/sales-manager/update-closer",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            leadId,
            showId,
            newCloserId: closerId,
            newCloserName: closerName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update closer");
      }

      const data = await response.json();
      console.log("Closer updated successfully:", data);
    } catch (err) {
      console.error("Error updating closer:", err);
      setError(err.message);
    }
  };

  if (loading) {
    return <div>Loading leads...</div>;
  }

  return (
    <div className="existing-leads-contain">
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "75vh",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
              }}
            >
              {[
                "Lead Name",
                "Show Name",
                "Timezone",
                "CityState",
                "Status",
                "Assign closer",
                "Done",
              ].map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "17px",
                    fontFamily: '"Playfair Display", serif',
                    letterSpacing: 1,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {existingLeads.map((lead, index) => (
              <TableRow key={lead._id}>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {lead?.Lead}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {lead?.Show}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {lead?.Timezone}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {lead?.cityState}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {lead?.Status}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id={`closer-select-${lead._id}`}>
                      Closer
                    </InputLabel>
                    <Select
                      labelId={`closer-select-${lead._id}`}
                      value={lead?.["Closer Assigned"]?.closerName || ""}
                      label="Closer"
                      onChange={(e) =>
                        handleCloserChange(lead._id, e.target.value)
                      }
                    >
                      {closers.map((closer) => (
                        <MenuItem key={closer._id} value={closer.name}>
                          {closer.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                    textAlign: "center",
                  }}
                >
                  <IoCheckmarkDoneCircle
                    style={{
                      fontSize: "26px",
                      color: lead?.["Closer Assigned"]?.closerId
                        ? "green"
                        : "gray",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const selectedCloser =
                        lead["Closer Assigned"]?.closerName;
                      const closerDetails = closers.find(
                        (c) => c.name === selectedCloser
                      );

                      if (closerDetails) {
                        handleDoneClick(
                          lead._id,
                          selectedCloser,
                          closerDetails._id,
                          lead.showId
                        );
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ExistingLeads;
