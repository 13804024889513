import React from "react";

const statusOptions = [
  { label: "New", value: "New" },
  { label: "VM", value: "VM" },
  { label: "Callback", value: "Callback" },
  { label: "GK Blocked", value: "GK Blocked" },
  { label: "DM Rejected", value: "DM Rejected" },
  { label: "Do Not Call", value: "Do Not Call" },
  { label: "Email Only", value: "Email Only" },
  { label: "Email Follow-up", value: "Email Follow-up" },
  { label: "Waiting Approval", value: "Waiting Approval" },
  { label: "Prospect", value: "Prospect" },
  { label: "Flagged", value: "Flagged" },
  { label: "N/A", value: "N/A" },
  { label: "Closed Won", value: "Closed Won" },
  { label: "Closed Lost", value: "Closed Lost" },
];

const TeamLeaderOverlay = ({
  handleCloseUpdateSubmit,
  dataOfRowId,
  setDataOfRowId,
}) => {
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setDataOfRowId((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataOfRowId((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log(dataOfRowId);
  
  return (
    <div className="overlayStatus-container">
      <div className="overlay-status-contain">
        <div className="overlay-status-control">
          <label htmlFor="Closer Status">Status:</label>
          <select
            name="closerStatus" 
            id="closerStatus"
            className="overlay-input"
            value={dataOfRowId["closerStatus"] || ""}  
            onChange={handleSelectChange}
          >
            <option value="" hidden>
              Select a Status ...
            </option>
            {statusOptions.map((optionItem) => (
              <option key={optionItem.value} value={optionItem.value}>
                {optionItem.label}
              </option>
            ))}
          </select>
        </div>
        <div className="overlay-status-control overlay-closer">
          <label htmlFor="Closer Note">Closer Note:</label>
          <input
            type="text"
            name="Closer Note" 
            id="Closer Note"
            className="tab-input"
            value={dataOfRowId["Closer Note"] || ""}
            onChange={handleInputChange}
          />
        </div>
        <button
          className="overlay-status-btn"
          type="submit"
          onClick={handleCloseUpdateSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default TeamLeaderOverlay;
