import React, { useEffect, useState } from "react";
import "./NewPricingEmailReq.css";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../api/Api";
import axios from "axios";

const NewPricingEmailReq = ({ token }) => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [hotelsArr, setHotelsArr] = useState([]);
  const [hotelInfo, setHotelInfo] = useState({
    "Hotel Name": "",
    Stars: "",
    SellingPrice: "",
    "Free Cancellation Until": "",
    "Boarding Type": "",
    Distance: "",
    Address: "",
    "Room Type": "",
  });
  console.log("selectedRequest", selectedRequest);
  console.log("hotelsArr", hotelsArr);
  console.log("hotelInfo", hotelInfo);

  const fetchUSEmailRequests = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/support/email-requests-us`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch email requests");
      }

      const data = await response.json();
      setEmailRequests(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUKEmailRequests = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/support/email-requests-uk`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch email requests");
      }

      const data = await response.json();
      setEmailRequests(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUSRequestsButtonClick = () => {
    fetchUSEmailRequests();
  };

  const handleUKRequestsButtonClick = () => {
    fetchUKEmailRequests();
  };

  console.log("emailRequests", emailRequests);

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const openFormDialog = () => {
    setOpenDialogForm(true);
  };

  // Add hotel to array and reset form
  const addHotelInArray = (e) => {
    e.preventDefault();

    if (
      !hotelInfo["Hotel Name"] ||
      !hotelInfo.Stars ||
      !hotelInfo.SellingPrice ||
      !hotelInfo["Free Cancellation Until"] ||
      !hotelInfo["Boarding Type"] ||
      !hotelInfo.Distance ||
      !hotelInfo.Address ||
      !hotelInfo["Room Type"]
    ) {
      alert("All fields are required.");
      return;
    }

    setHotelsArr((prevHotels) => [...prevHotels, hotelInfo]);

    console.log("Adding hotel:", hotelInfo);

    // Reset hotel form fields
    setHotelInfo({
      "Hotel Name": "",
      Stars: "",
      SellingPrice: "",
      "Free Cancellation Until": "",
      "Boarding Type": "",
      Distance: "",
      Address: "",
      "Room Type": "",
    });

    // Close form dialog
    setOpenDialogForm(false);
  };

  // Handle input changes and update hotelInfo state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHotelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseFormDialog = () => {
    setOpenDialogForm(false);
  };

  const sendEmailFeedback = async (payload) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/support/send-email-feedback`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Log the successful response for debugging
      console.log("Email feedback sent successfully:", response.data);
      alert("Email feedback sent successfully!");
    } catch (error) {
      console.error("Error sending email feedback:", error);
      alert("An error occurred while sending the feedback. Please try again.");
    }
  };

  const handleSendEmailFeedback = () => {
    const payload = {
      leadId: selectedRequest.CompanyId,
      showId: selectedRequest.ShowId,
      requestId: selectedRequest._id,
      emailPricingFeedback: hotelsArr,
    };
    sendEmailFeedback(payload);
    setOpenDialog(false);
    setHotelsArr([]);
  };

  ////////////////////////////////////////////////////////////////

  // Loading and error states
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="pricing-email-req-contanier">
      <div className="requests-buttons">
        <button onClick={handleUSRequestsButtonClick}>US Email Requests</button>
        <button onClick={handleUKRequestsButtonClick}>UK Email Requests</button>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "65vh",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
              }}
            >
              {[
                "Lead Name",
                "Show Name",
                "Request Data",
                "State",
                "Details",
              ].map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "17px",
                    fontFamily: '"Playfair Display", serif',
                    letterSpacing: 1,
                    ...(header === "Details" && { textAlign: "center" }),
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {emailRequests.map((emailRequest) => (
              <TableRow key={emailRequest._id}>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {emailRequest?.companyData?.Lead}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {emailRequest?.showData?.Show}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {emailRequest?.RequestedData}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                  }}
                >
                  {emailRequest?.State}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "4px 10px",
                    textAlign: "center",
                  }}
                >
                  <button
                    className="pricing-email-req-details"
                    onClick={() => handleViewDetails(emailRequest)}
                  >
                    View Details
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Material-UI Dialog for Request Details */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
        <div className="Dialog-pricing-email-contanier">
          <div className="Dialog-pricing-email-header">
            <h3>Email Request Details</h3>
            <button className="close-icon-dialog" onClick={handleCloseDialog}>
              <IoClose />
            </button>
          </div>
          <div className="Dialog-pricing-email-contain">
            <div className="Dialog-pricing-email-info">
              <div className="Dialog-pricing-email-texts">
                <p className="pricing-email-req-card-text">
                  <span>Agent Name:</span> {selectedRequest?.agentName}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Show Name:</span>
                  {selectedRequest?.showData?.Show}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Company Name:</span>
                  {selectedRequest?.companyData?.Lead}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Email:</span> {selectedRequest?.Email}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Request Data:</span>
                  {selectedRequest?.RequestedData}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Check-in:</span>
                  {selectedRequest?.companyData["Check-in"] &&
                    new Date(
                      selectedRequest?.companyData["Check-in"]
                    ).toLocaleDateString("en-US")}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Check-out:</span>
                  {selectedRequest?.companyData["Check-out"] &&
                    new Date(
                      selectedRequest?.companyData["Check-out"]
                    ).toLocaleDateString("en-US")}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>No.Rooms:</span>
                  {selectedRequest?.companyData?.Rooms}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Email Note:</span> {selectedRequest?.notes}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>ContactName:</span>
                  {selectedRequest?.ContactName}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>State:</span> {selectedRequest?.State}
                </p>
              </div>
              <button
                className="pricing-email-req-details"
                onClick={openFormDialog}
              >
                <p>Add Hotels</p>
              </button>
            </div>
            <div className="table-pricing-req-contanier">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Hotel Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Stars
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Selling Price
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Free Cancellation Until
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Boarding Type
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Distance
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Room Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelsArr?.length > 0 ? (
                      hotelsArr?.map((hotel, index) => {
                        console.log("Rendering hotel:", hotel); // Log each hotel
                        return (
                          <TableRow key={index}>
                            <TableCell>{hotel?.["Hotel Name"]}</TableCell>
                            <TableCell>{hotel?.Stars}</TableCell>
                            <TableCell>{hotel?.SellingPrice}</TableCell>
                            <TableCell>
                              {hotel?.["Free Cancellation Until"]}
                            </TableCell>
                            <TableCell>{hotel?.["Boarding Type"]}</TableCell>
                            <TableCell>{hotel?.["Distance"]}</TableCell>
                            <TableCell>{hotel?.["Address"]}</TableCell>
                            <TableCell>{hotel?.["Room Type"]}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} style={{ textAlign: "center" }}>
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <button
                className="pricing-email-req-details"
                onClick={handleSendEmailFeedback}
                style={{ marginTop: "10px" }}
              >
                <p>Send To Email Team</p>
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Material-UI Dialog for Request Details */}
      <Dialog open={openDialogForm}>
        <div
          style={{
            padding: "25px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <form
            onSubmit={addHotelInArray}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Hotel Name:
              </label>
              <input
                type="text"
                name="Hotel Name"
                value={hotelInfo["Hotel Name"]}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Stars:
              </label>
              <input
                type="number"
                name="Stars"
                value={hotelInfo.Stars}
                min="1"
                max="5"
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Selling Price:
              </label>
              <input
                type="text"
                name="SellingPrice"
                value={hotelInfo.SellingPrice}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Free Cancellation Until:
              </label>
              <input
                type="date"
                name="Free Cancellation Until"
                value={hotelInfo["Free Cancellation Until"]}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Boarding Type:
              </label>
              <input
                type="text"
                name="Boarding Type"
                value={hotelInfo["Boarding Type"]}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Distance:
              </label>
              <input
                type="text"
                name="Distance"
                value={hotelInfo.Distance}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Address:
              </label>
              <input
                type="text"
                name="Address"
                value={hotelInfo.Address}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Room Type:
              </label>
              <input
                type="text"
                name="Room Type"
                value={hotelInfo["Room Type"]}
                onChange={handleChange}
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "14px",
              }}
            >
              <button
                type="submit"
                style={{
                  padding: "10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                  marginRight: "10px",
                }}
                onClick={addHotelInArray}
              >
                Add Hotel
              </button>
              <button
                type="button"
                onClick={handleCloseFormDialog}
                style={{
                  padding: "10px",
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default NewPricingEmailReq;
