import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, Button, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Logo from "../../assets/images/Logo.png"

const MainNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {logout} = useContext(AuthContext)

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar position="static" className="update-nav">
      <Container>
        <Toolbar className="tolbar-contain">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <h4 style={{textTransform:"uppercase"}}>SkyLine Housing</h4>
            {/* <img src={Logo} alt="logo" className="logo-contain" style={{ width: '240px', height:"50px", marginTop:"12px" ,borderRadius:"10px",boxShadow: '6px 4px 6px rgba(255, 255, 255, 0.5)'}}/> */}
          </Typography>
          {location.pathname !== "/" && (
            <Button color="inherit" onClick={handleLogout} style={{width: '160px', padding:"8px", borderRadius:"8px"}}>
              Logout
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainNavBar;
