import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import SalesSupportPricingTable from "../salesSupportTeam/SalesSupportPricingTable";
import CustomFeedback from "./CustomFeedback";

const LiveRequest = ({
  handleCloseLiveReq,
  leadDataForRequest,
  requestSelected,
  typeOfEmail,
  notesOfEmail,
  requestedData,
  setRequestedData,
  
}) => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [isCustomRequest, setIsCustomRequest] = useState(false);
  const [text, setText] = useState("");

  // console.log("requestSelected",requestSelected);
  // console.log("leadDataForRequest",leadDataForRequest);
  console.log("typeOfEmail:",typeOfEmail);


  const { token } = useContext(AuthContext);

  useEffect(() => {
    const socket = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_URL 
    );

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log(message);
      console.log(feedbackData);

      if (message.type === "feedback") {
        const newFeedback = message.data.feedback;
        if (typeof message.data.feedback === "string") {
          setText(message.data.feedback);
          setIsCustomRequest(true);
        } else {
          setFeedbackData((prevFeedback) => {
            // Create a new array including the new feedback and existing ones
            const updatedFeedback = [...prevFeedback, newFeedback];

            // Use a Set to filter out duplicates based on _id
            const uniqueFeedback = Array.from(
              new Map(updatedFeedback.map((item) => [item._id, item])).values()
            );

            return uniqueFeedback;
          });
        }
        // Update state while ensuring uniqueness based on _id
      }
    };

    return () => {
      socket.close();
    };
  }, []);
  console.log(feedbackData);
  console.log(text);
  

  const handleSendRequest = async () => {
    console.log(leadDataForRequest);

    try {
      const response = await axios.post(
        `${baseUrl}/api/agents/send-request`,
        {
          showId: leadDataForRequest.showId,
          companyId: leadDataForRequest.companyId,
          request: requestSelected,
          requestedData,
          
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleCloseLiveReq()
      console.log("response",response);
      
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  const handleCloseRequestPopup = () => {
    handleCloseLiveReq();
    setText("");
    setIsCustomRequest(false);
  };
  return (
    <div className="live-request-container">
      <div className="live-request-details">
        <h1 className="linear">{requestSelected} Form</h1>
        <div className="live-request-input-contain">
          <input
            type="text"
            name=""
            id=""
            className="input-fullwidth"
            placeholder="Requested Data"
            onChange={(e) => setRequestedData(e.target.value)}
            value={requestedData}
          />
        </div>
        <div className="live-request-buttons">
          <button className="send-req-btn" onClick={handleSendRequest}>
            Send Request
          </button>
          <button onClick={handleCloseRequestPopup}>Close</button>
          {/* <button>Show Feedback</button> */}
        </div>
        {feedbackData.length > 0 && (
          <div style={{ marginTop: "2rem" }}>
            {feedbackData.map((feedback, index) => (
              <div key={index} style={{ marginBottom: "1rem" }}>
                <SalesSupportPricingTable data={feedback} />
              </div>
            ))}
          </div>
        )}
        {isCustomRequest && (
          <div>
            <CustomFeedback data={text} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveRequest;







// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import { AuthContext } from "../context/AuthContext";
// import { baseUrl } from "../../api/Api";
// import SalesSupportPricingTable from "../salesSupportTeam/SalesSupportPricingTable";
// import CustomFeedback from "./CustomFeedback";

// const LiveRequest = ({
//   handleCloseLiveReq,
//   leadDataForRequest,
//   requestSelected,
//   typeOfEmail
// }) => {
//   const [requestedData, setRequestedData] = useState("");
//   const [feedbackData, setFeedbackData] = useState([]);
//   const [isCustomRequest, setIsCustomRequest] = useState(false);
//   const [text, setText] = useState("");

//   console.log("requestSelected",requestSelected);
//   console.log("leadDataForRequest",leadDataForRequest);
//   console.log("typeOfEmail:",typeOfEmail);


//   const { token } = useContext(AuthContext);

//   useEffect(() => {
//     const socket = new WebSocket(
//       process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:4800"
//     );

//     socket.onmessage = (event) => {
//       const message = JSON.parse(event.data);
//       console.log(message);
//       console.log(feedbackData);

//       if (message.type === "feedback") {
//         const newFeedback = message.data.feedback;
//         if (typeof message.data.feedback === "string") {
//           setText(message.data.feedback);
//           setIsCustomRequest(true);
//         } else {
//           setFeedbackData((prevFeedback) => {
//             // Create a new array including the new feedback and existing ones
//             const updatedFeedback = [...prevFeedback, newFeedback];

//             // Use a Set to filter out duplicates based on _id
//             const uniqueFeedback = Array.from(
//               new Map(updatedFeedback.map((item) => [item._id, item])).values()
//             );

//             return uniqueFeedback;
//           });
//         }
//         // Update state while ensuring uniqueness based on _id
//       }
//     };

//     return () => {
//       socket.close();
//     };
//   }, []);
//   console.log(feedbackData);
//   console.log(text);
  

//   const handleSendRequest = async () => {
//     console.log(leadDataForRequest);

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/agents/send-request`,
//         {
//           showId: leadDataForRequest.showId,
//           companyId: leadDataForRequest.companyId,
//           request: requestSelected,
//           requestedData,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Error sending request:", error);
//     }
//   };
//   const handleCloseRequestPopup = () => {
//     handleCloseLiveReq();
//     setText("");
//     setIsCustomRequest(false);
//   };
//   return (
//     <div className="live-request-container">
//       <div className="live-request-details">
//         <h1 className="linear">{requestSelected} Form</h1>
//         <div className="live-request-input-contain">
//           <input
//             type="text"
//             name=""
//             id=""
//             className="input-fullwidth"
//             placeholder="Requested Data"
//             onChange={(e) => setRequestedData(e.target.value)}
//             value={requestedData}
//           />
//         </div>
//         <div className="live-request-buttons">
//           <button className="send-req-btn" onClick={handleSendRequest}>
//             Send Request
//           </button>
//           <button onClick={handleCloseRequestPopup}>Close</button>
//           {/* <button>Show Feedback</button> */}
//         </div>
//         {feedbackData.length > 0 && (
//           <div style={{ marginTop: "2rem" }}>
//             {feedbackData.map((feedback, index) => (
//               <div key={index} style={{ marginBottom: "1rem" }}>
//                 <SalesSupportPricingTable data={feedback} />
//               </div>
//             ))}
//           </div>
//         )}
//         {isCustomRequest && (
//           <div>
//             <CustomFeedback data={text} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default LiveRequest;
