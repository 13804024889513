import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const optionsReason = [
  { label: "Competition Rate", value: "Competition Rate" },
  { label: "Official Housing Only", value: "Official Housing Only" },
  { label: "Recently Booked", value: "Recently Booked" },
  { label: "Early Booked", value: "Early Booked" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "All Set", value: "All Set" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Local", value: "Local" },
  { label: "Homeowner", value: "Homeowner" },
  { label: "Free Rooms", value: "Free Rooms" },
  { label: "Next Show", value: "Next Show" },
  { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
  { label: "Escalate", value: "Escalate" },
  { label: "Try Again", value: "Try Again" },
];
const optionsEmailType = [
  { label: "Not yet", value: "Not yet" },
  {
    label: "Pricing (1 Day Before, Last Day)",
    value: "Pricing (1 Day Before, Last Day)",
  },
  {
    label: "Pricing (2 Day Before, Last Day)",
    value: "Pricing (2 Day Before, Last Day)",
  },
  {
    label: "Pricing (3 Day Before, Last Day)",
    value: "Pricing (3 Day Before, Last Day)",
  },
  {
    label: "Pricing (Same Day, Last Day)",
    value: "Pricing (Same Day, Last Day)",
  },
  {
    label: "Pricing (1 Day Before, Day After)",
    value: "Pricing (1 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, Day After)",
    value: "Pricing (2 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, 2 Day After)",
    value: "Pricing (2 Day Before, 2 Day After)",
  },
  {
    label: "Contact Card for Future Reference",
    value: "Contact Card for Future Reference",
  },
  { label: "Mixed", value: "Mixed" },
];
const optionsPreferences = [
  { label: "King Bed", value: "King Bed" },
  { label: "2 Queen Beds", value: "2 Queen Beds" },
  { label: "King & Queens", value: "King & Queens" },
  { label: "Triple", value: "Triple" },
  { label: "1 Bed Suite", value: "1 Bed Suite" },
  { label: "2 Bed Suite", value: "2 Bed Suite" },
  { label: "1-Bedroom Apt/Suite", value: "1-Bedroom Apt/Suite" },
  { label: "2-Bedroom Apt/Suite", value: "2-Bedroom Apt/Suite" },
  { label: "3-Bedroom Apt/Suite", value: "3-Bedroom Apt/Suite" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Villa", value: "Villa" },
];
const optionsAmenities = [
  { label: "Room Only", value: "Room Only" },
  { label: "Breakfast", value: "Breakfast" },
  { label: "HB", value: "HB" },
  { label: "FB", value: "FB" },
  { label: "AI", value: "AI" },
  { label: "Kitchenette", value: "Kitchenette" },
  { label: "Balcony", value: "Balcony" },
  { label: "Others", value: "Others" },
];
const optionsActivities = [
  { label: "Transfers", value: "Transfers" },
  { label: "Car Rental", value: "Car Rental" },
  { label: "Air Ticket", value: "Air Ticket" },
  { label: "Platform Access", value: "Platform Access" },
];

const TeamLeaderTabs = ({ dataOfRowId, setDataOfRowId }) => {
  const [value, setValue] = useState("1");
  const [emailError, setEmailError] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setDataOfRowId((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "Email") {
      // Validate email
      if (value === "" || value.includes("@")) {
        setEmailError("");
      } else {
        setEmailError(
          "Please enter a valid email address & E-mail must include @."
        );
        return;
      }
    }
  };

  return (
    <div className="TeamLeader-Tabs-contanier">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value} className="tab-context">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="tab-box"
          >
            <TabList
              onChange={handleChange}
              aria-label="tabs example"
              className="tab-list"
            >
              <Tab label="Tab 1" value="1" className="tab-info" />
              <Tab label="Tab 2" value="2" className="tab-info" />
              <Tab label="Tab 3" value="3" className="tab-info" />
              <Tab label="Tab 4" value="4" className="tab-info" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="rejection-reason">Rejection Reason:</label>
                <select
                  name="Rejection Reason"
                  id="rejection-reason"
                  className="tab-input input-dropdown"
                  value={dataOfRowId["Rejection Reason"] || ""}
                  onChange={handleSelectChange}
                >
                  <option value="" hidden>
                    Select a reason...
                  </option>
                  {optionsReason.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="notes">Notes : </label>
                <input
                  type="text"
                  name="Notes"
                  id="notes"
                  className="tab-input"
                  value={dataOfRowId.Notes}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="contact-name">Contact Name : </label>
                <input
                  type="text"
                  name="Contact Name"
                  id="contact-name"
                  className="tab-input"
                  value={dataOfRowId["Contact Name"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="dm-rating">DM Rating : </label>
                <input
                  type="number"
                  name="DM Rating"
                  id="dm-rating"
                  className="tab-input"
                  value={dataOfRowId["DM Rating"]}
                  onChange={handleSelectChange}
                  min="0"
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  name="Email"
                  id="email"
                  className="tab-input"
                  value={dataOfRowId.Email}
                  onChange={handleSelectChange}
                />
                {emailError && <p className="error-text">{emailError}</p>}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="daysToCall">Days to Call : </label>
                <input
                  type="number"
                  name="Days to Call"
                  id="daysToCall"
                  className="tab-input"
                  min="0"
                  value={dataOfRowId["Days to Call"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="lastContact">Last Contact : </label>
                <input
                  type="text"
                  name="Last Contact"
                  id="lastContact"
                  className="tab-input"
                  value={dataOfRowId["Last Contact"]}
                  onChange={handleSelectChange}
                />
              </div>

              <div className="tab-input-control">
                <label htmlFor="closingDate">Closing Date : </label>
                <input
                  type="date"
                  name="Closing Date"
                  id="closingDate"
                  className="tab-input"
                  value={dataOfRowId["Closing Date"]}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="hotel">Hotel : </label>
                <input
                  type="text"
                  name="Hotel"
                  id="hotel"
                  className="tab-input"
                  value={dataOfRowId.Hotel}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="budget">Budget : </label>
                <input
                  type="text"
                  name="Budget"
                  id="budget"
                  className="tab-input"
                  value={dataOfRowId.Budget}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="maxDistance">Max. Distance : </label>
                <input
                  type="text"
                  name=" Distance"
                  id="maxDistance"
                  className="tab-input"
                  value={dataOfRowId[" Distance"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="rooms">Rooms : </label>
                <input
                  type="number"
                  name="Rooms"
                  id="rooms"
                  className="tab-input"
                  min="0"
                  value={dataOfRowId.Rooms}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="checkIn">Check-in : </label>
                <input
                  type="date"
                  name="Check-in"
                  id="checkIn"
                  className="tab-input"
                  value={dataOfRowId["Check-in"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="checkOut">Check-out : </label>
                <input
                  type="date"
                  name="Check-out"
                  id="checkOut"
                  className="tab-input"
                  value={dataOfRowId["Check-out"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="preferences">Preferences:</label>
                <select
                  name="Preferences"
                  id="preferences"
                  className="tab-input input-dropdown"
                  value={dataOfRowId.Preferences}
                  onChange={handleSelectChange}
                >
                  <option value="" hidden>
                    Select a Preferences ...
                  </option>
                  {optionsPreferences.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="amenities">Amenities:</label>
                <select
                  name="Amenities"
                  id="amenities"
                  className="tab-input input-dropdown"
                  value={dataOfRowId.Amenities}
                  onChange={handleSelectChange}
                >
                  <option value="" hidden>
                    Select an Amenities ...
                  </option>
                  {optionsAmenities.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="activities">Activities:</label>
                <select
                  name="Activities"
                  id="activities"
                  className="tab-input input-dropdown"
                  value={dataOfRowId.Activities}
                  onChange={handleSelectChange}
                >
                  <option value="" hidden>
                    Select an activities...
                  </option>
                  {optionsActivities.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="roomStay">/ Room Stay : </label>
                <input
                  type="number"
                  name="/ Room Stay"
                  id="roomStay"
                  className="tab-input"
                  value={dataOfRowId["/ Room Stay"]}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="totalStay">Total Stay: </label>
                <input
                  type="number"
                  name="Total Stay"
                  id="totalStay"
                  className="tab-input"
                  value={dataOfRowId["Total Stay"]}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className="tabs-input-form">
              <div className="tab-input-control">
                <label htmlFor="leadDate">Lead Date : </label>
                <input
                  type="date"
                  name="Lead Date"
                  id="leadDate"
                  className="tab-input"
                  value={dataOfRowId["Lead Date"] ? new Date(dataOfRowId["Lead Date"]).toISOString().split("T")[0] : ""}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="tab-input-control">
                <label htmlFor="emailType">Email Type :</label>
                <select
                  name="Email Type"
                  id="emailType"
                  className="tab-input input-dropdown"
                  value={dataOfRowId["Email Type"]}
                  onChange={handleSelectChange}
                >
                  <option value="" hidden>
                    Select a type...
                  </option>
                  {optionsEmailType.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="tab-input-control">
                <label htmlFor="Email Notes">Email Notes : </label>
                <input
                  type="text"
                  name="Email Notes"
                  id="Email Notes"
                  className="tab-input"
                  value={dataOfRowId["Email Notes"]}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default TeamLeaderTabs;
