import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useWebSocket } from "../context/websocketContext";
import LiveRequestCard from "./LiveRequestCard";
import styles from "./SalesSupportPage.module.css";
import { Button, TextField } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const SalesSupportPage = () => {
  const { messages } = useWebSocket();
  const [liveRequests, setLiveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [hotelNames, setHotelNames] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedHotelData, setSelectedHotelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customFeedback, setCustomFeedback] = useState("");
  const { token } = useContext(AuthContext);

  ////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////// update sales support live request //////////////////////////////////////
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newHotel, setNewHotel] = useState({
    hotelName: "",
    address: "",
    distance: "",
    boardingType: "",
    nightRate: "",
    freeCancellation: "",
    roomType: "",
    sellingPrice: "",
    stars: "",
  });
  const [allHotels, setAllHotels] = useState([]);
  console.log("allHotels", allHotels);

  ////////////////////////////////////////////////////////////////////////////////////////////

  const fetchLiveRequests = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/support/live-requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLiveRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching live requests:", error);
    }
  };

  useEffect(() => {
    fetchLiveRequests();
  }, []);

  useEffect(() => {
    messages.forEach((message) => {
      if (message.type === "newRequest") {
        setLiveRequests((prevRequests) => [...prevRequests, message.data]);
      }
    });
  }, [messages]);

  useEffect(() => {
    const fetchHotelNames = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/support/hotel-names`);
        setHotelNames(response.data);
      } catch (error) {
        console.error("Error fetching hotel names:", error);
      }
    };

    fetchHotelNames();
  }, []);

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    // Clear previous feedback when a new request is selected
    setSelectedHotelData([]);
    setCustomFeedback("");
  };

  const handleHotelChange = async (event) => {
    const selectedHotelName = event.target.value;
    setSelectedHotel(selectedHotelName);

    try {
      const response = await axios.get(
        `${baseUrl}/api/support/hotel-data/${selectedHotelName}`
      );
      setSelectedHotelData((prevData) => [...prevData, response.data]);
    } catch (error) {
      console.error("Error fetching hotel data:", error);
    }
  };

  const handleCustomFeedbackChange = (event) => {
    setCustomFeedback(event.target.value); // Update custom feedback state
  };

  const handleSendFeedback = async () => {
    if (!selectedRequest) return;

    try {
      const feedbackData =
        selectedRequest?.Request === "Custom Request"
          ? customFeedback
          : selectedHotelData;

      const response = await axios.post(
        `${baseUrl}/api/support/update-company-feedback`,
        {
          showId: selectedRequest.ShowId,
          companyId: selectedRequest.CompanyId,
          feedback: feedbackData, // Send either hotel data or custom feedback
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      setSelectedHotelData([]);
      setSelectedHotel("");
      setCustomFeedback(""); // Clear custom feedback after submission
      fetchLiveRequests();
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////// update sales support live request //////////////////////////////////////
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewHotel({
      hotelName: "",
      address: "",
      distance: "",
      boardingType: "",
      nightRate: "",
      freeCancellation: "",
      roomType: "",
      sellingPrice: "",
      stars: "",
    });
  };

  const handleAddHotel = () => {
    if (newHotel.hotelName) {
      console.log("Adding new hotel:", newHotel);
      setAllHotels((prev) => [...prev, newHotel]);
      setNewHotel({
        hotelName: "",
        address: "",
        distance: "",
        boardingType: "",
        nightRate: "",
        freeCancellation: "",
        roomType: "",
        sellingPrice: "",
        stars: "",
      });
      handleDialogClose();
    }
  };

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  ////////////////////////////////////////////////////////////////////////////////////////////

  if (loading) {
    return (
      <div className="spinner-container">
        Loading...
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <h2>Live Requests</h2>
          {liveRequests?.slice(0, 4).map((request, index) => (
            <li key={index} onClick={() => handleCardClick(request)}>
              <LiveRequestCard message={{ data: request }} />
            </li>
          ))}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details</h2>
          {selectedRequest && (
            <div>
              <p>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p>
                <strong>Company Name:</strong>{" "}
                {selectedRequest?.companyData?.Lead}
              </p>
              <p>
                <strong>Request:</strong> {selectedRequest?.Request}
              </p>
              <p>
                <strong>Requested Data:</strong>{" "}
                {selectedRequest?.RequestedData}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest?.State}
              </p>

              {selectedRequest?.Request === "Custom Request" ? (
                <TextField
                  label="Custom Feedback"
                  multiline
                  rows={4}
                  value={customFeedback}
                  onChange={handleCustomFeedbackChange}
                  fullWidth
                  sx={{ marginBottom: "1rem" }}
                />
              ) : (
                selectedHotelData && (
                  <div className={styles.tableSection}>
                    <h2>Hotel Data</h2>
                    <div
                      className="live-request-table-container"
                      style={{ width: "100%" }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          maxHeight: "75vh",
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                background:
                                  "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                              }}
                            >
                              {[
                                "Hotel Name",
                                "Address",
                                "Distance",
                                "Boarding Type",
                                "Night Rate",
                                "Free Cancellation Unit",
                                "Room Type",
                                "Selling Price",
                                "Stars",
                              ].map((header, index) => (
                                <TableCell
                                  key={header}
                                  sx={{
                                    color: "#fff",
                                    borderRight: "2px solid #fff",
                                    fontSize: "15px",
                                    fontFamily: '"Playfair Display", serif',
                                    letterSpacing: 1,
                                    wordBreak: "normal",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allHotels.length > 0 ? (
                              allHotels.map((hotel, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.hotelName}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.address}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.distance}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.boardingType}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.nightRate}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.freeCancellation}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.roomType}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.sellingPrice}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderRight: "2px solid #e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: 550,
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {hotel?.stars}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderRight: "2px solid #e0e0e0",
                                    fontSize: "14px",
                                    fontWeight: 550,
                                    whiteSpace: "nowrap",
                                    wordBreak: "keep-all",
                                    padding: "4px 10px",
                                  }}
                                  colSpan={4}
                                >
                                  No hotels have been added yet
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )
              )}
              <Button
                variant="outlined"
                style={{ marginTop: "1rem" }}
                onClick={handleSendFeedback}
                disabled={
                  selectedRequest?.Request === "Custom Request"
                    ? customFeedback.length === 0
                    : allHotels.length === 0
                }
              >
                Send Feedback
              </Button>
            </div>
          )}
        </div>
        {selectedRequest?.Request !== "Custom Request" && selectedRequest && (
          <div className={styles.filters}>
            <button
              style={{
                wordBreak: "normal",
                whiteSpace: "nowrap",
                fontSize: "16px",
              }}
              onClick={handleDialogOpen}
            >
              Add Hotel
            </button>
          </div>
        )}
      </div>
      {/* Dialog for adding hotel */}
      <Dialog open={dialogOpen}>
        <DialogTitle>Add a New Hotel</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Hotel Name"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.hotelName}
            onChange={(e) =>
              setNewHotel({ ...newHotel, hotelName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.address}
            onChange={(e) =>
              setNewHotel({ ...newHotel, address: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Distance"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.distance}
            onChange={(e) =>
              setNewHotel({ ...newHotel, distance: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Boarding Type"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.boardingType}
            onChange={(e) =>
              setNewHotel({ ...newHotel, boardingType: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Night Rate"
            type="text"
            fullWidth
            // inputProps={{ min: 0, max: 5 }}
            variant="standard"
            value={newHotel.nightRate}
            onChange={(e) =>
              setNewHotel({ ...newHotel, nightRate: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Free Cancellation Unit"
            type="date"
            fullWidth
            variant="standard"
            value={newHotel.freeCancellation}
            onChange={(e) =>
              setNewHotel({ ...newHotel, freeCancellation: e.target.value })
            }
            inputProps={{
              min: formattedToday, // Prevent selecting today
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Room Type"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.roomType}
            onChange={(e) =>
              setNewHotel({ ...newHotel, roomType: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Selling Price"
            type="text"
            fullWidth
            variant="standard"
            value={newHotel.sellingPrice}
            onChange={(e) =>
              setNewHotel({ ...newHotel, sellingPrice: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Stars"
            type="number"
            inputProps={{ min: 0, max: 5 }}
            fullWidth
            variant="standard"
            value={newHotel.stars}
            onChange={(e) =>
              setNewHotel({ ...newHotel, stars: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose}> Cancel </button>
          <button onClick={handleAddHotel}> Add </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalesSupportPage;

{
  /* <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="hotel-select-label">Hotel</InputLabel>
              <Select
                labelId="hotel-select-label"
                id="hotel-select"
                value={selectedHotel}
                label="Hotel"
                onChange={handleHotelChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {hotelNames.map((hotel, index) => (
                  <MenuItem key={index} value={hotel}>
                    {hotel}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a hotel to view details</FormHelperText>
            </FormControl> */
}

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { useWebSocket } from "../context/websocketContext";
// import LiveRequestCard from "./LiveRequestCard";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import styles from "./SalesSupportPage.module.css";
// import SalesSupportPricingTable from "./SalesSupportPricingTable";
// import { Button, TextField } from "@mui/material";
// import { AuthContext } from "../context/AuthContext";
// import { baseUrl } from "../../api/Api";
// import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";

// const SalesSupportPage = () => {
//   const { messages } = useWebSocket();
//   const [liveRequests, setLiveRequests] = useState([]);
//   const [selectedRequest, setSelectedRequest] = useState(null);
//   const [hotelNames, setHotelNames] = useState([]);
//   const [selectedHotel, setSelectedHotel] = useState("");
//   const [selectedHotelData, setSelectedHotelData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [customFeedback, setCustomFeedback] = useState("");
//   const { token } = useContext(AuthContext);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [newHotel, setNewHotel] = useState({
//     hotelName: '',
//     address: '',
//     distance: '',
//     boardingType: '',
//     nightRate: '',
//     freeCancellation: '',
//     roomType: '',
//     sellingPrice: '',
//     stars: '',
//   });
//   const [allHotels , setAllHotels] = useState([])

//   const fetchLiveRequests = async () => {
//     try {
//       const response = await axios.get(`${baseUrl}/api/support/live-requests`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setLiveRequests(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching live requests:", error);
//     }
//   };

//   useEffect(() => {
//     fetchLiveRequests();
//   }, []);

//   useEffect(() => {
//     messages.forEach((message) => {
//       if (message.type === "newRequest") {
//         setLiveRequests((prevRequests) => [...prevRequests, message.data]);
//       }
//     });
//   }, [messages]);

//   useEffect(() => {
//     const fetchHotelNames = async () => {
//       try {
//         const response = await axios.get(`${baseUrl}/api/support/hotel-names`);
//         setHotelNames(response.data);
//       } catch (error) {
//         console.error("Error fetching hotel names:", error);
//       }
//     };

//     fetchHotelNames();
//   }, []);

//   const handleCardClick = (request) => {
//     setSelectedRequest(request);
//     // Clear previous feedback when a new request is selected
//     setSelectedHotelData([]);
//     setCustomFeedback("");
//   };

//   const handleHotelChange = async (event) => {
//     const selectedHotelName = event.target.value;
//     setSelectedHotel(selectedHotelName);

//     try {
//       const response = await axios.get(
//         `${baseUrl}/api/support/hotel-data/${selectedHotelName}`
//       );
//       setSelectedHotelData((prevData) => [...prevData, response.data]);
//     } catch (error) {
//       console.error("Error fetching hotel data:", error);
//     }
//   };

//   const handleCustomFeedbackChange = (event) => {
//     setCustomFeedback(event.target.value); // Update custom feedback state
//   };

//   const handleSendFeedback = async () => {
//     if (!selectedRequest) return;

//     try {
//       const feedbackData =
//         selectedRequest?.Request === "Custom Request"
//           ? customFeedback
//           : selectedHotelData;

//       const response = await axios.post(
//         `${baseUrl}/api/support/update-company-feedback`,
//         {
//           showId: selectedRequest.ShowId,
//           companyId: selectedRequest.CompanyId,
//           feedback: feedbackData, // Send either hotel data or custom feedback
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Feedback sent:", response.data);

//       setSelectedHotelData([]);
//       setSelectedHotel("");
//       setCustomFeedback(""); // Clear custom feedback after submission
//       fetchLiveRequests();
//     } catch (error) {
//       console.error("Error sending feedback:", error);
//     }
//   };

//   const handleDialogOpen = () => {
//     setDialogOpen(true);
//   };

//   const handleDialogClose = () => {
//     setDialogOpen(false);
//     setNewHotel({
//       hotelName: '',
//       address: '',
//       distance: '',
//       boardingType: '',
//       nightRate: '',
//       freeCancellation: '',
//       roomType: '',
//       sellingPrice: '',
//       stars: '',
//     });
//   };;

//   const handleAddHotel = () => {
//     if (newHotel.hotelName) {
//       console.log("Adding new hotel:", newHotel);
//       setAllHotels((prev) => [...prev, newHotel]);
//       setNewHotel({
//         hotelName: '',
//         address: '',
//         distance: '',
//         boardingType: '',
//         nightRate: '',
//         freeCancellation: '',
//         roomType: '',
//         sellingPrice: '',
//         stars: '',
//       });
//       handleDialogClose();
//     }
//   };

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         Loading...
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <div className={styles.content}>
//         <ul className={styles.requestList}>
//           <h2>Live Requests</h2>
//           {liveRequests?.slice(0, 4).map((request, index) => (
//             <li key={index} onClick={() => handleCardClick(request)}>
//               <LiveRequestCard message={{ data: request }} />
//             </li>
//           ))}
//         </ul>
//         <div className={styles.requestDetails}>
//           <h2>Request Details</h2>
//           {selectedRequest && (
//             <div>
//               <p>
//                 <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
//               </p>
//               <p>
//                 <strong>Company Name:</strong>{" "}
//                 {selectedRequest?.companyData?.Lead}
//               </p>
//               <p>
//                 <strong>Request:</strong> {selectedRequest?.Request}
//               </p>
//               <p>
//                 <strong>Requested Data:</strong>{" "}
//                 {selectedRequest?.RequestedData}
//               </p>
//               {/* <p>
//                 <strong>Requested Data:</strong> {selectedRequest?.notes}
//               </p> */}
//               <p>
//                 <strong>State:</strong> {selectedRequest?.State}
//               </p>

//               {selectedRequest?.Request === "Custom Request" ? (
//                 <TextField
//                   label="Custom Feedback"
//                   multiline
//                   rows={4}
//                   value={customFeedback}
//                   onChange={handleCustomFeedbackChange}
//                   fullWidth
//                   sx={{ marginBottom: "1rem" }}
//                 />
//               ) : (
//                 selectedHotelData && (
//                   <div className={styles.tableSection}>
//                     <h2>Hotel Data</h2>
//                     <SalesSupportPricingTable data={selectedHotelData} />
//                   </div>
//                 )
//               )}

//               <Button
//                 variant="outlined"
//                 style={{ marginTop: "1rem" }}
//                 onClick={handleSendFeedback}
//                 disabled={
//                   selectedRequest?.Request === "Custom Request"
//                     ? customFeedback.length === 0
//                     : selectedHotelData.length === 0
//                 }
//               >
//                 Send Feedback
//               </Button>
//             </div>
//           )}
//         </div>

//         {selectedRequest?.Request !== "Custom Request" && (
//           selectedRequest && (
//             <div className={styles.filters}>
//             <button
//               style={{
//                 wordBreak: "normal",
//                 whiteSpace: "nowrap",
//                 fontSize: "16px",
//               }}
//               onClick={handleDialogOpen}
//             >
//               Add Hotel
//             </button>
//           </div>
//           )
//         )}
//       </div>
//       {/* Dialog for adding hotel */}
//       <Dialog open={dialogOpen}>
//         <DialogTitle>Add a New Hotel</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Hotel Name"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.hotelName}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, hotelName: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Address"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.address}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, address: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Distance"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.distance}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, distance: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Boarding Type"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.boardingType}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, boardingType: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Night Rate"
//             type="number"
//             fullWidth
//             inputProps={{ min: 0, max: 5 }}
//             variant="standard"
//             value={newHotel.nightRate}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, nightRate: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Free Cancellation Unit"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.freeCancellation}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, freeCancellation: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Room Type"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.roomType}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, roomType: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Selling Price"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={newHotel.sellingPrice}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, sellingPrice: e.target.value })
//             }
//           />
//           <TextField
//             margin="dense"
//             label="Stars"
//             type="number"
//             inputProps={{ min: 0, max: 5 }}
//             fullWidth
//             variant="standard"
//             value={newHotel.stars}
//             onChange={(e) =>
//               setNewHotel({ ...newHotel, stars: e.target.value })
//             }
//           />
//         </DialogContent>
//         <DialogActions>
//           <button onClick={handleDialogClose}> Cancel </button>
//           <button onClick={handleDialogClose}> Add </button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default SalesSupportPage;
