import React from "react";
import "./RequestDropdown.css";

const RequestDropdown = ({ requestSelected, setRequestSelected, onRequestChange,id }) => {

  const selectReqOptions = [
    { label: "Live Request", value: "Live Request" },
    { label: "Callback Request", value: "Callback Request" },
    { label: "Custom Request", value: "Custom Request" },
  ];
  const handleRequestChange = (event) => {
    setRequestSelected(prevState => ({
      ...prevState,
      [id]: event.target.value
    }));
    if (onRequestChange) {
      onRequestChange();
    }
  };


  return (
    <div className="request-dropdown-container">
    <select
      name="Request"
      id={`request-dropdown-${id}`}
      className="request-select"
      value={requestSelected[id] || ""}
      onChange={handleRequestChange}
    >
      <option value="" className="request-main-option" hidden>
        Request
      </option>
      {selectReqOptions.map((optionItem) => (
        <option key={optionItem.value} value={optionItem.value}>
          {optionItem.label}
        </option>
      ))}
    </select>
  </div>
  );
};

export default RequestDropdown;


// import React from "react";
// import "./RequestDropdown.css";

// const RequestDropdown = ({ requestSelected, setRequestSelected, onRequestChange,id }) => {

//   console.log("id::::",id);
  
//   const selectReqOptions = [
//     { label: "Live Request", value: "Live Request" },
//     { label: "Callback Request", value: "Callback Request" },
//     { label: "Custom Request", value: "Custom Request" },
//   ];

//   // const handleRequestChange = (e) => {
//   //   const selectedValue = e.target.value;

//   //   setRequestSelected(selectedValue);

//   //   // setRequestSelected((prev) => ({
//   //   //   ...prev,
//   //   //   [id]: selectedValue,
//   //   // }))

//   //   console.log("Selected value:", selectedValue);

//   //   // Call the onRequestChange function if it exists
//   //   if (onRequestChange) {
//   //     onRequestChange(selectedValue);
//   //   }
//   // }; 

//   const handleRequestChange = (event) => {
//     setRequestSelected({ ...requestSelected, [id]: event.target.value });
//     if (onRequestChange) {
//       onRequestChange();
//     }
//   };

//   return (
//     <div className="request-dropdown-container">
//       <select
//         name="Request"
//         id={`request-dropdown-${id}`}
//         className="request-select"
//         value={requestSelected}
//         onChange={handleRequestChange}
//       >
//         <option value="" className="request-main-option" hidden>
//           Request
//         </option>
//         {selectReqOptions.map((optionItem) => (
//           <option key={optionItem.value} value={optionItem.value}>
//             {optionItem.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default RequestDropdown;
