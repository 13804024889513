import React, { useState } from "react";
import axios from "axios";
import styles from "./UploadCustomerSupportSection.module.css";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { baseUrl } from "../../api/Api";
import UploadExistingLeads from "./UploadExistingLeads";

const UploadCustomerSupportSection = () => {
  const [selectedLeadsFile, setSelectedLeadsFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");


  const handleShowsFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedLeadsFile(file);
  };

 

  const handleShowsUpload = async () => {
    if (selectedLeadsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedLeadsFile);
      setLoading(true);
      setProgress(0);
      try {
        const response = await axios.post(
          `${baseUrl}/api/customer-support/upload-existing-leads`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted);
            },
          }
        );
        console.log(response.data);
        setSelectedLeadsFile(null);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No file selected for Leads");
    }
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded </p>

      <div className={styles.controllersContainer}>
        <UploadExistingLeads
          onFileChange={handleShowsFileChange}
          onUpload={handleShowsUpload}
          isLoading={loading}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
    </div>
  );
};

export default UploadCustomerSupportSection;
