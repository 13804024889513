import React, { useContext, useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../api/Api";
import { AuthContext } from "../context/AuthContext";
import "./ExistingLeadsTable.css";
import UpdateExistingLeadModal from "./UpdateExistingLeadModal";

const ExistingLeadsTable = () => {
  const [existingLeads, setExistingLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useContext(AuthContext);

  const fetchExistingLeads = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/customer-support/get-raw-existing-leads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingLeads(response.data.leads); // Save fetched leads to state
    } catch (error) {
      console.error("Error fetching house leads:", error);
    }
  };
  useEffect(() => {
    fetchExistingLeads();
  }, [token]);

  const assignLead = async (leadId) => {
    try {
      await axios.post(
        `${baseUrl}/api/customer-support/distribute-lead/${leadId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === leadId ? { ...lead, Status: "Assigned" } : lead
        )
      );
    } catch (error) {
      console.error("Error Distributing lead");
    }
  };

  const updateLead = async (leadId, updatedData) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/customer-support/update-raw-existing-lead/${leadId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === leadId ? { ...lead, ...response.data.lead } : lead
        )
      );
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleOpenModal = (lead) => {
    setSelectedLead(lead);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedLead(null);
    setIsModalOpen(false);
  };

  return (
    <div style={{ padding: "1rem" }}>
      {/* Table to display leads */}

      <Paper style={{ marginTop: "20px", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead</TableCell>
              <TableCell>Show</TableCell>
              <TableCell>DM Name</TableCell>
              <TableCell>Shift</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell sx={{ width: "150px" }}>Status</TableCell>
              <TableCell>Actions</TableCell> {/* New column for buttons */}
            </TableRow>
          </TableHead>
          <TableBody>
            {existingLeads.map((lead) => (
              <TableRow key={lead._id}>
                <TableCell>{lead.Lead}</TableCell>
                <TableCell>{lead.Show}</TableCell>
                <TableCell>{lead["DM Name"] || "N/A"}</TableCell>
                <TableCell>{lead.Shift || "N/A"}</TableCell>
                <TableCell>{lead.Address || "N/A"}</TableCell>
                <TableCell>{lead.Phone || "N/A"}</TableCell>
                <TableCell>{lead.Email}</TableCell>
                <TableCell
                  className={`status ${lead.Status.toLowerCase()}`}
                  sx={{ width: "150px" }}
                >
                  {lead.Status}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => assignLead(lead._id)}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Distribute
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleOpenModal(lead)}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {selectedLead && (
        <UpdateExistingLeadModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          lead={selectedLead}
          onUpdate={updateLead}
        />
      )}
    </div>
  );
};

export default ExistingLeadsTable;
