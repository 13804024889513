import React from "react";
import "./PricingTable.css";

const PricingTable = ({
  getShows,
  selectedRows,
  selectedCheckboxes,
  handleCheckboxChange,
  isNewActive,
  getOldShows,
}) => {
  const columns = [
    { field: "Hotel", headerName: "Hotel" },
    { field: "OriginalCost", headerName: "Original Cost" },
    { field: "SellingPrice", headerName: "Selling Price" },
    { field: "Distance", headerName: "Distance" },
    { field: "MadeBy", headerName: "MadeBy" },
    { field: "isExpired", headerName: "isExpired" },
    { field: "lastModified", headerName: "last Modified" },
    { field: "", headerName: "" },
  ];

  console.log("getOldShows", getOldShows);
  console.log("getOldShows", getOldShows.ShowCompanyData);

  // const dataToShow = isNewActive ? getShows : getOldShows;

  return (
    <div className="pricing-table-contain">
      {isNewActive ? (
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getShows?.map(
              (lead) =>
                selectedRows === lead._id &&
                lead?.Pricings?.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td>{item.OriginalCost}</td>
                    <td>{item.SellingPrice}</td>
                    <td>{item.Distance}</td>
                    <td>{item.MadeBy}</td>
                    <td>{item.isExpired === false ? "no" : "yes"}</td>
                    <td>{item.lastModified}</td>
                    <td>
                      <input
                        type="checkbox"
                        name={`checkbox-${lead._id}-${item._id}`}
                        id={`checkbox-${lead._id}-${item._id}`}
                        checked={
                          selectedCheckboxes[`${lead._id}-${item._id}`] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(lead._id, item._id, item)
                        }
                      />
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      ) : (
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getOldShows?.map((company) =>
              company.ShowCompanyData.map((lead) =>
                lead?.Show?.pricings?.map((item) => (
                  <tr key={item._id}>
                    <td>{item.Hotel}</td>
                    <td>{item.OriginalCost}</td>
                    <td>{item.SellingPrice}</td>
                    <td>{item.Distance}</td>
                    <td>
                      <input
                        type="checkbox"
                        name={`checkbox-${lead._id}-${item._id}`}
                        id={`checkbox-${lead._id}-${item._id}`}
                        checked={
                          selectedCheckboxes[`${lead._id}-${item._id}`] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(lead._id, item._id, item)
                        }
                      />
                    </td>
                  </tr>
                ))
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PricingTable;
