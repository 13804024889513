import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import "./JuniorLeadsTable.css";
import { AuthContext } from "../../context/AuthContext";
import { baseUrl } from "../../../api/Api";
import CreateLeadModel from "./CreateLeadModel";

const CreateRawLeads = ({ data }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [showData, setShowData] = useState(data || []);
  const [editableValue, setEditableValue] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShowId, setSelectedShowId] = useState(null);
  const { token, role } = useContext(AuthContext);
  const cellRefs = useRef({});

  useEffect(() => {
    setShowData(data || []);
  }, [data]);
  console.log(showData);

  const allColumns = [
    { field: "Controllers", headerName: "Controllers" },
    { field: "showName", headerName: "Show Name" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleToggleLeads = (event, showId) => {
    event.stopPropagation();
    setExpandedRow(expandedRow === showId ? null : showId);
  };

  const handleCreateLeadClick = (showId) => {
    setSelectedShowId(showId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedShowId(null);
  };

  const handleLeadCreated = (newLead) => {
    console.log(newLead);

    // Assuming the last item in `newLead.companies` is the newly added lead
    const latestCompany = newLead.companies[newLead.companies.length - 1];

    const updatedShowData = showData.map((show) => {
      if (show._id === selectedShowId) {
        return {
          ...show,
          companies: Array.isArray(show.companies)
            ? [...show.companies, latestCompany]
            : [latestCompany],
        };
      }
      return show;
    });

    setShowData(updatedShowData);
  };

  return (
    <div className="table-container">
      <table className="table-scrollable">
        <thead>
          <tr>
            {allColumns.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {showData.map((show) => (
            <React.Fragment key={show._id}>
              <tr style={{ cursor: "pointer" }}>
                {allColumns.map((column) => (
                  <td key={column.field}>
                    {column.field === "Controllers" ? (
                      <div>
                        <button
                          onClick={(event) =>
                            handleToggleLeads(event, show._id)
                          }
                        >
                          Toggle Leads
                        </button>
                      </div>
                    ) : column.field === "showName" ? (
                      show[column.field]
                    ) : null}
                  </td>
                ))}
              </tr>
              {expandedRow === show._id && (
                <tr>
                  <td colSpan={allColumns.length}>
                    <div>
                      <div className="table-controllers">
                        <button onClick={() => handleCreateLeadClick(show._id)}>
                          Create Lead
                        </button>
                      </div>
                      <div className="accordion-content">
                        {show.companies && show.companies.length > 0 ? (
                          <table className="lead-details-table">
                            <thead>
                              <tr>
                                <th>Company Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Timezone</th>
                                <th>Link</th>
                              </tr>
                            </thead>
                            <tbody>
                              {show.companies.map((lead) => (
                                <tr key={lead._id} className="lead-details">
                                  <td>{lead.companyName}</td>
                                  <td>{lead.phone}</td>
                                  <td>{lead.email}</td>
                                  <td>{lead.timezone}</td>
                                  <td>{lead.link}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No lead data available.</p>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <CreateLeadModel
          showId={selectedShowId}
          token={token}
          onClose={handleModalClose}
          onLeadCreated={handleLeadCreated}
        />
      )}
    </div>
  );
};

export default CreateRawLeads;
