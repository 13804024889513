// export const baseUrl = "http://localhost:4800";

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const SignUrl = `${baseUrl}/api/users/register`;
export const loginUrl = `${baseUrl}/api/users/login`;
export const logoutUrl = `${baseUrl}/api/users/logout`;

export const AllTimeZoneUrl = `${baseUrl}/api/shows/all-timezones`;
export const GetFilteredCompanies = `${baseUrl}/api/agents/get-filtered-companies`;
export const GetOldCompanies = `${baseUrl}/api/agents/get-old-companies`;
export const GetShowNames = `${baseUrl}/api/shows/all-shows/names`;

export const GetAllShowsUrl = `${baseUrl}/api/shows/get-all-shows`;
export const FilterShowsUrl = `${baseUrl}/api/agents/get-filtered-companies`;
export const updateShowsUrl = `${baseUrl}/api/agents/shows/idShow/companies/idCompany`;

export const GetLeaders = `${baseUrl}/api/agents/get-leader`;
export const GetCloserLeads = `${baseUrl}/api/leader/get-closer-leads`;
