import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../components/context/AuthContext";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import { format } from "date-fns";
import { baseUrl } from "../../api/Api";

const TeamMembersTable = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/leader/get-team`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response.data.team);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching team members:", error);
        setError("Failed to fetch team members. Please try again.");
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, [token]);

  const handleRowClick = (id) => {
    navigate(`/agent-history/${id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return format(date, "MMM dd, yyyy h:mm a");
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "wheat" }}>
              <strong>Name</strong>
            </TableCell>
            <TableCell sx={{ color: "wheat" }}>
              <strong>Role</strong>
            </TableCell>
            <TableCell sx={{ color: "wheat" }}>
              <strong>Email</strong>
            </TableCell>
            <TableCell sx={{ color: "wheat" }}>
              <strong>Logged In</strong>
            </TableCell>
            <TableCell sx={{ color: "wheat" }}>
              <strong>Last Seen</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamMembers.map((member) => (
            <TableRow
              key={member.userId}
              onClick={() => handleRowClick(member._id)}
            >
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.role}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell
                sx={{
                  width: "120px",
                  maxWidth: "120px",
                  textAlign: "center",
                }}
              >
                <Circle
                  sx={{
                    color: member.loggedIn ? "green" : "red",
                    fontSize: "1rem",
                  }}
                />
              </TableCell>
              <TableCell>
                {member.loggedIn ? "Online" : formatDate(member.lastSeen)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamMembersTable;
