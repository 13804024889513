import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../api/Api";
import { AuthContext } from "../context/AuthContext";

const ReportsTable = () => {
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { token } = useContext(AuthContext);

  // Fetch reports from the API
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/locator/report`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setReports(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch reports.");
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const renderHorizontalTable = (title, reportData) => (
    <Box my={4}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {/* Timezone Report Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Timezone</TableCell>
              {reportData.timezoneReport.map((row, index) => (
                <TableCell key={index} align="left" sx={{ color: "white" }}>
                  {row.timezone}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Total Leads</TableCell>
              {reportData.timezoneReport.map((row, index) => (
                <TableCell key={index} align="left">
                  {row.totalLeads}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Assigned Leads</TableCell>
              {reportData.timezoneReport.map((row, index) => (
                <TableCell key={index} align="left">
                  {row.assignedLeads}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Unassigned Leads</TableCell>
              {reportData.timezoneReport.map((row, index) => (
                <TableCell key={index} align="left">
                  {row.unassignedLeads}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Status Breakdown Table */}
      <Box mt={4}>
        <Typography variant="subtitle1">Status Breakdown:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Status</TableCell>
                {reportData.statusCounts.map((status, index) => (
                  <TableCell key={index} align="left" sx={{ color: "white" }}>
                    {status.status}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Count</TableCell>
                {reportData.statusCounts.map((status, index) => (
                  <TableCell key={index} align="left">
                    {status.count}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Unassigned Active Show Leads Table */}
      <Box mt={4}>
        <Typography variant="subtitle1">
          Unassigned Active Show Leads:
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Timezone</TableCell>
                {reportData.unassignedActiveShowLeads.map((lead, index) => (
                  <TableCell key={index} align="center" sx={{ color: "white" }}>
                    {lead.timezone}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Count</TableCell>
                {reportData.unassignedActiveShowLeads.map((lead, index) => (
                  <TableCell key={index} align="center">
                    {lead.count}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Summary */}
      <Box mt={2}>
        <Typography variant="subtitle1">
          Total Leads: {reportData.totalLeads}
        </Typography>
        <Typography variant="subtitle1">
          Leads with Closer Status: {reportData.closerStatusLeads}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Locator Reports
      </Typography>
      {renderHorizontalTable("US Leads Report", reports.usLeads)}
      {renderHorizontalTable("UK Leads Report", reports.ukLeads)}
    </Box>
  );
};

export default ReportsTable;
