import React from "react";
import "./EmailTemplateStyle.css";
import SelectEmailTemplate from "./selectEmailTemplate";
import PricingTable from "../pricingTable/PricingTable";
import Templates from "./Templates";

const EmailTemplate = ({
  handleSelectChange,
  selectShow,
  showNamesOptions,
  selectedTemplate,
  handleSelectTemplateChange,
  getShows,
  selectedRows,
  selectedCheckboxes,
  handleCheckboxChange,
  dataOfTemplate,
  handelCreateTemplate,
  isNewActive,
  getOldShows,
}) => {
  return (
    <div className="email-templates-container">
      <div className="email-select-section">
        <div className="email-templat-dropDown">
          <label htmlFor="show-name">Select Show:</label>
          <select
            className="email-templat-select"
            id="show-name"
            value={selectShow}
            onChange={handleSelectChange}
          >
            <option value="" hidden>
              Select Show
            </option>
            {showNamesOptions
              .filter((showName) => showName)
              .map((showName, index) => (
                <option key={index} value={showName}>
                  {showName}
                </option>
              ))}
          </select>
        </div>
        <SelectEmailTemplate
          selectedTemplate={selectedTemplate}
          handleSelectTemplateChange={handleSelectTemplateChange}
        />
        <div className="pricing-table-contanier">
          <PricingTable
            getShows={getShows}
            selectedRows={selectedRows}
            selectedCheckboxes={selectedCheckboxes}
            handleCheckboxChange={handleCheckboxChange}
            getOldShows={getOldShows}
            isNewActive={isNewActive}
          />
        </div>
        <div className="emailTemplate-btns">
          <button className="create-btn" onClick={handelCreateTemplate}>
            Create Template
          </button>
        </div>
      </div>
      <Templates
        selectedTemplate={selectedTemplate}
        dataOfTemplate={dataOfTemplate}
      />
    </div>
  );
};

export default EmailTemplate;
