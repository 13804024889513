import React, { useState } from "react";
import axios from "axios";
import UploadPricings from "./UploadPricings";
import styles from "./UploadControllersSection.module.css";
import { baseUrl } from "../../api/Api";

const UploadControllersSection = () => {
  const [selectedPricingsFile, setSelectedPricingsFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handlePricingsFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedPricingsFile(file);
  };

  const handlePricingsUpload = async () => {
    if (selectedPricingsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedPricingsFile);
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}}/api/pricing/upload-pricing`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        setSelectedPricingsFile(null); // Clear the selected file
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No file selected for pricings");
    }
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded </p>
      <div className={styles.controllersContainer}>
        <UploadPricings
          onFileChange={handlePricingsFileChange}
          onUpload={handlePricingsUpload}
          isLoading={loading}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
    </div>
  );
};

export default UploadControllersSection;
