import React, { useContext, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose} from "react-icons/md";
import "./SuperUser.css";
import { AuthContext } from "../../components/context/AuthContext";
import SignUp from "../AuthForms/SignUp";
import AssignLeader from "../../components/superUser/AssigneLeader";

const SuperUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRigsterUserActive, setIsRigsterUserActive] = useState(true);
  const [isAssignLeaderActive, setIsAssignLeaderActive] = useState(false);

  const { role } = useContext(AuthContext);
  
  const toggleSidebar = () => setIsOpen(!isOpen);

  const showRigesterUser = () => {
    setIsRigsterUserActive(true);
    setIsAssignLeaderActive(false);
  };

  const showAssignLeader = () => {
    setIsRigsterUserActive(false);
    setIsAssignLeaderActive(true);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Super User View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showRigesterUser} className="sidebar-btn">Rigister User</button>
              <button onClick={showAssignLeader} className="sidebar-btn">Users</button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isRigsterUserActive && (
          <div className="reuse-container">
            <h2 className="table-title">Rigister New User</h2>
            <SignUp />
          </div>
        )}

        {isAssignLeaderActive && (
          <div className="table-container">
            <h2 className="table-title">Users Panel</h2>
            <AssignLeader />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperUser;
