import {
  TextField,
  Button,
  Typography,
  Box,
  Modal,
  Paper,
} from "@mui/material";
import SalesSupportPricingTable from "./SalesSupportPricingTable";

const LiveRequestFeedback = ({
  isFeedbackModalOpen,
  handleFeedbackCloseModal,
  feedbackData
}) => {
  return (
    <Modal open={isFeedbackModalOpen} onClose={handleFeedbackCloseModal}>
      <Paper sx={{ maxWidth: 700, mx: "auto", p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Feedback Data
        </Typography>
        {feedbackData.length > 0 ? (
          <SalesSupportPricingTable data={feedbackData} />
        ) : (
          <Typography>No feedback data available.</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleFeedbackCloseModal}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Paper>
    </Modal>
  );
};

export default LiveRequestFeedback;
