import React, { useEffect, useState, useCallback } from "react";
import "./Table.css";
import axios from "axios";
import { baseUrl } from "../../api/Api";

const Table = ({
  data,
  handelPopUpOpen,
  requestSelected,
  setRequestSelected,
  handleRequestPopupOpen,
  handleFeedbackOpenModal,
  feedbackData,
  closerSelections,
  handleCloserChange,
  errorMessage,
}) => {
  const [leaders, setLeaders] = useState({ teamLeader: null });

  const columnsOfTable = [
    { field: "Lead", headerName: "Lead" },
    { field: "ShowName", headerName: "Show Name" },
    { field: "cityState", headerName: "City State" },
    { field: "Phone", headerName: "Phone" },
    { field: "Timezone", headerName: "Timezone" },
    { field: "Status", headerName: "Status" },
    { field: "answerBtn", headerName: "" },
    // { field: "requestBtn", headerName: "" },
    // { field: "liveRequestFeedback", headerName: "" },
    // { field: "CloserAssigned", headerName: "" },
  ];
  // useEffect(() => {
  //   const fetchLeaders = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const response = await axios.get(`${baseUrl}/api/agents/get-leader`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setLeaders(response.data || { teamLeader: null });
  //     } catch (error) {
  //       console.error("Error fetching leaders:", error);
  //       setLeaders({ teamLeader: null });
  //     }
  //   };

  //   fetchLeaders();
  // }, []);

  const handleUpdateAssignedTo = async (companyId, showId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${baseUrl}/api/agents/update-assigned-to/${companyId}/${showId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Successfully updated assigned to:", response.data);
      } else {
        console.error("Failed to update assigned to:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating assigned to:", error);
    }
  };


  const handlePopUpOpen = useCallback(
    (lead, leadData) => {
      handelPopUpOpen(
        lead._id,
        leadData._id,
        lead?.Show || "N/A",
        leadData?.Lead || "N/A",
        leadData?.Timezone || "N/A",
        leadData?.Status || "N/A",
        leadData?.RejectionReason || "",
        leadData?.Notes || "",
        leadData?.ContactName || "",
        leadData?.DMRating || "",
        leadData?.Email || "",
        leadData?.CloserAssigned || "",
        leadData?.CloserNote || "",
        leadData?.DaysToCall || "",
        leadData?.LastContact || "",
        leadData?.NextContact || "",
        leadData?.ClosingDate || "",
        leadData?.Hotel || "",
        leadData?.Budget || "",
        leadData?.Max?.Distance || "",
        leadData?.Rooms || "",
        lead["Next Dates"] || "",
        lead["End Date"] || "",
        leadData?.Preferences || "",
        leadData?.Amenities || "",
        leadData?.Activities || "",
        leadData?.RoomStay || "",
        leadData?.TotalStay || "",
        leadData?.EmailType || "",
        leadData?.EmailNotes || ""
      );
      handleUpdateAssignedTo(leadData._id, leadData.showId);
    },
    [handelPopUpOpen]
  );

  const handleRequestChange = useCallback(
    (lead, leadData, show) => {
      console.log(leadData);

      handleRequestPopupOpen(
        leadData.showId,
        leadData._id,
        lead?.Show || "N/A",
        leadData?.Lead || "N/A"
      );
    },
    [handleRequestPopupOpen]
  );

  const handleFeedback = useCallback(
    (feedback) => {
      handleFeedbackOpenModal(feedback || feedbackData || "");
    },
    [handleFeedbackOpenModal, feedbackData]
  );

  const teamLeader = leaders.teamLeader;

  const renderTableRows = useCallback(() => {
    return data?.map((lead, leadIndex) =>
      lead?.Leads?.map((leadData, leadDataIndex) => (
        <tr key={`${leadIndex}-${leadData._id}`}>
          <td
            style={{
              width: "20%",
            }}
          >
            {leadData?.Lead || "N/A"}
          </td>
          <td
            style={{
              width: "30%",
            }}
          >
            {lead?.Show || "N/A"}
          </td>
          <td
            style={{
              width: "15%",
            }}
          >
            {leadData?.cityState || "N/A"}
          </td>
          <td
            style={{
              width: "20%",
            }}
          >
            {leadData?.Phone || "N/A"}
          </td>
          <td>{leadData?.Timezone || "N/A"}</td>
          <td>{leadData?.Status || "New"}</td>
          <td>
            <button onClick={() => handlePopUpOpen(lead, leadData)}>
              Answer
            </button>
          </td>
        </tr>
      ))
    );
  }, [
    data,
    // requestSelected,
    // setRequestSelected,
    handlePopUpOpen,
    // handleRequestChange,
    // handleFeedback,
    // handleCloserChange,
    // closerSelections,
    // feedbackData,
    // teamLeader,
  ]);

  return (
    <div className="table-container">
      {/* {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )} */}
      {errorMessage && (
      <div className="error-message">
        <p>{errorMessage}</p>
      </div>
    )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              {columnsOfTable.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Table);

// import React, { useCallback } from "react";
// import "./Table.css";
// import RequestDropdown from "../RequestDropdown/RequestDropdown";
// import CloserAssignedDropdown from "../CloserAssignedDrop/CloserAssignedDropdown";

// const Table = ({
//   data,
//   handelPopUpOpen,
//   handelOverlayPage,
//   requestSelected,
//   setRequestSelected,
//   handleRequestPopupOpen,
//   handleFeedbackOpenModal,
//   feedbackData,
//   closerSelections,
//   handleCloserChange,
//   errorMessage,
// }) => {
//   const columnsOfTable = [
//     { field: "Lead", headerName: "Lead" },
//     { field: "ShowName", headerName: "Show Name" },
//     { field: "Phone", headerName: "Phone" },
//     { field: "Timezone", headerName: "Timezone" },
//     { field: "Status", headerName: "Status" },
//     { field: "answerBtn", headerName: "" },
//     { field: "requestBtn", headerName: "" },
//     { field: "liveRequestFeedback", headerName: "" },
//     { field: "CloserAssigned", headerName: "" },
//   ];

//   const handlePopUpOpen = useCallback(
//     (lead, leadData) => {
//       handelPopUpOpen(
//         lead._id,
//         leadData._id,
//         lead?.Show || "N/A",
//         leadData?.Lead || "N/A",
//         leadData?.Timezone || "N/A",
//         leadData?.Status || "N/A",
//         leadData?.RejectionReason || "",
//         leadData?.Notes || "",
//         leadData?.ContactName || "",
//         leadData?.DMRating || "",
//         leadData?.Email || "",
//         leadData?.CloserAssigned || "",
//         leadData?.CloserNote || "",
//         leadData?.DaysToCall || "",
//         leadData?.LastContact || "",
//         leadData?.NextContact || "",
//         leadData?.ClosingDate || "",
//         leadData?.Hotel || "",
//         leadData?.Budget || "",
//         leadData?.Max?.Distance || "",
//         leadData?.Rooms || "",
//         leadData?.CheckIn || "",
//         leadData?.CheckOut || "",
//         leadData?.Preferences || "",
//         leadData?.Amenities || "",
//         leadData?.Activities || "",
//         leadData?.RoomStay || "",
//         leadData?.TotalStay || "",
//         leadData?.EmailType || ""
//       );
//     },
//     [handelPopUpOpen]
//   );

//   const handleRequestChange = useCallback(
//     (lead, leadData, show) => {
//       handleRequestPopupOpen(show, leadData._id, lead?.Show || "N/A", leadData?.Lead || "N/A");
//     },
//     [handleRequestPopupOpen]
//   );

//   const handleFeedback = useCallback(
//     (feedback) => {
//       handleFeedbackOpenModal(feedback || feedbackData || "");
//     },
//     [handleFeedbackOpenModal, feedbackData]
//   );

//   const renderTableRows = useCallback(() => {
//     return data?.map((lead, leadIndex) =>
//       lead?.Leads?.map((leadData, leadDataIndex) => (
//         <tr key={`${leadIndex}-${leadData._id}`}>
//           <td className="td-main">{leadData?.Lead || "N/A"}</td>
//           <td className="td-main1">{lead?.Show || "N/A"}</td>
//           <td className="td-main2">{leadData?.Phone || "N/A"}</td>
//           <td>{leadData?.Timezone || "N/A"}</td>
//           <td>{leadData?.Status || "New"}</td>
//           <td>
//             <button onClick={() => handlePopUpOpen(lead, leadData)}>Answer</button>
//           </td>
//           <td>
//             <RequestDropdown
//               requestSelected={requestSelected}
//               setRequestSelected={setRequestSelected}
//               id={leadData._id}
//               onRequestChange={() => handleRequestChange(lead, leadData, lead?.Show)}
//             />
//           </td>
//           <td>
//             <button
//               onClick={() => handleFeedback(leadData["Request Feedback"])}
//               disabled={!leadData["Request Feedback"] || !feedbackData}
//             >
//               Feedback
//             </button>
//           </td>
//           <td>
//             <CloserAssignedDropdown
//               companyId={leadData._id}
//               selectedCloser={closerSelections[leadData._id] || ""}
//               onCloserChange={(selectedCloser) => handleCloserChange(leadData._id, selectedCloser)}
//             />
//           </td>
//         </tr>
//       ))
//     );
//   }, [
//     data,
//     requestSelected,
//     setRequestSelected,
//     handlePopUpOpen,
//     handleRequestChange,
//     handleFeedback,
//     handleCloserChange,
//     closerSelections,
//     feedbackData,
//   ]);

//   return (
//     <div className="table-container">
//       {errorMessage && (
//         <div className="error-message">
//           <p>{errorMessage}</p>
//         </div>
//       )}
//       <table>
//         <thead>
//           <tr>
//             {columnsOfTable.map((column) => (
//               <th key={column.field}>{column.headerName}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>{renderTableRows()}</tbody>
//       </table>
//     </div>
//   );
// };

// export default React.memo(Table);
