import React, { useContext, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./CustomerSupportManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import SignUp from "../AuthForms/SignUp";
import AssignLeader from "../../components/superUser/AssigneLeader";
import ExistingLeadsTable from "../../components/CustomerSupport/ExistingLeadsTable";
import UploadCustomerSupportSection from "../../components/CustomerSupport/UploadCustomerSupportSection";

const CustomerSupportManager = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRawExistingLeadsActive, setIsRawExistingLeadsActive] =
    useState(true);
  const [isUploadSectionActive, setIsUploadSectionActive] = useState(false);

  const { role } = useContext(AuthContext);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const showExistingLeads = () => {
    setIsRawExistingLeadsActive(true);
    setIsUploadSectionActive(false);
  };

  const showUploadSection = () => {
    setIsRawExistingLeadsActive(false);
    setIsUploadSectionActive(true);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Customer Support View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showExistingLeads} className="sidebar-btn">
                Existing Leads
              </button>
              <button onClick={showUploadSection} className="sidebar-btn">
                Upload Existings
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isRawExistingLeadsActive && (
          <div className="reuse-container">
            <h2 className="table-title">Existing Leads</h2>
            <ExistingLeadsTable />
          </div>
        )}

        {isUploadSectionActive && (
          <div className="reuse-container">
            <h2 className="table-title">Upload Existing Leads</h2>
            <UploadCustomerSupportSection />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSupportManager;
