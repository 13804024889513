import React, { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../../components/context/AuthContext";
import "./CloserTab.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const optionsReason = [
  { label: "Competition Rate", value: "Competition Rate" },
  { label: "Official Housing Only", value: "Official Housing Only" },
  { label: "Recently Booked", value: "Recently Booked" },
  { label: "Early Booked", value: "Early Booked" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "All Set", value: "All Set" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Local", value: "Local" },
  { label: "Homeowner", value: "Homeowner" },
  { label: "Free Rooms", value: "Free Rooms" },
  { label: "Next Show", value: "Next Show" },
  { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
  { label: "Escalate", value: "Escalate" },
  { label: "Try Again", value: "Try Again" },
];
const optionsEmailType = [
  { label: "Not yet", value: "Not yet" },
  {
    label: "Pricing (1 Day Before, Last Day)",
    value: "Pricing (1 Day Before, Last Day)",
  },
  {
    label: "Pricing (2 Day Before, Last Day)",
    value: "Pricing (2 Day Before, Last Day)",
  },
  {
    label: "Pricing (3 Day Before, Last Day)",
    value: "Pricing (3 Day Before, Last Day)",
  },
  {
    label: "Pricing (Same Day, Last Day)",
    value: "Pricing (Same Day, Last Day)",
  },
  {
    label: "Pricing (1 Day Before, Day After)",
    value: "Pricing (1 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, Day After)",
    value: "Pricing (2 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, 2 Day After)",
    value: "Pricing (2 Day Before, 2 Day After)",
  },
  {
    label: "Contact Card for Future Reference",
    value: "Contact Card for Future Reference",
  },
  { label: "Mixed", value: "Mixed" },
];
const optionsPreferences = [
  { label: "King Bed", value: "King Bed" },
  { label: "2 Queen Beds", value: "2 Queen Beds" },
  { label: "King & Queens", value: "King & Queens" },
  { label: "Triple", value: "Triple" },
  { label: "1 Bed Suite", value: "1 Bed Suite" },
  { label: "2 Bed Suite", value: "2 Bed Suite" },
  { label: "1-Bedroom Apt/Suite", value: "1-Bedroom Apt/Suite" },
  { label: "2-Bedroom Apt/Suite", value: "2-Bedroom Apt/Suite" },
  { label: "3-Bedroom Apt/Suite", value: "3-Bedroom Apt/Suite" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Villa", value: "Villa" },
];
const optionsAmenities = [
  { label: "Room Only", value: "Room Only" },
  { label: "Breakfast", value: "Breakfast" },
  { label: "HB", value: "HB" },
  { label: "FB", value: "FB" },
  { label: "AI", value: "AI" },
  { label: "Kitchenette", value: "Kitchenette" },
  { label: "Balcony", value: "Balcony" },
  { label: "Others", value: "Others" },
];
const optionsActivities = [
  { label: "Transfers", value: "Transfers" },
  { label: "Car Rental", value: "Car Rental" },
  { label: "Air Ticket", value: "Air Ticket" },
  { label: "Platform Access", value: "Platform Access" },
];

const CloserTab = ({ selectedLead, handleInputChange }) => {
  const [value, setValue] = React.useState("1");
  const { role, token } = useContext(AuthContext);
  // Initial state from selectedLead prop
  const [leadDetails, setLeadDetails] = useState({ ...selectedLead });
  // Convert ISO string to YYYY-MM-DD format
  const formattedDate = new Date(leadDetails["Lead Date"])
    .toISOString()
    .split("T")[0];

  console.log("selectedLead", selectedLead);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        typography: "body1",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: "2px solid #37373f",
            borderColor: "divider",
            padding: 1,
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {[
              "Client Communication",
              "Follow-Up & Timeline",
              "Booking & Preferences",
              "Lead Information",
              "Pricing Table",
            ].map((label, index) => (
              <Tab
                key={index}
                label={label}
                value={(index + 1).toString()}
                sx={{
                  color: "#333",
                  fontFamily: '"Playfair Display", serif',
                  letterSpacing: 1,
                  fontSize: 16,
                  fontWeight: 700,
                  background: "#fff",
                  marginLeft: "5px",
                  "&:hover": {
                    color: "#fff",
                    background:
                      "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  },
                  "&.Mui-selected": {
                    color: "#fff",
                    background:
                      "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    borderRadius: "6px",
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className="tab-panel-container">
            <div className="tab-panel-inputs">
              <div className="input-tab-group">
                <label htmlFor="rejection-reason">Rejection Reason: </label>
                <select
                  name="Rejection Reason"
                  id="rejection-reason"
                  className="tab-input input-dropdown"
                  value={leadDetails["Rejection Reason"]}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a reason
                  </option>
                  {optionsReason.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-tab-group">
                <label htmlFor="notes">Notes : </label>
                <input
                  type="text"
                  name="Notes"
                  id="notes"
                  className="tab-input"
                  value={leadDetails.Notes}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="contact-name">Contact Name : </label>
                <input
                  type="text"
                  name="DM Name"
                  id="contact-name"
                  className="tab-input"
                  value={leadDetails["DM Name"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="dm-rating">DM Rating : </label>
                <input
                  type="number"
                  name="DM Rating"
                  id="dm-rating"
                  className="tab-input"
                  min="0"
                  value={leadDetails["DM Rating"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="email">Email : </label>
                <input
                  type="email"
                  name="Email"
                  id="email"
                  className="tab-input"
                  value={leadDetails.Email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="tab-panel-emailreq-inputs">
              <div className="tab-panel-inputs">
                <div className="input-tab-group">
                  <label
                    htmlFor="Email Type"
                    // style={{
                    //   color: "#fff",
                    // }}
                  >
                    Email Type :
                  </label>
                  <select
                    name="Email Type"
                    id="Email Type"
                    className="tab-input input-dropdown"
                    value={leadDetails["Email Type"]}
                    onChange={handleInputChange}
                  >
                    <option value="" hidden>
                      Select a type...
                    </option>
                    {optionsEmailType.map((optionItem) => (
                      <option key={optionItem.value} value={optionItem.value}>
                        {optionItem.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-tab-group">
                  <label
                    htmlFor="Email Notes"
                    // style={{
                    //   color: "#fff",
                    // }}
                  >
                    Email Notes :
                  </label>
                  <input
                    type="text"
                    name="emailRequestNotes"
                    id="Email Notes"
                    className="tab-input"
                    value={leadDetails.emailRequestNotes}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-tab-group" style={{ width: "300px" }}>
                  <button>Send Email Request</button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2" sx={{ height: "100%" }}>
          <div className="tab-panel-container">
            <div className="tab-panel-inputs">
              <div className="input-tab-group">
                <label htmlFor="daysToCall" disabled>
                  Days to Call :
                </label>
                <input
                  type="number"
                  name="Days to Call"
                  id="daysToCall"
                  className="tab-input"
                  min="0"
                  disabled
                  value={leadDetails["Days to Call"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="lastContact">Last Contact : </label>
                <input
                  type="text"
                  name="Last Contact"
                  id="lastContact"
                  className="tab-input"
                  value={leadDetails["Last Contact"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="closingDate">Closing Date : </label>
                <input
                  type="date"
                  name="Closing Date"
                  id="closingDate"
                  className="tab-input"
                  value={leadDetails["Closing Date"]}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3">
          <div className="tab-panel-container">
            <div className="tab-panel-inputs">
              <div className="input-tab-group">
                <label htmlFor="hotel">Hotel : </label>
                <input
                  type="text"
                  name="Hotel"
                  id="hotel"
                  className="tab-input"
                  value={leadDetails["Hotel"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="budget">Budget : </label>
                <input
                  type="text"
                  name="Budget"
                  id="budget"
                  className="tab-input"
                  value={leadDetails["Budget"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="maxDistance">Max. Distance : </label>
                <input
                  type="text"
                  name="Max. Distance"
                  id="maxDistance"
                  className="tab-input"
                  value={leadDetails["Max. Distance"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="rooms">Rooms : </label>
                <input
                  type="number"
                  name="Rooms"
                  id="rooms"
                  className="tab-input"
                  min="0"
                  value={leadDetails["Rooms"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="checkIn">Check-in : </label>
                <input
                  type="date"
                  name="Check-in"
                  id="checkIn"
                  className="tab-input"
                  value={leadDetails["Check-in"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="checkOut">Check-out : </label>
                <input
                  type="date"
                  name="Check-out"
                  id="checkOut"
                  className="tab-input"
                  value={leadDetails["Check-out"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="roomStay">/ Room Stay : </label>
                <input
                  type="number"
                  name="/ Room Stay"
                  id="roomStay"
                  className="tab-input"
                  value={leadDetails["/ Room Stay"]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-tab-group">
                <label htmlFor="totalStay">Total Stay: </label>
                <input
                  type="number"
                  name="Total Stay"
                  id="totalStay"
                  min="0"
                  className="tab-input"
                  value={leadDetails["Total Stay"]}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="4">
          <div className="tab-panel-container">
            <div className="tab-panel-inputs">
              <div className="input-tab-group">
                <label htmlFor="leadDateTime">Lead Date: </label>
                <input
                  type="date"
                  name="Lead Date"
                  id="leadDateTime"
                  className="tab-input"
                  disabled
                  value={formattedDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CloserTab;
