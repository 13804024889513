import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./TeamLeader.css";
import { AuthContext } from "../../components/context/AuthContext";
import axios from "axios";
import { GetCloserLeads } from "../../api/Api";
import TeamMembersTable from "../../components/teamLeader/TeamMembersTable";
import AssignedLeadsTable from "../../components/teamLeader/AssignedLeadsTable";
import TeamLeaderPopup from "../../components/popUp/TeamLeaderPopup";
import { baseUrl } from "../../api/Api";

const TeamLeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isViewTeamActive, setIsViewTeamActive] = useState(true);
  const [isAssignedLeadsActive, setIsAssignedLeadsActive] = useState(false);
  const [overLayStatusActive, setOverLayStatusActive] = useState(false);
  //-----------------------------------------------------------------------------
  const [assignedLeads, setAssignedLeads] = useState([]);
  const [popUpActive, setPopUpActive] = useState(false);
  const [dataOfRowId, setDataOfRowId] = useState({
    showId: null,
    companyId: null,
    Company: null,
    Show: null,
    Timezone: null,
    Status: null,
    "Closer Note": null,
    closerStatus: null,
    "Rejection Reason": null,
    Notes: null,
    "Contact Name": null,
    "DM Rating": null,
    Email: null,
    "Days to Call": null,
    "Last Contact": null,
    "Closing Date": null,
    Hotel: null,
    Budget: null,
    " Distance": null,
    Rooms: null,
    "Check-in": null,
    "Check-out": null,
    Preferences: null,
    Amenities: null,
    Activities: null,
    "/ Room Stay": null,
    "Total Stay": null,
    "Lead Date": null,
    "Email Type": null,
    "Email Notes": null,
  });

  const { role, token } = useContext(AuthContext);
  const fetchAssignedLeads = async () => {
    try {
      const response = await axios.get(GetCloserLeads, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAssignedLeads(response.data);
    } catch (error) {
      console.log("Fetch Assigned Leads Error ", error);
    }
  };

  useEffect(() => {
    fetchAssignedLeads();
  }, []);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const showTeam = () => {
    setIsViewTeamActive(true);
    setIsAssignedLeadsActive(false);
  };

  const showAssignedLeads = () => {
    setIsViewTeamActive(false);
    setIsAssignedLeadsActive(true);
  };
  const handleopenUpdateSubmit = () => {
    setOverLayStatusActive(true);
  };
  const handleCloseUpdateSubmit = async () => {
    if (!dataOfRowId.showId || !dataOfRowId.companyId) {
      console.error("showId or companyId is missing.");
      return;
    }
    const url = `${baseUrl}/api/leader/close-lead/${dataOfRowId.showId}/${dataOfRowId.companyId}`;
    try {
      const response = await axios.put(url, dataOfRowId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Update successful:", response);
      setOverLayStatusActive(false);
      fetchAssignedLeads();
      setPopUpActive(false);
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handlePopUpOpen = (
    showId,
    companyId,
    Company,
    Show,
    Timezone,
    Status,
    closerNote,
    rejectionReason,
    Notes,
    ContactName,
    dmRating,
    Email,
    daysToCall,
    lastContact,
    closingDate,
    Hotel,
    Budget,
    Distance,
    Rooms,
    CheckIn,
    CheckOut,
    Preferences,
    Amenities,
    Activities,
    roomStay,
    totalStay,
    leadDate,
    emailType,
    emailNotes
  ) => {
    setPopUpActive(true);
    setDataOfRowId({
      showId: showId,
      companyId: companyId,
      Show: Show,
      Company: Company,
      Timezone,
      "Closer Note": closerNote,
      closerStatus: Status,
      "Rejection Reason": rejectionReason,
      Notes,
      "Contact Name": ContactName,
      "DM Rating": dmRating,
      Email,
      "Days to Call": daysToCall,
      "Last Contact": lastContact,
      "Closing Date": closingDate,
      Hotel,
      Budget,
      " Distance": Distance,
      Rooms: Rooms,
      "Check-in": CheckIn,
      "Check-out": CheckOut,
      Preferences: Preferences,
      Amenities: Amenities,
      Activities: Activities,
      "/ Room Stay": roomStay,
      "Total Stay": totalStay,
      "Lead Date": leadDate,
      "Email Type": emailType,
      "Email Notes": emailNotes,
    });
  };

  return (
    <div className="team-leader-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Team Leader View</h2>
      </div>
      <div className="main-leader-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showTeam} className="sidebar-btn">
                View Team
              </button>
              <button onClick={showAssignedLeads} className="sidebar-btn">
                Assigned Leads
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isViewTeamActive && (
          <div className="table-container">
            <h2 className="table-title">Team Members</h2>
            <TeamMembersTable />
          </div>
        )}

        {isAssignedLeadsActive && (
          <div className="table-container">
            <h2 className="table-title">Assigned Leads</h2>
            <AssignedLeadsTable
              assignedLeads={assignedLeads}
              handlePopUpOpen={handlePopUpOpen}
            />
          </div>
        )}
        {popUpActive && (
          <TeamLeaderPopup
            dataOfRowId={dataOfRowId}
            setDataOfRowId={setDataOfRowId}
            overLayStatusActive={overLayStatusActive}
            handleopenUpdateSubmit={handleopenUpdateSubmit}
            handleCloseUpdateSubmit={handleCloseUpdateSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default TeamLeader;
