import React from "react";

const SelectEmailTemplate = ({selectedTemplate,handleSelectTemplateChange}) => {
  const templateOptions = [
    { label: "Template 1", value: "Template 1" },
    { label: "Template 2", value: "Template 2" },
    { label: "Template 3", value: "Template 3" },
    { label: "Template 4", value: "Template 4" },
    { label: "Template 5", value: "Template 5" },
    { label: "Template 6", value: "Template 6" },
    { label: "Template 7", value: "Template 7" },
  ];

  return (
    <div className="email-templat-dropDown">
      <label htmlFor="select-template">Select Template:</label>
      <select
        className="email-templat-select"
        id="select-template"
        value={selectedTemplate}
        onChange={handleSelectTemplateChange}
      >
        <option value="" hidden>
          Select Template
        </option>
        {templateOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectEmailTemplate;
