import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { field: "Hotel", headerName: "Hotel" },
  { field: "SellingPrice", headerName: "Selling Price" },
  { field: "Distance", headerName: "Distance" },
  { field: "MadeBy", headerName: "Made By" },
  { field: "isExpired", headerName: "Is Expired" },
  { field: "lastModified", headerName: "Last Modified" },
];

const ShowPricingOld = ({ data, selectedRows }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ position: "sticky", top: 0, backgroundColor: "#1f3445", zIndex: 1000 }}>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.field}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.flatMap((showItem) =>
            showItem.Pricing?.map((pricingItem) =>
              selectedRows === showItem._id
                ? pricingItem.Hotels.map((hotel) => (
                    <TableRow key={hotel._id}>
                      <StyledTableCell>{hotel["Hotel Name"] || "N/A"}</StyledTableCell>
                      <StyledTableCell>{hotel.SellingPrice || "N/A"}</StyledTableCell>
                      <StyledTableCell>{hotel.Distance || "N/A"}</StyledTableCell>
                      <StyledTableCell>{pricingItem.MadeBy || "N/A"}</StyledTableCell>
                      <StyledTableCell>
                        {pricingItem.isExpired !== undefined
                          ? pricingItem.isExpired
                            ? "Yes"
                            : "No"
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>{pricingItem.lastModified || "N/A"}</StyledTableCell>
                    </TableRow>
                  ))
                : null
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShowPricingOld;

// import React, { useEffect } from "react";

// const columns = [
//   { field: "Hotel", headerName: "Hotel" },
//   { field: "SellingPrice", headerName: "Selling Price" },
//   { field: "Distance", headerName: "Distance" },
//   { field: "MadeBy", headerName: "MadeBy" },
//   { field: "isExpired", headerName: "isExpired" },
//   { field: "lastModified", headerName: "last Modified" },
// ];

// const ShowPricingOld = ({ data, selectedRows }) => {
  
//   return (
//     <div className="pricing-table-contain">
//       <table>
//         <thead>
//           <tr>
//             {columns.map((column) => (
//               <th key={column.field}>{column.headerName}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data?.map((showItem) => (
//             showItem.Pricing?.map((pricingItem) =>
//               selectedRows === showItem._id && (
//                 pricingItem.Hotels.map((hotel) => (
//                   <tr key={hotel._id}>
//                     <td>{hotel["Hotel Name"] || "N/A"}</td>
//                     <td>{hotel.SellingPrice || "N/A"}</td>
//                     <td>{hotel.Distance || "N/A"}</td>
//                     <td>{pricingItem.MadeBy || "N/A"}</td>
//                     <td>{pricingItem.isExpired !== undefined ? (pricingItem.isExpired ? "Yes" : "No") : "N/A"}</td>
//                     <td>{pricingItem.lastModified || "N/A"}</td>
//                   </tr>
//                 ))
//               )
//             )
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ShowPricingOld;


// import React, { useEffect } from "react";

// const columns = [
//   { field: "Hotel", headerName: "Hotel" },
//   { field: "OriginalCost", headerName: "Original Cost" },
//   { field: "SellingPrice", headerName: "Selling Price" },
//   { field: "Distance", headerName: "Distance" },
//   { field: "MadeBy", headerName: "MadeBy" },
//   { field: "isExpired", headerName: "isExpired" },
//   { field: "lastModified", headerName: "last Modified" },
// ];

// const ShowPricingOld = ({ data, selectedRows }) => {
//   return (
//     <div className="pricing-table-contain">
//       <table>
//         <thead>
//           <tr>
//             {columns.map((column) => (
//               <th key={column.field}>{column.headerName}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//         {data?.map((lead) =>
//             lead?.ShowCompanyData?.map((showItem) =>
//               showItem?.Show?.pricings?.map((pricingItem) =>
//                 selectedRows === showItem?.Show._id && (
//                   <tr key={pricingItem._id}>
//                     <td>{pricingItem.Hotel || "N/A"}</td>
//                     <td>{pricingItem.OriginalCost || "N/A"}</td>
//                     <td>{pricingItem.SellingPrice || "N/A"}</td>
//                     <td>{pricingItem.Distance || "N/A"}</td>
//                     <td>{pricingItem.MadeBy || "N/A"}</td>
//                     <td>{pricingItem.isExpired !== undefined ? (pricingItem.isExpired ? "Yes" : "No") : "N/A"}</td>
//                     <td>{pricingItem.lastModified || "N/A"}</td>
//                   </tr>
//                 )
//               )
//             )
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ShowPricingOld;
