import React from "react";
import { Typography, Box, Paper } from "@mui/material";

// This component takes a string as 'data' and displays it.
const CustomFeedback = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.default",
        padding: "20px",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <h2>Custom Request Feedback</h2>
      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          backgroundColor: "background.paper",
          borderRadius: "8px",
          maxWidth: "500px",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" component="div">
          {data}
        </Typography>
      </Paper>
    </Box>
  );
};

export default CustomFeedback;
