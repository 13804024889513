import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { format } from "date-fns";
import { baseUrl } from "../../api/Api";

const CloserLeadsHistory = () => {
  const { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const teamMembers = location.state?.teamMembers || [];
  console.log(teamMembers);

  // State to manage edit mode
  const [editMode, setEditMode] = useState(null);
  // State to manage selected closers
  const [selectedClosers, setSelectedClosers] = useState({});

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/closer-leads/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const leadsData = response.data.leads;

        // Calculate status counts
        const counts = leadsData.reduce((acc, lead) => {
          acc[lead.status] = (acc[lead.status] || 0) + 1;
          return acc;
        }, {});

        setLeads(leadsData);
        setStatusCounts(counts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
        setError("Failed to fetch leads. Please try again.");
        setLoading(false);
      }
    };

    fetchLeads();
  }, [id, token]);

  const handleCloserChange = async (event, leadId, showId) => {
    const selectedCloserId = event.target.value;
    const selectedCloser = teamMembers.find(member => member._id === selectedCloserId);

    if (!selectedCloser) {
      console.log("Selected closer not found.");
      return;
    }

    const selectedCloserName = selectedCloser.name;

    console.log("Selected Closer ID:", selectedCloserId);
    console.log("Selected Closer Name:", selectedCloserName);
    console.log("Lead ID:", leadId);
    console.log("Show ID:", showId);

    try {
      const response = await axios.put(
        `${baseUrl}/api/sales-manager/update-closer`,
        {
          leadId,
          showId,
          newCloserId: selectedCloserId,
          newCloserName: selectedCloserName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Update successful:", response.data);

      // Update the lead in state
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.leadId === leadId
            ? { ...lead, closerId: selectedCloserId, closerName: selectedCloserName }
            : lead
        )
      );
      setEditMode(null); // Exit edit mode
    } catch (error) {
      console.log("Closer Change Error", error);
    }
  };

  console.log(leads);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  };

  // Filter team members to exclude the one with the id from params
  const filteredTeamMembers = teamMembers.filter((member) => member._id !== id);

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          {/* Status counts */}
          {statusCounts &&
            Object.entries(statusCounts).map(([status, count]) => (
              <Grid item xs={6} sm={3} md={2} key={status}>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle1">{status}</Typography>
                  <Typography variant="h6">{count}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Show</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Lead</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Timezone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closer Note</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closing Date</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Assign Closer</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.map((lead) => (
              <TableRow key={lead.leadId}>
                <TableCell>{lead.show}</TableCell>
                <TableCell>{lead.lead}</TableCell>
                <TableCell>{lead.phone}</TableCell>
                <TableCell>{lead.email}</TableCell>
                <TableCell>{lead.timezone}</TableCell>
                <TableCell>{lead.status}</TableCell>
                <TableCell>{lead.closerNote}</TableCell>
                <TableCell>{formatDate(lead.closingDate)}</TableCell>
                <TableCell>
                  {editMode === lead.leadId ? (
                    <FormControl fullWidth>
                      <InputLabel id={`assign-closer-label-${lead.leadId}`}>
                        Select Closer
                      </InputLabel>
                      <Select
                        labelId={`assign-closer-label-${lead.leadId}`}
                        value={selectedClosers[lead.leadId]?.id || ""}
                        onChange={(event) =>
                          handleCloserChange(event, lead.leadId, lead.showId)
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Closer
                        </MenuItem>
                        {filteredTeamMembers.map((member) => (
                          <MenuItem key={member._id} value={member._id}>
                            {member.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography
                      onClick={() => {
                        setEditMode(lead.leadId);
                        setSelectedClosers((prevState) => ({
                          ...prevState,
                          [lead.leadId]: {
                            id: lead.closerId || "",
                            name: lead.closerName || "",
                          },
                        }));
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {lead.closerName || "Select Closer"}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CloserLeadsHistory;
