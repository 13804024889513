import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OverLayPage.css";
import EmailTemplate from "../EmailTemplate/EmailTemplate";
import { GetShowNames } from "../../api/Api";

const OverLayPage = ({
  handelOverlayclose,
  emailTemplateData,
  getShows,
  selectedRows,
  isNewActive,
  getOldShows,
}) => {
  const [showNamesOptions, setShowNamesOptions] = useState([]);
  const [selectShow, setSelectShow] = useState(emailTemplateData.Show);
  // selected email template  logicc====>
  const [selectedTemplate, setSelectedTemplate] = useState("");
  // select pricing table in template logic ====>
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  // collect all data i want to put in template in this State
  const [dataOfTemplate, setDataOfTemplate] = useState({
    templatedSelected: "",
    showSelected: null,
    pricingSelected: null,
    companyName: null,
    ContactName: null,
    showId: null,
    companyId: null,
  });

  // data will integrated with api
  const [pricingIds, setPricingIds] = useState([]);
  const [templateIds, setTemplateIds] = useState({
    showId: null,
    companyId: null,
    templatedSelected: "",
    pricingSelectedId: null,
  });

  // get shows name
  useEffect(() => {
    const fetchShowNames = async () => {
      try {
        const response = await axios.get(GetShowNames);
        setShowNamesOptions(response.data);
      } catch (error) {
        console.error("Error fetching show names:", error);
      }
    };

    fetchShowNames();
  }, []);

  const handleSelectChange = (event) => {
    setSelectShow(event.target.value);
  };

  // selected email template ====>
  const handleSelectTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  // select pricing table in template logic ====>
  const handleCheckboxChange = (leadId, itemId, itemData) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [`${leadId}-${itemId}`]: !prevState[`${leadId}-${itemId}`],
    }));

    // Check if checkbox is checked
    if (!selectedCheckboxes[`${leadId}-${itemId}`]) {
      // Add selected item to the array
      setSelectedData((prevData) => [...prevData, itemData]);
    } else {
      // Remove unselected item from the array
      setSelectedData((prevData) =>
        prevData.filter((item) => `${item._id}` !== `${itemData._id}`)
      );
    }
  };

  const handelCreateTemplate = () => {
    const extractIds = () => {
      return selectedData.map((item) => item._id);
    };
    const ids = extractIds();
    setPricingIds(ids);

    setDataOfTemplate({
      templatedSelected: selectedTemplate,
      showSelected: selectShow,
      pricingSelected: selectedData,
      companyName: emailTemplateData.Company,
      ContactName: emailTemplateData.ContactName,
    });
    setTemplateIds({
      showId: emailTemplateData.showId,
      companyId: emailTemplateData.companyId,
      templatedSelected: selectedTemplate,
      pricingSelectedId: ids,
    });
  };
  console.log("templateIds", templateIds);

  return (
    <div className="overlay-container">
      <div className="close-overlay">
        <button className="close-button" onClick={handelOverlayclose}>
          &times;
        </button>
      </div>
      <div className="overlay-contain">
        <EmailTemplate
          handleSelectChange={handleSelectChange}
          selectShow={selectShow}
          showNamesOptions={showNamesOptions}
          selectedTemplate={selectedTemplate}
          handleSelectTemplateChange={handleSelectTemplateChange}
          getShows={getShows}
          handleCheckboxChange={handleCheckboxChange}
          selectedRows={selectedRows}
          selectedCheckboxes={selectedCheckboxes}
          dataOfTemplate={dataOfTemplate}
          handelCreateTemplate={handelCreateTemplate}
          isNewActive={isNewActive}
          getOldShows={getOldShows}
        />
      </div>
    </div>
  );
};

export default OverLayPage;
