import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    whiteSpace: "nowrap",
    zIndex: 3000,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: "#fffff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns = [
  { field: "Hotel Name", headerName: "Hotel Name" },
  { field: "Address", headerName: "Address" },
  { field: "Distance", headerName: "Distance" },
  { field: "SellingPrice", headerName: "Selling Price" },
  { field: "Online Price", headerName: "Online Price" },
  { field: "Room Type", headerName: "Room Type" },
  { field: "Stars", headerName: "Stars" },
  { field: "Boarding Type", headerName: "Boarding Type" },
  { field: "Free Cancellation Until", headerName: "Free Cancellation Until" },
  { field: "Amenities", headerName: "Amenities" },
  { field: "Template", headerName: "Template" },
];

const ShowPricingTable = ({ getShows }) => {
  const [openPopupTemplate, setOpenPopupTemplate] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const handleOpenPopup = (hotel) => {
    setSelectedHotel(hotel);
    setOpenPopupTemplate(true);
  };

  const handleClosePopup = () => {
    setOpenPopupTemplate(false);
    setSelectedHotel(null);

  };

  const pricings = getShows[0]?.Pricings || [];
  const allHotels = pricings.flatMap((pricing) => pricing.Hotels);
  const visibleHotels = allHotels.filter((hotel) => hotel.hidden === false);

  console.log(visibleHotels);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 400, overflow: "auto" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#1f3445",
              zIndex: 1000,
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  sx={{
                    backgroundColor: "#1f3445",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleHotels.map((hotel) => (
              <StyledTableRow key={hotel._id || hotel["Hotel Name"]}>
                <StyledTableCell>{hotel["Hotel Name"]}</StyledTableCell>
                <StyledTableCell>{hotel.Address}</StyledTableCell>
                <StyledTableCell>{hotel.Distance}</StyledTableCell>
                <StyledTableCell>{hotel.SellingPrice}</StyledTableCell>
                <StyledTableCell>{hotel["Online Price"]}</StyledTableCell>
                <StyledTableCell>{hotel["Room Type"]}</StyledTableCell>
                <StyledTableCell>{hotel.Stars}</StyledTableCell>
                <StyledTableCell>{hotel["Boarding Type"]}</StyledTableCell>
                <StyledTableCell>
                  {hotel["Free Cancellation Until"]}
                </StyledTableCell>
                <StyledTableCell>
                  {hotel.Amenities && hotel.Amenities.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: 200, overflow: "auto" }}
                    >
                      <Table stickyHeader>
                        <TableHead sx={{ position: "relative" }}>
                          <TableRow>
                            {/* backgroundImage:"linear-gradient(90deg,#e95095,#28465f) ",  */}
                            <TableCell
                              sx={{
                                backgroundColor: "#e95095",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Air Conditioned
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#e95095",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Gym
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#d82e7a",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Internet
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#bb1962",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Parking
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundImage:
                                  "linear-gradient(90deg,#bb1962,#28465f) ",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              24-Hour Reception
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#28465f",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Restaurant
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#28465f",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Spa
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#28465f",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Swimming Pool
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {hotel.Amenities[0]?.airConditioned
                                ? "Yes"
                                : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.gym ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.internet ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.parking ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.reception24Hour
                                ? "Yes"
                                : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.restaurant ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.spa ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {hotel.Amenities[0]?.swimmingPool ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    "No amenities available."
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {/* <button className="fetch-data-button" onClick={() => console.log(hotel._id)}>Show Template</button> */}
                  <button
                    className="fetch-data-button"
                    onClick={() => handleOpenPopup(hotel)}
                  >
                    Show Template
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openPopupTemplate} onClose={handleClosePopup}>
        <DialogTitle>Hotel Template</DialogTitle>
        <DialogContent>
          {selectedHotel ? (
            <div>
              <p>
                <strong>Hotel Name:</strong> {selectedHotel["Hotel Name"]}
              </p>
              <p>
                <strong>Address:</strong> {selectedHotel.Address}
              </p>
              <p>
                <strong>Distance:</strong> {selectedHotel.Distance}
              </p>
              <p>
                <strong>Selling Price:</strong> {selectedHotel.SellingPrice}
              </p>
              <p>
                <strong>Online Price:</strong> {selectedHotel["Online Price"]}
              </p>
              <p>
                <strong>Room Type:</strong> {selectedHotel["Room Type"]}
              </p>
              <p>
                <strong>Stars:</strong> {selectedHotel.Stars}
              </p>
              {/* Add more fields as necessary */}
            </div>
          ) : (
            <p>No template available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <button className="fetch-data-button" onClick={handleClosePopup}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowPricingTable;

// import React from "react";

// const columns = [
//   { field: "Hotel Name", headerName: "Hotel Name" },
//   { field: "Address", headerName: "Address" },
//   { field: "Distance", headerName: "Distance" },
//   { field: "SellingPrice", headerName: "Selling price" },
//   { field: "Online Price", headerName: "Online Price" },
//   { field: "Room Type", headerName: "Room Type" },
//   { field: "Stars", headerName: "Stars" },
//   { field: "Boarding Type", headerName: "Boarding Type" },
//   { field: "Free Cancellation Until", headerName: "Free Cancellation Until" },
//   { field: "Amenities", headerName: "Amenities" },
// ];

// const ShowPricingTable = ({ getShows }) => {
//   // Ensure getShows and its Pricings are defined
//   const pricings = getShows[0]?.Pricings || [];

//   // Extract all hotels from all pricing objects
//   const allHotels = pricings.flatMap((pricing) => pricing.Hotels);
//   const visibleHotels = allHotels.filter((hotel) => hotel.hidden === false);

//   return (
//     <div className="pricing-table-contain">
//       <table>
//         <thead>
//           <tr>
//             {columns.map((column) => (
//               <th key={column.field}>{column.headerName}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {visibleHotels.map((hotel, index) => (
//             <tr key={index}>
//               <td>{hotel["Hotel Name"]}</td>
//               <td>{hotel.Address}</td>
//               <td>{hotel.Distance}</td>
//               <td>{hotel.SellingPrice}</td>
//               <td>{hotel["Online Price"]}</td>
//               <td>{hotel["Room Type"]}</td>
//               <td>{hotel.Stars}</td>
//               <td>{hotel["Boarding Type"]}</td>
//               <td>{hotel["Free Cancellation Until"]}</td>
//               <td>
//                 {hotel.Amenities ? (
//                   <table className="amenities-table">
//                     <thead>
//                       <tr>
//                         <th>Air Conditioned</th>
//                         <th>Gym</th>
//                         <th>Internet</th>
//                         <th>Parking</th>
//                         <th>24-Hour Reception</th>
//                         <th>Restaurant</th>
//                         <th>Spa</th>
//                         <th>Swimming Pool</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td>
//                           {hotel.Amenities[0]?.airConditioned ? "Yes" : "No"}
//                         </td>
//                         <td>{hotel.Amenities[0]?.gym ? "Yes" : "No"}</td>
//                         <td>{hotel.Amenities[0]?.internet ? "Yes" : "No"}</td>
//                         <td>{hotel.Amenities[0]?.parking ? "Yes" : "No"}</td>
//                         <td>
//                           {hotel.Amenities[0]?.reception24Hour ? "Yes" : "No"}
//                         </td>
//                         <td>{hotel.Amenities[0]?.restaurant ? "Yes" : "No"}</td>
//                         <td>{hotel.Amenities[0]?.spa ? "Yes" : "No"}</td>
//                         <td>
//                           {hotel.Amenities[0]?.swimmingPool ? "Yes" : "No"}
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 ) : (
//                   <p>No amenities available.</p>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ShowPricingTable;
