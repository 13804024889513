import React from "react";
import "./TeamLeaderPop.css";
import TeamLeaderTabs from "../Tabs/teamLeaderTabs";
import TeamLeaderOverlay from "./TeamLeaderOverlay";

const TeamLeaderPopup = ({
  dataOfRowId,
  setDataOfRowId,
  overLayStatusActive,
  handleopenUpdateSubmit,
  handleCloseUpdateSubmit,
}) => {
  return (
    <div className="teamLeader-pop-container">
      <div className="teamLeader-pop-content">
        <div className="popup-content-header">
          <div className="popup-info">
            <h3 className="popup-header liner-tall-name">
              <span className="linear ">Show Name :</span> {dataOfRowId.Show}
            </h3>
            <h3 className="popup-header">
              <span className="linear">Company Name :</span>{" "}
              {dataOfRowId.Company}
            </h3>
            <h3 className="popup-header">
              <span className="linear">TimeZone :</span>
              {dataOfRowId.Timezone}
            </h3>
          </div>
          <div className="popup-button">
            <button
              className="save-btn"
              type="submit"
              onClick={handleopenUpdateSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="teamLeader-pop-section">
          <TeamLeaderTabs
            dataOfRowId={dataOfRowId}
            setDataOfRowId={setDataOfRowId}
          />
        </div>
      </div>
      {overLayStatusActive && (
        <TeamLeaderOverlay
          handleCloseUpdateSubmit={handleCloseUpdateSubmit}
          dataOfRowId={dataOfRowId}
          setDataOfRowId={setDataOfRowId}
        />
      )}
    </div>
  );
};

export default TeamLeaderPopup;
