import React, { useEffect, useState } from "react";
import RequestTimeZone from "../RequestTimeZone/RequestTimeZone";
import RequestEdition from "../RequestTimeZone/RequestEdition";
import './NewLeads.css'; 

const NewLeads = ({
  selectOptionsTimezone,
  selectedTimeZone,
  setSelectedTimeZone,
  selectOptionsEdition,
  selectedEdition,
  fetchShows,
  leadsCount,
}) => {
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  useEffect(() => {
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      const elapsedTime = Date.now() - storedStartTime;
      setTimer(elapsedTime);
      setIsTimerRunning(true);
    }

    if (!storedStartTime) {
      setIsTimerRunning(true);
      localStorage.setItem("startTime", Date.now());
    }

    const interval = setInterval(() => {
      if (isTimerRunning) {
        setTimer((prevTime) => prevTime + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerRunning]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  return (
    <div style={{ display: "flex", alignItems: "center", background: "#fff" }}>
      <RequestTimeZone
        selectOptionsTimezone={selectOptionsTimezone}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
      />
      <div className="timer-container">
        <span className="timer-label">Login Time</span>
        <p className="timer">{formatTime(timer)}</p>
      </div>
      <button
        onClick={fetchShows}
        disabled={leadsCount > 0 || !selectedTimeZone}
        className="fetch-data-button"
      >
        <p> Get More Leads</p>
      </button>
    </div>
  );
};

export default NewLeads;
