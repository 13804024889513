import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
  Button,
} from "@mui/material";
import "./SeniorLeadsTable.css";
import { format } from "date-fns";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { baseUrl } from "../../../api/Api";

const SeniorLeadsTable = ({ data }) => {
  const [leadsData, setLeadsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [createdLeadsCount, setCreatedLeadsCount] = useState(0);
  const [juniorsData, setJuniorsData] = useState([]);
  const [selectedJuniorId, setSelectedJuniorId] = useState("");
  const { token } = useContext(AuthContext);

  // Define table headers based on the data structure
  const columnsOfTable = [
    { field: "Show", headerName: "Show" },
    { field: "createdAt", headerName: "Created At" },
    { field: "dateModified", headerName: "Date Modified" },
    { field: "doneCount", headerName: "Done Count" },
    { field: "inWorks", headerName: "In Works" },
    { field: "newCount", headerName: "New Count" },
    { field: "oldCount", headerName: "Old Count" },
  ];

  // Update leadsData when data or selectedYear changes
  useEffect(() => {
    if (data) {
      let filteredLeads = [];

      // Log the incoming data
      console.log("Data:", data);

      // Loop through the data array
      data.forEach((item) => {
        // Log each item
        console.log("Item:", item);

        // Check if the edition matches the selectedYear
        if (!selectedYear || item.edition === selectedYear) {
          // Log each item that is added to filteredLeads
          console.log("Adding Item to Leads Data:", item);

          // Add the item to the filteredLeads array
          filteredLeads = [...filteredLeads, item];
        }
      });

      // Log the filtered leads
      console.log("Filtered Leads:", filteredLeads);

      setLeadsData(filteredLeads);
    }
  }, [data, selectedYear]);
  const handleGetJuniors = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/companies/get-juniors`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJuniorsData(response.data);
    } catch (error) {
      console.error("Error fetching Created leads count:", error);
    }
  };

  const handleGetCreatedLeadsCount = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/created-junior-leads-count`,
        { juniorId: selectedJuniorId }, // Send the selectedJuniorId in the body
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCreatedLeadsCount(response.data.createdLeadsCount);
    } catch (error) {
      console.error("Error fetching Created leads count:", error);
    }
  };

  useEffect(() => {
    handleGetJuniors();
  }, []);

  // Handle year selection change
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleJuniorChange = (event) => {
    setSelectedJuniorId(event.target.value);
  };

  // Get unique years for the filter dropdown
  const uniqueYears = Array.from(
    new Set(data?.map((item) => item.edition)) // Extract unique years from edition
  );

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  };

  return (
    <div className="table-container">
      <div className="main-header-info filters-section-container">
        <div className="filter-dropDown-container" style={{width:"30%" , gap:"30px" }}>
          <FormControl fullWidth variant="outlined" >
            <InputLabel>Junior Name</InputLabel>
            <Select
              value={selectedJuniorId}
              onChange={handleJuniorChange}
              label="Junior Name"
            >
              {juniorsData.map((junior) => (
                <MenuItem key={junior._id} value={junior._id}>
                  {junior.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleGetCreatedLeadsCount}>Submit</Button>
        </div>
      </div>
      <Box
        sx={{
          textAlign: "center",
          border: "1px solid #ccc",
          borderRadius: 2,
          width: "10%",
        }}
      >
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          Created Leads Count
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1976d2" }}>
          {createdLeadsCount}
        </Typography>
      </Box>
    </div>
  );
};

export default SeniorLeadsTable;
