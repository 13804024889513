import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const AgentHistoryLeads = () => {
  const { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/leader/agent-leads/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const leadsData = response.data.leads;

        // Calculate status counts
        const counts = leadsData.reduce((acc, lead) => {
          acc[lead.status] = (acc[lead.status] || 0) + 1;
          return acc;
        }, {});

        setLeads(leadsData);
        setStatusCounts(counts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
        setError("Failed to fetch leads. Please try again.");
        setLoading(false);
      }
    };

    fetchLeads();
  }, [id, token]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  // Predefined list of statuses with "All Leads" included
  const statusList = [
    { label: "All Leads", value: null },
    { label: "New", value: "New" },
    { label: "VM", value: "VM" },
    { label: "Callback", value: "Callback" },
    { label: "GK Blocked", value: "GK Blocked" },
    { label: "DM Rejected", value: "DM Rejected" },
    { label: "Do Not Call", value: "Do Not Call" },
    { label: "Email Only", value: "Email Only" },
    { label: "Email Follow-up", value: "Email Follow-up" },
    { label: "Waiting Approval", value: "Waiting Approval" },
    { label: "Prospect", value: "Prospect" },
    { label: "Flagged", value: "Flagged" },
    { label: "N/A", value: "N/A" },
    { label: "Closed Won", value: "Closed Won" },
    { label: "Closed Lost", value: "Closed Lost" },
  ];

  // Filter leads based on selected status
  const filteredLeads = selectedStatus
    ? leads.filter((lead) => lead.status === selectedStatus)
    : leads;

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          {statusList.map((status) => (
            <Grid item xs={6} sm={3} md={2} key={status.value}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                  border: "1px solid #e0e0e0",
                  borderRadius: 1,
                  cursor: "pointer",
                  backgroundColor:
                    selectedStatus === status.value ? "#f0f0f0" : "#fff",
                }}
                onClick={() => setSelectedStatus(status.value)}
              >
                <Typography variant="subtitle1">{status.label}</Typography>
                <Typography variant="h6">
                  {status.value === null
                    ? leads.length
                    : statusCounts[status.value] || 0}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Show</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Lead</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Timezone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeads.map((lead) => (
              <TableRow key={lead.leadId}>
                <TableCell>{lead.show}</TableCell>
                <TableCell>{lead.lead}</TableCell>
                <TableCell>{lead.phone}</TableCell>
                <TableCell>{lead.email}</TableCell>
                <TableCell>{lead.timezone}</TableCell>
                <TableCell>{lead.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AgentHistoryLeads;
