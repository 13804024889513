import React from "react";

const columnsOfTable = [
  { field: "CompanyName", headerName: "Company Name" },
  { field: "ShowName", headerName: "Show Name" },
  { field: "cityState", headerName: "City State" },
  { field: "Phone", headerName: "Phone" },
  { field: "Timezone", headerName: "Timezone" },
  { field: "Status", headerName: "Status" },
  { field: "Next Contact", headerName: "Callback Date" },
  { field: "answerBtn", headerName: "" },
];

const CallbackTable = ({ data, handelPopUpOpen }) => {
  console.log("data", data);

  // Calculate the status counts
  const statusCounts = data?.reduce((acc, lead) => {
    lead.Leads?.forEach((companyItem) => {
      const status = companyItem.Status;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
    });
    return acc;
  }, {});

  const getCurrentDateFormatted = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
  };

  const currentDate = getCurrentDateFormatted();
  // console.log(currentDate);

  const filteredLeads = data
    ?.map((lead) => ({
      ...lead,
      Leads: lead.Leads?.filter(
        (companyItem) => companyItem["Next Contact"] === currentDate
      ),
    }))
    .filter((lead) => lead.Leads && lead.Leads.length > 0);

  return (
    <div className="table-container old-data-table">
      <div style={{ marginBottom: "2rem" }}>
        <h2>Callback Leads for {currentDate}</h2>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              {columnsOfTable.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredLeads?.map((lead, index) =>
              lead?.Leads?.map((companyItem, companyIndex) => (
                <tr key={`${index}-${companyIndex}`}>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    {companyItem.Lead}
                  </td>
                  <td
                    style={{
                      width: "25%",
                    }}
                  >
                    {lead.Show}
                  </td>
                  <td
                    style={{
                      width: "15%",
                    }}
                  >
                    {companyItem?.cityState || "N/A"}
                  </td>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    {companyItem.Phone}
                  </td>
                  <td>{companyItem.Timezone}</td>
                  <td
                    style={{
                      width: "10%",
                    }}
                  >
                    {companyItem.Status}
                  </td>
                  <td
                    style={{
                      width: "15%",
                    }}
                  >
                    {companyItem["Next Contact"]}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        handelPopUpOpen(
                          companyItem.showId,
                          companyItem._id,
                          lead.Show,
                          companyItem.Lead,
                          companyItem.Timezone,
                          companyItem.Status,
                          companyItem["Rejection Reason"],
                          companyItem.Notes,
                          companyItem["DM Name"],
                          companyItem["DM Rating"],
                          companyItem.Email,
                          companyItem["Closer Assigned"],
                          companyItem["Closer Note"],
                          companyItem["Days to Call"],
                          companyItem["Last Contact"],
                          companyItem["Next Contact"],
                          companyItem["Closing Date"],
                          companyItem.Hotel,
                          companyItem.Budget,
                          companyItem?.Max?.[" Distance"],
                          companyItem.Rooms,
                          companyItem["Check-in"],
                          companyItem["Check-out"],
                          companyItem.roomDetails,
                          companyItem.Activities,
                          companyItem["/ Room Stay"],
                          companyItem["Total Stay"],
                          companyItem["Email Type"],
                          companyItem["Email Notes"]
                        );
                      }}
                    >
                      Answer
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CallbackTable;
