import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LiveRequestCard from "./LiveRequestCard";
import styles from "./SalesSupportPage.module.css";
import { Button, TablePagination } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";

const EmailTeamPage = () => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [page, setPage] = useState(0); // Page starts at 0
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [renderCheck, setRenderCheck] = useState(true);

  console.log("emailRequests", emailRequests);
  console.log("selectedRequest", selectedRequest);
  console.log("selectedRequest", selectedRequest?.emailPricingFeedback);

  const filteredHotelData = selectedRequest
    ? emailRequests
        .filter((item) => item.ShowId === selectedRequest.ShowId) // Filter by ShowId
        .flatMap((item) =>
          item?.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
        )
    : [];

  useEffect(() => {
    const fetchEmailRequests = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/email-team/email-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmailRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching live requests:", error);
      }
    };

    fetchEmailRequests();
  }, []);

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    // console.log("request", request);

    // Check if RequestedData is "Contact Card for Future Reference" or "Pricing (Same Day, Last Day)"
    if (
      request.RequestedData === "Contact Card for Future Reference" ||
      request.RequestedData === "Pricing (Same Day, Last Day)" ||
      request.RequestedData === "Pricing (1 Day Before, Last Day)"
    ) {
      setRenderCheck(false);
    } else {
      setRenderCheck(true); // Reset renderCheck if the condition isn't met
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  const paginatedRequests = emailRequests
    .slice()
    .sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    

  const updateEmailFeedback = async (feedback) => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      // Update the emailRequests state to reflect the feedback change
      setEmailRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.CompanyId === selectedRequest.CompanyId
            ? {
                ...req,
                companyData: { ...req.companyData, emailFeedback: feedback },
              }
            : req
        )
      );
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleRowClick = (id) => {
    setSelectedRowId(id);
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="loading-message">Loading...</div>
        <div className="spinner"></div>
      </div>
    );
  }
  console.log(selectedRequest);

  const renderTableRow = (hotel) => {
    return (
      <TableRow
        key={hotel._id}
        onClick={() => handleRowClick(hotel._id)}
        sx={{
          background: selectedRowId === hotel._id && "#A9A9A9",
          cursor: "pointer",
          color: selectedRowId === hotel._id ? "#fff" : "inherit",
          "&:hover": {
            background: "#A9A9A9",
            color: "#fff",
          },
        }}
      >
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Hotel Name"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.Address}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.Stars}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.SellingPrice
            ? hotel.SellingPrice
            : hotel["Average Nightly Rate with Markup"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Boarding Type"]}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", wordBreak: "keep-all" }}>
          {hotel.Distance}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Room Type"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Free Cancellation Until"]}
        </TableCell>
        {/* <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Online Price"]}
        </TableCell> */}
      </TableRow>
    );
  };

  return (
    <div className={styles.container}>
      <h1>Email Team Page</h1>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <h2>Email Requests</h2>
          <TablePagination
            component="div"
            count={emailRequests.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
          {paginatedRequests
            .slice()
            .map((request, index) => (
              <li
                key={request._id}
                onClick={() => handleCardClick(request)}
                style={{
                  background:
                    request.companyData?.emailFeedback === "Sent"
                      ? "green"
                      : request.companyData?.emailFeedback === "Error Sent"
                      ? "red"
                      : "transparent",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <LiveRequestCard message={{ data: request }} />
              </li>
            ))}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details : </h2>
          {selectedRequest && (
            <div>
              <p style={{ marginTop: "4px" }}>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p style={{ marginTop: "4px" }}>
                <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
              </p>
              <p style={{ marginTop: "4px" }}>
                <strong>Email:</strong>{" "}
                {selectedRequest?.Email || selectedRequest?.companyData?.Email}
              </p>
              <p style={{ marginTop: "4px" }}>
                <strong>Contact Name:</strong> {selectedRequest?.ContactName}
              </p>
              <div
                className="render-req-agent"
                style={{ display: "flex", gap: "60px" }}
              >
                <p style={{ marginTop: "4px" }}>
                  <strong>Request:</strong> {selectedRequest?.Request}
                </p>
                <p style={{ marginTop: "4px" }}>
                  <strong>Agent Name:</strong> {selectedRequest?.agentName}
                </p>
              </div>
              <p style={{ marginTop: "4px" }}>
                <strong>Requested Data:</strong>
                {selectedRequest?.RequestedData}
              </p>
              <p style={{ marginTop: "4px" }}>
                <strong>Email notes:</strong>
                {selectedRequest?.notes}
              </p>

              <div
                className="render-req-agent"
                style={{ display: "flex", gap: "30px" }}
              >
                {renderCheck && (
                  <>
                    <p style={{ marginTop: "4px" }}>
                      <strong style={{ marginRight: 5 }}>Check-in:</strong>
                      {selectedRequest.companyData["Check-in"]}
                    </p>
                    <p style={{ marginTop: "4px" }}>
                      <strong style={{ marginRight: 5 }}>Check-out:</strong>
                      {selectedRequest.companyData["Check-out"]}
                    </p>
                  </>
                )}
                <p style={{ marginTop: "4px" }}>
                  <strong style={{ marginRight: 5 }}>No Of Rooms:</strong>
                  {selectedRequest.companyData.Rooms}
                </p>
              </div>

              <p style={{ marginTop: "4px" }}>
                <strong>State:</strong> {selectedRequest?.State}
              </p>
              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginRight: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Sent")}
              >
                Send Correct Email
              </Button>

              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Error Sent")}
              >
                Send Error Email
              </Button>
            </div>
          )}
          {selectedRequest && (
            <div className={styles?.emailPriceTable__container}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: "auto",
                  maxHeight: "600px",
                  // minWidth: "800px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "100%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "Hotel Name",
                        "Address",
                        "Stars",
                        "Selling Price",
                        "Boarding Type",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        // "Online Price",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            background: "#8b5a5a",
                            color: "white",
                            // whiteSpace: "nowrap",
                            // wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(
                      selectedRequest?.emailPricingFeedback?.length > 0
                        ? selectedRequest?.emailPricingFeedback
                        : selectedRequest?.pricingData?.[0]?.Hotels
                    )
                      ? (
                          (selectedRequest?.emailPricingFeedback?.length > 0
                            ? selectedRequest.emailPricingFeedback
                            : selectedRequest?.pricingData?.[0]?.Hotels) || []
                        ).map((hotel) => renderTableRow(hotel))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailTeamPage;
