import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { format } from "date-fns";
import { baseUrl } from "../../api/Api";
import ResDrop from "../FiltersSection/ResDrop";

const AgentLeadsHistory = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const [statusOptionsSale, setStatusOptionsSale] = useState([]);
  const [timezoneOptionsSale, setTimezoneOptionsSale] = useState([]);
  const [showNameOptions, setShowNameOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedShowName, setSelectedShowName] = useState("");

  // State to manage edit mode
  const [editMode, setEditMode] = useState(null);
  // State to manage selected closers
  const [selectedClosers, setSelectedClosers] = useState({});

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/agent-member-leads/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const leadsData = response.data.leads;

        // Calculate status counts
        const counts = leadsData.reduce((acc, lead) => {
          acc[lead.status] = (acc[lead.status] || 0) + 1;
          return acc;
        }, {});

        setLeads(leadsData);
        setFilteredLeads(leadsData);
        setStatusCounts(counts);

        setStatusOptionsSale(
          Array.from(new Set(leadsData.map((lead) => lead.status)))
        );
        setTimezoneOptionsSale(
          Array.from(new Set(leadsData.map((lead) => lead.timezone)))
        );
        setShowNameOptions(
          Array.from(new Set(leadsData.map((lead) => lead.show)))
        );

        setLoading(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
        setError("Failed to fetch leads. Please try again.");
        setLoading(false);
      }
    };

    fetchLeads();
  }, [id, token]);

  useEffect(() => {
    let filtered = leads;

    if (selectedStatus) {
      filtered = filtered.filter((lead) => lead.status === selectedStatus);
    }
    if (selectedTimezone) {
      filtered = filtered.filter((lead) => lead.timezone === selectedTimezone);
    }
    if (selectedShowName) {
      filtered = filtered.filter((lead) => lead.show === selectedShowName);
    }

    setFilteredLeads(filtered);

    setStatusOptionsSale(
      Array.from(new Set(filtered.map((lead) => lead.status)))
    );
    setTimezoneOptionsSale(
      Array.from(new Set(filtered.map((lead) => lead.timezone)))
    );
    setShowNameOptions(Array.from(new Set(filtered.map((lead) => lead.show))));
  }, [selectedStatus, selectedTimezone, selectedShowName, leads]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };
  const handleShowNameChange = (event) => {
    setSelectedShowName(event.target.value);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  };

  return (
    <>
      <Box mb={2}>
        <Grid
          container
          spacing={1.5}
          sx={{ marginBottom: 2, marginTop: 1.5, marginLeft: 1 }}
        >
          {statusCounts &&
            Object.entries(statusCounts).map(([status, count]) => (
              <Grid item xs={12} sm={6} md={2} key={status}>
                <Box
                  sx={{
                    padding: 1,
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    textAlign: "center",
                    boxShadow: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {status}
                  </Typography>
                  <Typography variant="h6">{count}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      <div div className="main-header-info filters-section-container">
        <ResDrop
          label="Status"
          name="status"
          options={statusOptionsSale}
          selectedValue={selectedStatus}
          handleChange={handleStatusChange}
        />
        <ResDrop
          label="TimeZone"
          name="timeZone"
          options={timezoneOptionsSale}
          selectedValue={selectedTimezone}
          handleChange={handleTimezoneChange}
        />
        <ResDrop
          label="ShowName"
          name="ShowName"
          options={showNameOptions}
          selectedValue={selectedShowName}
          handleChange={handleShowNameChange}
        />
      </div>
      <TableContainer sx={{ padding: 2, borderRadius: "8px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Show</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Lead</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Timezone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closer Note</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closing Date</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closer Name</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeads.map((lead) => (
              <TableRow key={lead.leadId}>
                <TableCell>{lead.show}</TableCell>
                <TableCell>{lead.lead}</TableCell>
                <TableCell>{lead.phone}</TableCell>
                <TableCell>{lead.email}</TableCell>
                <TableCell>{lead.timezone}</TableCell>
                <TableCell>{lead.status}</TableCell>
                <TableCell>{lead.closerNote}</TableCell>
                <TableCell>{lead.closingDate}</TableCell>
                <TableCell>{lead.closerName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AgentLeadsHistory;
