import React from "react";
import FiltersSection from "../FiltersSection/FiltersSection";
import SortSection from "../SortSection/SortSection";

const HistoryLeads = ({
  getOldShows,
  handleChangeFilter,
  selectedCompany,
  companyOptions,
  statusOptions,
  selectedStatus,
  selectedTime,
  showNameOptions,
  selectedShowName,
  timeZoneOptions,
  updatedAtOptions,
  selectedTimeZoneFilter,
  sortOrder,
  handleChangeSort,
}) => {
  return (
    <>
      <FiltersSection
        getOldShows={getOldShows}
        handleChangeFilter={handleChangeFilter}
        selectedCompany={selectedCompany}
        companyOptions={companyOptions}
        statusOptions={statusOptions}
        selectedStatus={selectedStatus}
        selectedTime={selectedTime}
        showNameOptions={showNameOptions}
        selectedShowName={selectedShowName}
        timeZoneOptions={timeZoneOptions}
        updatedAtOptions={updatedAtOptions}
        selectedTimeZoneFilter={selectedTimeZoneFilter}
      />
    </>
  );
};

export default HistoryLeads;
