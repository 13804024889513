import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import InputFileUpload from "../../uploadControllers/InputFileUpload";
import styles from "../../uploadControllers/UploadControllers.module.css";

const UploadSseniorLeads = ({
  onFileChange,
  onUpload,
  isLoading,
  progress,
  setProgress,
}) => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFileName(file.name);
      onFileChange(event);
    }
  };

  const handleFileRemove = () => {
    setFileName("");
    onFileChange({ target: { files: [] } });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setProgress(0);
  };

  return (
    <div className={styles.controller}>
      <InputFileUpload
        onFileChange={handleFileChange}
        fileName={fileName}
        inputRef={fileInputRef}
      />
      {fileName && (
        <div className={styles.fileInfo}>
          <span className={styles.fileName}>{fileName}</span>
          <IconButton onClick={handleFileRemove} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      {progress > 0 && (
        <div className={styles.progressContainer}>
          <LinearProgress variant="determinate" value={progress} />
          <div className={styles.progressText}>{progress}%</div>
        </div>
      )}
      <Button
        onClick={onUpload}
        variant="outlined"
        color="primary"
        disabled={!fileName || isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          "Upload Leads Bulk with different shows"
        )}
      </Button>
    </div>
  );
};

export default UploadSseniorLeads;
